import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = ({
  // open = false, 
  title, 
  content = 'Ste prepričani?', 
  confirmText = 'Da', 
  declineText = 'Prekliči', 
  onConfirm, 
  onCancel,
  ...rest
}) => (
  <Dialog
    // open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    {...rest}
  >
    {title && (
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    )}
    
    {content && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
    )}

    <DialogActions>
      {/* <Button onClick={onCancel} color="primary"> */}
      <Button onClick={onCancel} color="default">
        {declineText}
      </Button>
      <Button onClick={onConfirm} color="primary" autoFocus>
      {/* <Button onClick={onConfirm} color="default" autoFocus> */}
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmDialog