import React, {useState, useEffect, useCallback} from 'react'
// import MainLayout from '../layout/Main'
import SimpleLayout from '../../layout/SingleAppBarLayout'
import { withStyles } from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';

// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';


// import Typography from '@material-ui/core/Typography';
import WorkOrderTable from './WorkOrderTable'
import ElectronicsTable from './ElectronicsTable'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import GeneralInfo from './GeneralInfo'

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import generateWODefinition from './pdf/generateWODefinition'
import generateElectronicsDefinition from './pdf/generateElectronicsDefinition'

import ordersService from '../../../services/order'

import {useProductConfiguration} from '../../../store/formConfig/FormConfigContext'

let numberOfColumnsPerPage = 5

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// import classNames from 'classnames';
const styles = theme => ({
  root: {},
  tableWrapper: {
    overflowX: "auto",
    // maxHeight: 'calc(100vh - 162px)',
    maxHeight: 'calc(100vh - 162px - 60px)',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 380px - 60px)'
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 292px - 60px)'
    }
  },
  tab: {
    maxWidth: '25%',
    // minWidth: 175
  }
});

const workOrdersTextDescriptions = {
  tip_zaluzije: 'Tip žaluzije',
  specifikacija_vodil_in_distancnikov: 'Specifikacija vodil in distančnikov',
  specifikacija_mask_in_nosilcev: 'Specifikacija mask in nosilcev',
  krmiljenje_in_elektronika: 'Krmiljenje in elektronika'
}

const WorkOrders = withRouter(withStyles(styles)(({classes, history, match, showOriginal = false}) => {

  const [orderData, setOrderData] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const [workOrders, setWorkOrders] = useState(null);
  const [activeTab, setActiveTab] = useState(0)
  const [valuesHaveChanged, setValuesHaveChanged] = useState(false)

  const {orderId} = match.params

  const loadOrder = useCallback(() => {
    ordersService.getById(orderId)
      .then(setOrderData)
      .catch(e => {
        console.log("e", e)
        let redirectPath = process.env.REACT_APP_BASENAME || '/'
        history.push(redirectPath)
      })
    },
    [orderId, history]
  )

  const activeWorkOrder = workOrders && workOrders[activeTab]

  const onPrintClick = useCallback(() => {
    let woTypeText = workOrdersTextDescriptions[activeWorkOrder.workOrderTypeDescription]
    if (woTypeText)
      woTypeText = woTypeText.toUpperCase()

    if (activeWorkOrder.workOrderTypeDescription === 'krmiljenje_in_elektronika') {
      const docDefinition = generateElectronicsDefinition({
        orderData,
        workOrderTypeText: woTypeText,
        workOrder: activeWorkOrder
      })
      if (docDefinition)
        pdfMake.createPdf(docDefinition).open()
    }
    else {
      const docDefinition = generateWODefinition({
        orderData,
        workOrderTypeText: woTypeText,
        numberOfColumnsPerPage,
        workOrder: activeWorkOrder
      })
      if (docDefinition)
        pdfMake.createPdf(docDefinition).open()
    }

    // const docDefinition = generateWODefinition({
    //   orderData,
    //   workOrderTypeText: woTypeText,
    //   numberOfColumnsPerPage,
    //   workOrder: activeWorkOrder
    // })
    // pdfMake.createPdf(docDefinition).open();
  });

  useEffect(() => {
    loadOrder()
  }, [loadOrder]);

  // useEffect(() => {
  //   ordersService.getWorkOrdersByOrderId(orderId)
  //     .then(setWorkOrders)
  //     .catch(e => {
  //       console.log("e", e)
  //     })
  // }, [ordersService]);

  const loadWorkOrders = useCallback(() => {
      if (showOriginal){
        ordersService.getOriginalWorkOrdersByOrderId(orderId)
          .then(workOrders => {
            setWorkOrders(workOrders)
            setValuesHaveChanged(false)
          })
          .catch(e => {
            console.log("e", e)
          })
      }
      else {
        ordersService.getWorkOrdersByOrderId(orderId)
          .then(workOrders => {
            setWorkOrders(workOrders)
            setValuesHaveChanged(false)
          })
          .catch(e => {
            console.log("e", e)
          })
      }
    },
    [ordersService, showOriginal]
  )
  
  useEffect(() => {
    loadWorkOrders()
  }, [loadWorkOrders]);

  let productConfig = useProductConfiguration(orderData && orderData.order && orderData && orderData.order.productId)


  if (!orderId || !orderData || !productConfig)
    return null

  let {order, rows, values} = orderData

  // let columnsList = rows.allIds
  //   .map((rowId) => rows.byId[rowId])
  //   .sort((row1, row2) => row1.orderNo - row2.orderNo)
  //   .slice(0, 5)

  // let currentPageOffsetIndex = (currentPageIndex) * numberOfColumnsPerPage
  // let currentPageColumnsList = columnsList.slice(currentPageOffsetIndex, currentPageOffsetIndex + numberOfColumnsPerPage)

  // let fieldsToRender = productConfig.product && productConfig.product.fieldsIds
  //   && productConfig.product.fieldsIds.map(fieldId => productConfig.fields.byId[fieldId])

  // console.log({workOrders})


  // if (workOrders && workOrders[0]) {
  //   console.log({0: workOrders[0].worksheetRows})
  //   console.log({1: workOrders[1].worksheetRows})
  //   console.log({2: workOrders[2].worksheetRows})
  //   console.log({3: workOrders[3].worksheetRows})
  // }


  if (!workOrders || !workOrders[0] || !workOrders[0].worksheetRows || !workOrders[0].worksheetRows.rows){
    // setTimeout(() => history.push('/'), 0)
    return null
  }

  const sameAsOriginal = workOrders.every(wo => wo.createdDate === wo.lastModifiedDate)

  // workOrders.map(wo => {
  //   console.log(wo.createdDate === wo.lastModifiedDate, wo.createdDate, wo.lastModifiedDate)
  // })

  const pageCount = Math.ceil(workOrders[0].worksheetRows.rows.length / numberOfColumnsPerPage)

  return (
    <>
      <Paper className={classes.root}>
        <GeneralInfo
          hasChanged={!sameAsOriginal}
          order={order}
          product={productConfig.product}
          history={history}
          onConfirmOrderClick={() => {}}
          saveEnabled={valuesHaveChanged && !showOriginal}
          showOriginal={showOriginal}
          onShowOriginalClick={() => {
            if (!showOriginal)
              history.push(`/orders/${match.params.orderId}/work-orders/original`)
            else
              history.push(`/orders/${match.params.orderId}/work-orders`)
          }}
          onSave={() => {
            setTimeout(() => {
              let cleanWorkOrders = workOrders.map(wo => {
                if (wo.workOrderTypeDescription === 'krmiljenje_in_elektronika'){
                  let allItemsObject = wo.worksheetRows.items
                  let itemKeys = Object.keys(allItemsObject)
                  let allItemsArrays = itemKeys.map(key => {
                    let item = allItemsObject[key]
                    let q = Number(item.quantity)
                      q = (q === 0) ? 0 : (q || 1)
  
                    return Array(q).fill(item.item)
                  })
                  let allItems = allItemsArrays.flat()
                  // console.log({allItems})
  
                  let cleanWorkOrder = {
                    ...wo,
                    worksheetRows: {
                      rows: [[...allItems]]
                    }
                  }
  
                  return cleanWorkOrder
                }
                return wo
              })
  
              // console.log({cleanWorkOrders})
  
              // return 
  
              Promise.all(
                cleanWorkOrders.map(ordersService.updateWorkOrder)
                // [
                //   ordersService.updateWorkOrder(workOrders[0]),
                //   ordersService.updateWorkOrder(workOrders[1]),
                //   ordersService.updateWorkOrder(workOrders[2])
                // ]
              ).then(function(values) {
                setValuesHaveChanged(false)
                loadWorkOrders()
              })
            }, 300)
          }}
        />

        <Divider variant="fullWidth" style={{height: '4px'}}/>

        <AppBar position="static" color="default" elevation={0}> 
          
          <Box justifyContent="space-between" flexWrap="nowrap" display="flex">
            <Tabs value={activeTab}
              // variant="scrollable"  
              // scrollButtons="auto"
              onChange={(e, tabIndex) => {
                setActiveTab(tabIndex)
                setCurrentPageIndex(0)
              }}
              indicatorColor="primary"
              textColor="primary"
            >
              {workOrders.map(wo => (
                <Tab key={wo.id} className={classes.tab} label={workOrdersTextDescriptions[wo.workOrderTypeDescription]} />
              ))}
            </Tabs>
            
            <Toolbar variant="dense">
              {/* {showOriginal && (
                <Typography variant="subtitle1" style={{whiteSpace: 'nowrap', color: 'black', fontWeight: 500, fontSize: 20}}>ORIGINAL</Typography>
              )} */}
              <IconButton
                id="print-button"
                style={{marginRight: 12}}
                onClick={onPrintClick}
              >
                <PrintIcon />
              </IconButton>
              <Pagination 
                disabled={activeWorkOrder && activeWorkOrder.workOrderTypeDescription === 'krmiljenje_in_elektronika'}
                page={currentPageIndex+1}
                pageCount={pageCount}
                goNext={() => {
                  if (currentPageIndex < (currentPageIndex+1))
                    setCurrentPageIndex(currentPageIndex+1)
                }}
                goPrevious={() => {
                  if (currentPageIndex > 0)
                    setCurrentPageIndex(currentPageIndex-1)
                }}
              />
            </Toolbar>
          </Box>

        </AppBar>

        {/* <Divider variant="fullWidth" style={{height: '1px'}}/> */}

        <div className={classes.tableWrapper}>
        
          {activeWorkOrder && (
            (activeWorkOrder.workOrderTypeDescription !== 'krmiljenje_in_elektronika')  ? (
            // (true)  ? (
              <WorkOrderTable
                workOrder={activeWorkOrder}
                columnOffset={currentPageIndex * numberOfColumnsPerPage}
                editingDisabled={showOriginal && activeWorkOrder.version === 'ORIGINAL'}
                // onChange={(rowIndex, columnIndex, newValue) => {
                //   console.log({workOrders})

                //   let wsRows = activeWorkOrder.worksheetRows && activeWorkOrder.worksheetRows.rows
                //   let updatedRows = [...wsRows]
                //   let oldValue = updatedRows[columnIndex][rowIndex]
                //   updatedRows[columnIndex][rowIndex] = {...oldValue, value: newValue}

                //   let updatedWorkOrders = [...workOrders]
                //   updatedWorkOrders[activeTab] = {
                //     ...updatedWorkOrders[activeTab],
                //     worksheetRows: {rows: updatedRows}
                //   }

                //   // console.log({updatedWorkOrders})

                //   setWorkOrders(updatedWorkOrders)
                //   setValuesHaveChanged(true)
                // }}

                onChange={(labelObject, columnIndex, newValue) => {

                  console.log({workOrders})
                  console.log({labelObject, columnIndex, newValue})

                  let wsRows = activeWorkOrder.worksheetRows && activeWorkOrder.worksheetRows.rows
                  let updatedRows = [...wsRows]
                  let oldColumnValues = updatedRows[columnIndex]
                  let oldValueIndex = oldColumnValues.findIndex(data => data.label === labelObject.label)
                  
                  if (oldValueIndex !== -1){ 
                    let oldValueObject = updatedRows[columnIndex][oldValueIndex]
                    updatedRows[columnIndex][oldValueIndex] = {...oldValueObject, value: newValue}
                  }
                  else {
                    updatedRows[columnIndex].push({...labelObject, value: newValue})
                    updatedRows[columnIndex].sort((a, b) => a.columnNum - b.columnNum)
                  }

                  console.log({updatedRows})

                  let updatedWorkOrders = [...workOrders]
                  updatedWorkOrders[activeTab] = {
                    ...updatedWorkOrders[activeTab],
                    worksheetRows: {rows: updatedRows}
                  }

                  // // console.log({updatedWorkOrders})

                  setWorkOrders(updatedWorkOrders)
                  setValuesHaveChanged(true)
                }}
              />
            ) : (
              <ElectronicsTable 
                editingDisabled={showOriginal}
                onChange={(itemKey, newQuantityValue) => {

                  if (!activeWorkOrder && activeWorkOrder.worksheetRows && activeWorkOrder.worksheetRows.items)
                    return null

                  let items = activeWorkOrder.worksheetRows.items
                  let updatedItems = {
                    ...items,
                    [itemKey]: {...items[itemKey], quantity: newQuantityValue}
                  }

                  let updatedWorkOrders = [...workOrders]
                  updatedWorkOrders[activeTab] = {
                    ...updatedWorkOrders[activeTab],
                    worksheetRows: {items: updatedItems}
                  }

                  console.log({updatedWorkOrders})

                  setWorkOrders(updatedWorkOrders)
                  setValuesHaveChanged(true)
                }}
                onNoteChange={(newNote) => {
                  let updatedWorkOrders = [...workOrders]
                  updatedWorkOrders[activeTab] = {
                    ...updatedWorkOrders[activeTab],
                    note: newNote
                  }
                  setWorkOrders(updatedWorkOrders)
                  setValuesHaveChanged(true)
                }}
                workOrder={activeWorkOrder}
              />
            )
            
          )}

        </div>
      </Paper>
    </>
  )
}))

const Pagination = ({page, pageCount, goNext, goPrevious, disabled}) => {

  return (<>
      <Typography variant="subtitle1" style={{
        whiteSpace: 'nowrap',
        color: disabled ? '#9a9a9a' : 'unset'
      }}>
        {'Stran ' + page + ' / ' + pageCount}
      </Typography>
    
      <div style={{marginLeft: 12, whiteSpace: 'nowrap'}}>
        <IconButton disabled={(page <= 1) || disabled} onClick={goPrevious}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton disabled={(page >= pageCount) || disabled} onClick={goNext}>
          <ChevronRightIcon />
        </IconButton>
      </div>
    
  </>)
}

const WorkOrdersPage = ({auth, match, showOriginal, ...rest}) => {
  const {orderId} = match.params
  return (

    <SimpleLayout 
      headerText={`Naročilo ${orderId} - Delovni Nalogi${showOriginal ? ' - ORIGINAL' : ''}`}
      component={WorkOrders}
      auth={auth}
      showOriginal={showOriginal}
      {...rest}
    />
  )
}

export default withRouter(WorkOrdersPage);