import React from 'react'
import ConfirmDialog from './Confirm'

const DeleteRowDialog = ({open, numberOfSelectedRows, onConfirm, onCancel}) => {
  let contentText = numberOfSelectedRows == 1 ? 'Želite odstraniti vrstico?'
  : numberOfSelectedRows == 2 ? 'Želite odstraniti vrstici?'
  : numberOfSelectedRows == 3 ? 'Želite odstraniti vrstice?'
  : null

  return (
    <ConfirmDialog
      open={open}
      // title="Potrditev naročila"
      content={contentText}
      confirmText="Zbriši" 
      declineText="Prekliči"
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

export default DeleteRowDialog