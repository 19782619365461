
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StickerIcon(props) {
  console.log({props})
  
  return (
    <SvgIcon
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2px"
      {...props}
    >
      <g fill="none" stroke="currentColor">
        <path d="M21 3v8c0 5.523-4.477 10-10 10H3V3h18z" />
        <path d="M9 21c2.667 0 4-1.333 4-4v-4h4c2.667 0 4-1.333 4-4" />
      </g>
      
    </SvgIcon>
  );

  // return (
  //   <SvgIcon {...props} >
  //     <svg
  //       stroke="currentColor"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       strokeWidth="2px"
  //       fill="none"
  //     >
  //       <path d="M21 3v8c0 5.523-4.477 10-10 10H3V3h18z" />
  //       <path d="M9 21c2.667 0 4-1.333 4-4v-4h4c2.667 0 4-1.333 4-4" />
  //     </svg>
      
  //   </SvgIcon>
  // );
}