import React, {useContext} from 'react';
import userService from './services/user'

const AuthContext = React.createContext();

class AuthProvider extends React.Component {

  constructor () {
    super()
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.cancelCredentialsError = this.cancelCredentialsError.bind(this)
  }

  state = {
    initialCheckInProgress: true,
    isLoggingIn: false,
    // isLoading: false,
    credentialsError: false,
    user: null
  }

  componentDidMount () {
    userService.getMe()
      .then(user => {
        // console.log({user})
        this.setState({user, initialCheckInProgress: false})
      })
      .catch(() => {
        this.setState({user: null, initialCheckInProgress: false})

        // Remove the token here?
        // localStorage.removeItem('auth_token')
      })
  }

  login (...rest) {
    this.setState({isLoggingIn: true})
    return userService.login(...rest)
      .then(user => {
        // console.log({user})
        this.setState({user, isLoggingIn: false})
      })
      .catch(e => {
        // console.log(e)
        this.setState({credentialsError: true, isLoggingIn: false})
      })
  }

  logout (...rest) {
    return userService.logout(...rest)
      .then(user => {
        this.setState({user: null, initialCheckInProgress: false})
      })
  }

  cancelCredentialsError () {
    this.setState({credentialsError: false})
  }

  render() {
    // const {
      // initialCheckInProgress,
      // isLoggingIn,
      // user,
    // } = this.state

    // ROLE_ADMIN
    // ROLE_MANAGER
    // ROLE_OPERATER
    // ROLE_USER

    let authorities = (this.state.user && this.state.user.authorities && this.state.user.authorities) || []

    let isAdmin = authorities.includes('ROLE_ADMIN')
    let isManager = authorities.includes('ROLE_MANAGER')
    let isOperator = authorities.includes('ROLE_OPERATOR') || authorities.includes('ROLE_OPERATER')
    let isUser = authorities.includes('ROLE_USER')

    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          login: this.login,
          logout: this.logout,
          cancelCredentialsError: this.cancelCredentialsError,
          isAdmin, isManager, isOperator, isUser
        }}
        // value={{
        //   initialCheckInProgress,
        //   isLoggingIn,
        //   // isLoading: initialCheckInProgress || isLoggingIn,
        //   user,
          // login: this.login,
          // logout: this.logout
        // }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContext')
  }
  return context
}

// export function withAuthContext(Component) {
//     return function WrapperComponent(props) {
//         return (
//             <AuthContext.Consumer>
//               {state => <Component {...props} context={state} />}
//             </AuthContext.Consumer>
//         );
//     };
// }

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer, useAuth}
export default AuthContext

