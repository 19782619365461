
const handleFieldResetFromName = async ({
  targetFieldName,
  rowWithDetails,
  createdOrderId, actualRowId,
  dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService, allFieldsToRender, allOptions
}) => {

  let field = allFieldsToRender.find(({name}) => name === targetFieldName)
  if (!field) return

  let existingValueObj = rowWithDetails.rowValuesObjects && rowWithDetails.rowValuesObjects[field.id]

  if (existingValueObj){
    handleFieldResetFromRow(rowWithDetails, field.id, actions, ordersService, dispatchOrderActions)
  }
}

const handleFieldResetFromRow = async (rowDetails, fieldId, actions, ordersService, dispatchOrderActions) => {
  // await delay(100)
  let valueIds = rowDetails && rowDetails.row && rowDetails.row.valuesIdsByFieldsIds && rowDetails.row.valuesIdsByFieldsIds[fieldId]
  valueIds && valueIds.forEach(vId => {
    handleFieldReset({fieldId, actions, ordersService, dispatchOrderActions, row: rowDetails.row, fieldValueId: vId})
  })
}

const handleFieldReset = ({fieldId, actions, ordersService, dispatchOrderActions, row, fieldValueId}) => {
  dispatchOrderActions(actions.removeFieldValue(fieldId, row))
  ordersService.removeFieldValue(fieldValueId)
}

export default handleFieldResetFromName