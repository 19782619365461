import spajanjeZaluzije from './handlers/spajanjeZaluzije'
import stranskoVodiloLevo from './handlers/stranskoVodiloLevo'
import stranskoVodiloLevoBarva from './handlers/stranskoVodiloLevoBarva'
import stranskoVodiloLevoDistancnik from './handlers/stranskoVodiloLevoDistancnik'

const fieldHandlers = {
  'spajanje_zaluzije': spajanjeZaluzije,
  'stransko_vodilo_levo': stranskoVodiloLevo,
  'stransko_vodilo_levo-barva': stranskoVodiloLevoBarva,
  'stransko_vodilo_levo-distancnik': stranskoVodiloLevoDistancnik
}

const handleSpecialChangeCases = async ({...attrs}) => {

  let {
    field, newValueOptionId, allFieldsToRender,
    rowsWithRenderDetails, ind, dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService,
    // productField,
    allOptions, createdOrderId, actualRows, addRows, insertRows,
    rowsWithRenderDetailsRef
  } = attrs

  if (fieldHandlers[field.name]){
    await fieldHandlers[field.name]({...attrs})
  }
} 

// function delay(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }



export default handleSpecialChangeCases