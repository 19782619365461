import React from 'react'
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    // backgroundColor: 'white',
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-root.Mui-disabled input': {
      opacity: .25
    },
    '& .MuiOutlinedInput-root.Mui-disabled fieldset': {
      borderColor: '#efefef',
    },

    // '&.Mui-focused fieldset': {
    //   backgroundColor: 'green',
    // },

    // '& .MuiOutlinedInput-root': {
    //   '&.Mui-focused fieldset': {
    //     backgroundColor: 'green',
    //   },
    // },
  }
});

const OutlinedTextField = ({id, name, value, text, isEnabled, readOnly, setLocalValue, setNewValue, withPlaceholderClasses, max}) => {

  let classes = useStyles()

  return (
    <TextField
      id={id}
      className={classes.root}
      style={{
        minWidth: 200,
        width: '100%',
        opacity: !isEnabled ? .25 : 1,
        // backgroundColor: 'lightblue'
      }} 

      // id={id}
      name={name}
      // label={value ? '' : field.text}
      placeholder={value ? '' : text}
      value={value}
      onChange={e => {
        let val = e.target.value
        if (val && max) {
          val = val.slice(0, max)
        }
        setLocalValue(val)
      }}
      onBlur={(e) => setNewValue(e.target.value)}
      type="text"

      // eslint-disable-next-line   
      inputProps={{
        style: {padding: '8px 5px 5px 5px', textOverflow: 'ellipsis'}
      }}

      // eslint-disable-next-line 
      InputProps={{
        classes: {input: withPlaceholderClasses},
        readOnly
      }}

      disabled={!isEnabled}
      variant="outlined"
    />
  )
}

export default OutlinedTextField