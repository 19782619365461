
import React, {useContext, useCallback} from 'react'
import orderService from '../../services/order'

const OrdersStateContext = React.createContext({})
const OrdersDispatchContext = React.createContext()

function ordersReducer(state, action) {
  // console.log({state, action})

  switch (action.type) {
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.isLoading || false
      }
    }
    case 'UPDATE_ALL': {
      return {
        ...state,
        orders: action.orders,
        totalCount: action.totalCount,
        updatedAt: Date.now(),
        isLoading: false
      }
    }
    case 'ADD': {
      return {
        ...state,
        orders: [...state.orders, {...action.order, id: Math.random()}]
      }
    }
    case 'UPDATE_LAST_CHANGE': {
      return {...state, lastChangeAt: Date.now()}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const initialState = {
  updatedAt: null,
  lastChangeAt: null,
  orders: null,
  // orders: [
  //   {id: 1, status: 'sent'},
  //   {id: 2, status: 'confirmed'},
  //   {id: 3, status: 'confirmed'},
  //   {id: 4, status: 'draft'}
  // ]
}

const OrdersProvider = function ({children}) {
  // const [orders, setOrders] = useState(initialState)

  const [state, dispatch] = React.useReducer(ordersReducer, initialState)

  // useEffect(() => {
  //   orderService.getList()
  //     .then(orders => {
  //       // console.log('orders:', orders)
  //       dispatch({type: 'UPDATE_ALL', orders})
  //       // setOrders(orders)
  //       // setIsLoading(false)
  //     })
    
  // }, []);

  return (
    // <OrdersStateContext.Provider value={orders}>
    //   <OrdersDispatchContext.Provider value={setOrders}>
    //     {children}
    //   </OrdersDispatchContext.Provider >
    // </OrdersStateContext.Provider >

    <OrdersStateContext.Provider value={state}>
      <OrdersDispatchContext.Provider value={dispatch}>
        {children}
      </OrdersDispatchContext.Provider >
    </OrdersStateContext.Provider >
  )
}

function useOrdersState() {
  const context = useContext(OrdersStateContext)
  if (context === undefined) {
    throw new Error('useOrdersState must be used within a OrdersProvider')
  }
  return context
}
function useOrdersDispatch() {
  const context = useContext(OrdersDispatchContext)
  if (context === undefined) {
    throw new Error('useOrdersDispatch must be used within a OrdersProvider')
  }
  return context
}

function useReload () {
  let dispatch = useOrdersDispatch()

  return useCallback((page = 1, showDrafts = false) => {

    dispatch({type: 'SET_LOADING', isLoading: true})

    if (showDrafts){
      return orderService.getList(page)
        .then(({orders, totalCount}) => {
          dispatch({type: 'UPDATE_ALL', orders, totalCount})
        })
    }
    else {
      return orderService.getListWithoutDrafts(page)
        .then(({orders, totalCount}) => {
          dispatch({type: 'UPDATE_ALL', orders, totalCount})
        })
    }
    
  }, [dispatch])
}

function useUpdateLastChange () {
  let dispatch = useOrdersDispatch()
  return () => {
    dispatch({type: 'UPDATE_LAST_CHANGE'})
  }
}

// use custom hooks for 'selectors'
function useOrdersSelectors() {
  const state = React.useContext(OrdersStateContext)

  function getAll() {
    return state.orders
  }
  function getTotalCount() {
    return state.totalCount
  }

  function getDrafts() {
    return state.orders && state.orders.filter(o => o.status === 'draft')
  }

  function getConfirmed() {
    return state.orders && state.orders.filter(o => o.status === 'confirmed')
  }

  return {
    getAll,
    getTotalCount,
    getDrafts,
    getConfirmed
  }
}

function useGetAll () {
  const state = React.useContext(OrdersStateContext)
  return state.orders
}
function useGetDrafts () {
  const state = React.useContext(OrdersStateContext)
  return state.orders.filter(o => o.status === 'draft')
}
function useGetConfirmed () {
  const state = React.useContext(OrdersStateContext)
  return state.orders.filter(o => o.status === 'confirmed')
}


// // use custom hooks for actions (specially async ones)
// function useOrdersActions() {
//   const state = React.useContext(OrdersStateContext)

//   function getOrders() {
//     return state.orders
//   }

//   function getOrdersLength () {
//     console.log({state})
//     return state.orders && state.orders.length
//   }

//   return {
//     getOrders,
//     getOrdersLength
//   }
// }

export { OrdersProvider, useOrdersState, useOrdersDispatch, useOrdersSelectors, useGetAll, useGetDrafts, useGetConfirmed, useReload, useUpdateLastChange}