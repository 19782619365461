import formConfigApi from '../api/formConfig'
import {normalize} from '../../utils/dataNormalization'

// const normalizationByName = createNormalization({
//   idAttribute: 'name',
//   byIdAttribute: 'byName',
//   allIdsAttribute: 'allNames'
// })

export function getProductTypes () {
  return formConfigApi.getProductTypes().then(normalize)
}

export function getProducts () {
  return formConfigApi.getProducts().then(normalize)
}

export function getFields () {
  return formConfigApi.getFields()
    .then(fields => fields.sort((field1, field2) => (
      field1.columnNum - field2.columnNum
    )))
    .then(normalize)
}

export function getOptions () {
  return formConfigApi.getOptions().then(normalize)
}

export function getRules () {
  return formConfigApi.getEnabledFields().then(normalize)
}

const addProductIdsToProductTypes = function ([productTypes, products]) {
  
  let updatedProductTypesById = products.allIds.reduce((productTypesToUpdate, productId) => {
    let product = products.byId[productId]
    let productTypeId = product.productTypeId

    if (!productTypesToUpdate[productTypeId]){
      productTypesToUpdate[productTypeId] = {
        ...productTypes.byId[productTypeId],
        productsIds: [productId]
      }
    }
    else
      productTypesToUpdate[productTypeId]
        .productsIds.push(productId)

    return productTypesToUpdate
  }, {})

  return {
    ...productTypes,
    byId: {
      ...productTypes.byId,
      ...updatedProductTypesById
    }
  }
}

const addFieldIdsToProducts = function ([products, fields]) {

  let fieldsIdsByFormConfigId = fields.allIds
    // .reduce((fieldsIdsByFormConfigIdToUpdate, fieldId) =>{
    .reduce((byFormConfigId, fieldId) =>{

      let field = fields.byId[fieldId]
      let fieldFormConfigsIds = field.formConfigs.map(formConfig => formConfig.id)

      fieldFormConfigsIds.forEach(formConfigId => {

        if (!byFormConfigId[formConfigId]){
          byFormConfigId[formConfigId] = [fieldId]
        }
        else 
          byFormConfigId[formConfigId].push(fieldId)
      })

      return byFormConfigId
    }, {})
  
  let updatedProductsById = products.allIds
    .reduce((productsByIdToUpdate, productId) => {

      let product = products.byId[productId]
      let productFormConfigId = product.formConfigId
      let productFields = fieldsIdsByFormConfigId[productFormConfigId]

      if (productFields){
        if (!productsByIdToUpdate[productId])
          productsByIdToUpdate[productId] = {
            ...products.byId[productId],
            fieldsIds: productFields
          }
      }

      return productsByIdToUpdate
    }, {})

  return {
    ...products,
    byId: {
      ...products.byId,
      ...updatedProductsById
    }
  }
}


const addOptionsIdsToFields = function ([fields, options]) {

  let updatedFields = options.allIds.reduce((fieldsToUpdate, optionId) => {
    let option = options.byId[optionId]
    if (!option)
      return fieldsToUpdate

    let optionField = fields.byId[option.fieldId]
    if (!optionField)
      return fieldsToUpdate

    if (!fieldsToUpdate[optionField.id])
      fieldsToUpdate[optionField.id] = {...optionField, optionsIds: []}

    fieldsToUpdate[optionField.id] = {
      ...fieldsToUpdate[optionField.id],
      optionsIds: [...fieldsToUpdate[optionField.id].optionsIds, optionId]
    }
    
    return fieldsToUpdate
  }, {})
  
  return {
    ...fields,
    byId: {
      ...fields.byId,
      ...updatedFields
    }
  }
}

const addRules = function ([fields, options, enabledFields]) {
  
  let [updatedFields, updatedOptions] = enabledFields.allIds.reduce(
    ([fieldsByIdToUpdate, optionsByIdToUpdate], ruleId) => {

      let rule = enabledFields.byId[ruleId]
      let {childFieldId, childOptionFieldId, parentOptionFieldId} = rule

      //
      //  If rule affects another field
      //
      if (childFieldId){
        if (!fieldsByIdToUpdate[childFieldId]){
          fieldsByIdToUpdate[childFieldId] = {
            ...fields.byId[childFieldId],
            parentRulesIds: [ruleId]
          }
        }
        else {
          fieldsByIdToUpdate[childFieldId]
            .parentRulesIds.push(ruleId)
        }
      }

      //
      //  If rule affects another option (field option)
      //
      if (childOptionFieldId){

        if (!optionsByIdToUpdate[childOptionFieldId]){
          optionsByIdToUpdate[childOptionFieldId] = {
            ...options.byId[childOptionFieldId],
            parentRulesIds: [ruleId]
          }
        }
        else {

          if (!optionsByIdToUpdate[childOptionFieldId].parentRulesIds) {
            optionsByIdToUpdate[childOptionFieldId] = {
              ...optionsByIdToUpdate[childOptionFieldId],
              parentRulesIds: [ruleId]
            }
          }
          else {
            optionsByIdToUpdate[childOptionFieldId]
              .parentRulesIds.push(ruleId)
          }

        }
      }

      //
      //  If the source of the rule is option 
      //
      if (parentOptionFieldId){

        if (!optionsByIdToUpdate[parentOptionFieldId]){
          optionsByIdToUpdate[parentOptionFieldId] = {
            ...options.byId[parentOptionFieldId],
            childrenRulesIds: [ruleId]
          }
        }
        else {

          if (!optionsByIdToUpdate[parentOptionFieldId].childrenRulesIds) {
            optionsByIdToUpdate[parentOptionFieldId] = {
              ...optionsByIdToUpdate[parentOptionFieldId],
              childrenRulesIds: [ruleId]
            }
          }
          else {
            optionsByIdToUpdate[parentOptionFieldId]
              .childrenRulesIds.push(ruleId)
          }

        }
      }

      //
      //  If the source of the rule is parent 
      //
      //
      //  if (parentFieldId){}
      //  NOT DEFINED SO FAR
      //

      // console.log(optionsByIdToUpdate[121])

      return [fieldsByIdToUpdate, optionsByIdToUpdate]
    },
    [{},{}]
  )

  let fieldsWithRules = {
    ...fields,
    byId: {
      ...fields.byId,
      ...updatedFields
    }
  }

  let optionsWithRules = {
    ...options,
    byId: {
      ...options.byId,
      ...updatedOptions
    }
  }

  return [fieldsWithRules, optionsWithRules]
}

export function getProductsConfiguration () {
  let productTypesPromise = getProductTypes()
  let productsPromise = getProducts()
  let fieldsPromise = getFields()
  let optionsPromise = getOptions()
  let rulesPromise = getRules()

  let productTypesWithProductsPromise = Promise.all([productTypesPromise, productsPromise])
    .then(addProductIdsToProductTypes)

  let productsWithFieldsPromise = Promise.all([productsPromise, fieldsPromise])
    .then(addFieldIdsToProducts)

  let fieldsWithOptionsPromise = Promise.all([fieldsPromise, optionsPromise])
    .then(addOptionsIdsToFields)


  let fieldsAndOptionsWithRulesPromise =
    Promise.all([fieldsWithOptionsPromise, optionsPromise, rulesPromise])
      .then(addRules)

  return Promise.all([
    productTypesWithProductsPromise, 
    productsWithFieldsPromise,
    rulesPromise,
    fieldsAndOptionsWithRulesPromise
  ])
    .then((results) => {
      // console.log({results})
      let [productTypes, products, rules, [fields, options]] = results
      return [productTypes, products, rules, fields, options]
    })

  // return Promise.all([
  //   productTypesPromise, 
  //   productsPromise, 
  //   fieldsWithOptionsPromise, // fieldsPromise, 
  //   optionsPromise,
  //   enabledFieldsPromise
  // ])
}

// getProductsConfiguration()

export default {
  getProductTypes,
  getProducts,
  getFields,
  getOptions,
  getRules,

  getProductsConfiguration
}