import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const DuplicateRowDialog = ({
  onConfirm, 
  onCancel,
  popoverProps,
}) => {
  // console.log({rest})
  const [value, setValue] = useState(1)
  const {open} = popoverProps

  const inputRef = React.useRef(null);

  const handleConfirm = () => {
    onConfirm(value)
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    onConfirm(value)
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onExited={() => setValue(1)}
    >
      <DialogTitle id="alert-dialog-title">{'Podvoji vrstico'}</DialogTitle>
      
      <DialogContent style={{backgroundColor: '#efefef'}}>
        
        <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
          <TextField
            inputRef={inputRef}
            style={{margin: 8}}
            id="row-copies-number"
            label="Število kopij"
            type="number"
            value={value}
            autoFocus
            onChange={e => setValue(e.target.value)}
            // inputRef={input => console.log('asdf')}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            onFocus={e => e.target.select()}
          />
        </form>
      </DialogContent>

      <DialogActions>
        {/* <Button onClick={onCancel} color="primary"> */}
        <Button onClick={onCancel} color="default">
          Prekliči
        </Button>
        <Button disabled={!value || value < 1} onClick={handleConfirm} color="primary">
          Podvoji
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DuplicateRowDialog