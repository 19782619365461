import sonalApi from './sonal'

export function getProductTypes () {
  return sonalApi.get('/product-types')
    .then(response => response.data)
}

export function getProducts () {
  return sonalApi.get('/products')
    .then(response => response.data)
}

export function getFields () {
  // return sonalApi.get('/fields?formConfigId=' + firstProduct.formConfigId)
  return sonalApi.get('/fields?size=1000')
    .then((response) => {
      let fields = response.data
      return fields
      // return response.data.filter(field => {
      //   return field.formConfigs &&
      //     field.formConfigs.some(formConfig => formConfig.id == firstProduct.formConfigId)
      // })
    })
}

export function getOptions () {
  // return sonalApi.get('/fields?formConfigId=' + firstProduct.formConfigId)
  return sonalApi.get('/field-options?size=1000')
    .then((response) => {
      let options = response.data
      return options
    })
}

export function getEnabledFields () {
  return sonalApi.get('/enabled-fields?size=1000')
    .then((response) => {
      let options = response.data
      return options
    })
}

export function downloadOptionImage (optionId, imageName) {
  // return sonalApi.get(`/field-options/${optionId}/img/${imageName}`, {responseType: 'blob'})
  return sonalApi.get(`/field-options/images/${optionId}/${imageName}`, {
    responseType: 'blob', 
    // headers: {"Cache-Control": "max-age=3600"}
  })
    .then((response) => {
      return response.data ? URL.createObjectURL(response.data) : null
    })
}

export default {
  getProductTypes,
  getProducts,
  getFields,
  getOptions,
  getEnabledFields,

  downloadOptionImage
}