import React, {useState, useEffect, useCallback} from 'react'
// import MainLayout from '../layout/Main'
import SimpleLayout from '../../layout/SingleAppBarLayout'
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';

import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import SendIcon from '@material-ui/icons/Send';
import Divider from '@material-ui/core/Divider';

import GeneralInfo from './GeneralInfo'

import DeleteOrderDialog from '../../common/dialog/DeleteOrderDialog'
import SubmitOrderDialog from '../../common/dialog/SubmitOrderDialog'
import ConfirmOrderDialog from '../../common/dialog/ConfirmOrderDialog'
import IncompleteOrderDialog from '../../common/dialog/IncompleteOrderDialog'

import {getRowValues, getFieldRenderDetails} from '../helpers'

import {useUpdateLastChange as useUpdateLastOrdersChange} from '../../../store/orders/OrdersContext'

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import moment from 'moment'
// import {useAuth} from '../../../AuthContext'

// import demoOrderData from './Demo/demo-order.json';
// import sonal80T from './Demo/demo-80T.json';

import ordersService from '../../../services/order'

import {useProductConfiguration} from '../../../store/formConfig/FormConfigContext'
// console.log({demoOrderData, sonal80T})

// const demoOrderData = ordersService.get("1")
const numberOfColumnsPerPage = 5

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// import classNames from 'classnames';
const styles = theme => ({
  root: {
    // overflowX: 'scroll'
  },
  tableWrapper: {
    overflowX: "auto",
    // maxHeight: "calc(100vh - 354px)"

    maxHeight: 'calc(100vh - 162px)',
    // overflowY: 'scroll',
    // position: 'relative',
    [theme.breakpoints.up('sm')]: {
      // maxHeight: 'calc(100vh - 392px)'
      maxHeight: 'calc(100vh - 380px)'
    },
    [theme.breakpoints.up('md')]: {
      // maxHeight: 'calc(100vh - 356px)'
      maxHeight: 'calc(100vh - 292px)'
    },


  },
  tableRow: {
    // cursor: 'pointer'
  },
  // stickyTableHead: {
  //   position: "sticky",
  //   top: 0,
  //   padding: 0,
  //   backgroundColor: "white",
  //   whiteSpace: 'nowrap'
  // }
});

const OrderToolbar = ({submitOrder, submitDisabled}) => {

  return (
    <Toolbar style={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
      {/* <div style={{flex: '1'}}></div>
      <Button
        // size="small"
        disabled={submitDisabled}
        variant="contained"
        color="primary"
        onClick={submitOrder}
        // startIcon={<CheckIcon />}
        endIcon={<SendIcon />}
      >
        Oddaj naročilo  
      </Button> */}

    </Toolbar>
  )
}

const BottomLine = () => (
  <div style={{
    position: 'absolute',
    borderBottom: '1px solid lightgray',
    width: '100%',
    bottom: 0
  }}></div>
)

const renderStickyTableHead = (entry, columnNumber) => {
  // console.log({entry, entryIndex})
  return (
    <TableCell
      style={{
        position: "sticky",
        top: 0,
        padding: 0,
        backgroundColor: "white",
        whiteSpace: 'nowrap',
        borderLeft: '1px solid #dadada',
        width: entry ? 'auto' : 340
      }}
      key={columnNumber}
      align="left"
    >
      <div style={{padding: '6px 24px 6px 16px'}}>{(entry !== null) ? (columnNumber + '.') : ''}</div>
      <BottomLine />
    </TableCell>
  )
}

const MultiselectValueRender = ({texts}) => (
  <div style={{
    width: '100%',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }}>
    <span style={{color: 'gray'}}>{`(Št. izbranih: ${texts.length}) `}</span> {texts.join(', ')}
  </div>
)

const ShoppingItemList = withRouter(withStyles(styles)(({classes, history, match, auth}) => {
  // let {user} = useAuth()

  const [orderData, setOrderData] = useState(null);
  const [workOrdersStatus, setWorkOrdersStatus] = useState(null);

  // const [anchorEl, setAnchorEl] = useState(null);
  const [deleteOrderModalData, setDeleteOrderModalData] = useState(null);
  const [submitOrderModalOpen, setSubmitOrderModalOpen] = useState(false);
  const [confirmOrderModalOpen, setConfirmOrderModalOpen] = useState(false);
  const [incompleteOrderModalOpen, setIncompleteOrderModalOpen] = useState(false);

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  let updateLastOrdersChange = useUpdateLastOrdersChange()

  const handleOrderDelete = () => {
    setDeleteOrderModalData({
      onConfirm: () => {
        ordersService.deleteOrder(orderId)
          .then(() => {
            let redirectPath = process.env.REACT_APP_BASENAME || '/'
            updateLastOrdersChange()
            history.push(redirectPath)
          })
      }
    })
    // setAnchorEl(null)
  }

  // const handleConfirmOrderClick = () => {
  //   setDeleteOrderModalData({
  //     onConfirm: () => {
  //       ordersService.deleteOrder(orderId)
  //         .then(() => {
  //           let redirectPath = process.env.REACT_APP_BASENAME || '/'
  //           updateLastOrdersChange()
  //           history.push(redirectPath)
  //         })
  //     }
  //   })
  //   setAnchorEl(null)
  // }

  const {orderId} = match.params

  const loadOrder = useCallback(
    () => {
      ordersService.getById(orderId)
        .then(setOrderData)
        .catch(e => {
          console.log("e", e)
          let redirectPath = process.env.REACT_APP_BASENAME || '/'
          history.push(redirectPath)
        })
        
      // ordersService.getAttachmentsList(orderId)
      //   .then(files => {
      //     console.log({files})
      //   })
    },
    [orderId, history]
  );

  useEffect(() => {
    loadOrder()
  }, [loadOrder]);
  
  useEffect(() => {
    if (orderData && !workOrdersStatus){
      ordersService.getWorkOrdersByOrderId(orderId)
        .then((workOrders) => {
          if (Array.isArray(workOrders)) {
            if (workOrders.length === 0){
              setWorkOrdersStatus('not-found')
            }
            else if (workOrders.length > 0){
              setWorkOrdersStatus('found')
            }
          }
        })
        .catch(e => {
          console.log("e", e)
        })
    }
  }, [ordersService, orderData, orderId]);

  let productConfig = useProductConfiguration(orderData && orderData.order && orderData && orderData.order.productId)
  // console.log({productConfig})

  if (!orderId || !orderData || !productConfig)
    return null

    // console.log({orderData})

  let {order, rows, values} = orderData

  let columnsList = rows.allIds
    .map((rowId) => rows.byId[rowId])
    .sort((row1, row2) => row1.orderNo - row2.orderNo)

  let currentPageOffsetIndex = (currentPageIndex) * numberOfColumnsPerPage
  // console.log(currentPageOffsetIndex)
  let currentPageColumnsList = columnsList.slice(currentPageOffsetIndex, currentPageOffsetIndex+numberOfColumnsPerPage)

  let fieldsToRender = productConfig.product && productConfig.product.fieldsIds
    && productConfig.product.fieldsIds.map(fieldId => productConfig.fields.byId[fieldId])

  

  // let rowsWithRenderDetails = rowsList.map((row, rowIndex) => {
  //   let {rowValues, rowValuesObjects} = getRowValues(row, values, productConfig.fields) 
  //   let fieldDetailsByFieldId = {}
  //   let isRowEmpty = true
  //   let hasEmptyFields = false

  //   fieldsToRender.forEach((field, ind) => {
  //     let valueObj = rowValuesObjects[field.id]
  //     let fieldDetails = getFieldRenderDetails({field, allOptions: productConfig.options, allRules: productConfig.rules, rowValues, valueObj})
  //     fieldDetailsByFieldId[field.id] = fieldDetails

  //     if (rowValues[field.id]){
  //       isRowEmpty = false
  //     }
  //     else {
  //       if (fieldDetails.isEnabled)
  //         hasEmptyFields = true
  //     }
  //   })

  //   return {
  //     row,
  //     rowValues,
  //     rowValuesObjects,
  //     isRowEmpty, hasEmptyFields,
  //     withError: isRowEmpty ? false : hasEmptyFields,
  //     fieldDetailsByFieldId
  //   }
  // })

  // console.log({fieldsToRender, columnsList})

  // let allRowsOk = rowsWithRenderDetails.some(({isRowEmpty}) => !isRowEmpty)
  //   && rowsWithRenderDetails.every(({withError}) => !withError)

  return (
    <>
      <DeleteOrderDialog
        open={Boolean(deleteOrderModalData)}
        onConfirm={deleteOrderModalData && deleteOrderModalData.onConfirm}
        onCancel={() => {
          setDeleteOrderModalData(null)
        }}
      />

      <SubmitOrderDialog
        open={submitOrderModalOpen}
        onConfirm={() => {
          if (orderData && orderData.order && orderData.order.id){
            ordersService.submitOrder(orderData.order.id)
              .then(() => {
                updateLastOrdersChange()
                loadOrder()
              })
              setSubmitOrderModalOpen(false)
          }
        }}
        onCancel={() => setSubmitOrderModalOpen(false)}
      />

      <ConfirmOrderDialog
        open={confirmOrderModalOpen}
        deliveryDate={orderData.order.deliveryDate}
        onConfirm={(confirmOrderDate) => {
          console.log({confirmOrderDate})
          if (orderData && orderData.order && orderData.order.id){
            ordersService.confirmOrder(orderData.order.id)
              .then(() => {
                updateLastOrdersChange()
                loadOrder()
              })
              setConfirmOrderModalOpen(false)
          }
        }}
        onCancel={() => setConfirmOrderModalOpen(false)}
      />

      <IncompleteOrderDialog
        open={incompleteOrderModalOpen}
        onConfirm={(message) => {
          if (!message)
            return
            
          if (orderData && orderData.order && orderData.order.id){

            ordersService.statusToIncomplete(orderData.order.id, message)
                .then(() => {
                  updateLastOrdersChange()
                  loadOrder()
                  setIncompleteOrderModalOpen(false)
                })
          }
        }}
        onCancel={() => setIncompleteOrderModalOpen(false)}
      />

      <Paper className={classes.root}>

        <GeneralInfo
          order={order}
          product={productConfig.product}
          history={history}
          onDeleteOrderClick={handleOrderDelete}
          onConfirmOrderClick={() => setConfirmOrderModalOpen(true)}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          pagesCount={Math.ceil(columnsList.length / numberOfColumnsPerPage)}


          workOrdersStatus={workOrdersStatus}
          onCreateWOClick={() => {
            if (workOrdersStatus === 'not-found'){
              setWorkOrdersStatus('creating')
              ordersService.createWorkOrders(order.id)
                .then(() => {
                  history.push(`/orders/${order.id}/work-orders`)
                })
            }
            else if (workOrdersStatus === 'found'){
              history.push(`/orders/${order.id}/work-orders`)
            }
          }}

          // onStatusChange={(newStatus) =>  {
          //   if (newStatus === 'CONFIRMED')
          //     setConfirmOrderModalOpen(true)
          //   else {
          //     // if (newStatus === 'DRAFT' || newStatus === 'CONFIRMED' || newStatus === 'REJECTED' || newStatus === 'PENDING')
          //     if (order.status !== 'DRAFT')
          //      ordersService.updateStatus(orderData.order.id, newStatus)
          //       .then(() => {
          //         updateLastOrdersChange()
          //         loadOrder()
          //       })
          //   }
          // }}

          onStatusChange={(newStatus) =>  {
            if (newStatus === 'CONFIRMED')
              setConfirmOrderModalOpen(true)
            else if (newStatus === 'INCOMPLETE')
              setIncompleteOrderModalOpen(true)
            else {
              // if (newStatus === 'DRAFT' || newStatus === 'CONFIRMED' || newStatus === 'REJECTED' || newStatus === 'PENDING')
              if (order.status !== 'DRAFT')
               ordersService.updateStatus(orderData.order.id, newStatus)
                .then(() => {
                  updateLastOrdersChange()
                  loadOrder()
                })
            }
          }}

          onPrintClick={() => {

            let getRows = function (offset = 0) {
              return fieldsToRender.map((field, fieldIndex) => {
                
                let type = field.type
                let fieldId = field.id
  
                let rowData = columnsList.slice(offset, offset + numberOfColumnsPerPage).map((column)  => {
  
                  let displayValue = ''
  
                  if (column.valuesIdsByFieldsIds){
                    let valuesIds = column.valuesIdsByFieldsIds[fieldId]
                    if (valuesIds){
                      if (type === 'NUMBER'){
                        valuesIds.forEach(valueId => {
                          let value = values.byId[valueId]
                          displayValue = value.number
                        })
                      }
                      else if (type === 'TEXT'){
                        valuesIds.forEach(valueId => {
                          let value = values.byId[valueId]
                          displayValue = value.text
                        })
                      }
                      else if (type === 'SELECT_ONE'){
                        valuesIds.forEach(valueId => {
                          let value = values.byId[valueId]
                          displayValue = value.fieldOptionText
                        })
                      }
                      else if (type === 'SELECT_MULTIPLE'){
                        let texts = valuesIds.map(valueId => {
                          let value = values.byId[valueId]
                          return value.fieldOptionText
                        })
                        // displayValue = <MultiselectValueRender texts={texts} />
                        displayValue = {ul: texts}
                      }
                    }
                  }
                  return displayValue
                })
  
                return [
                  {
                    noWrap: false,
                    text: field.text
                  },
                  ...rowData
                ]
              })
            }

            var getSectionDefinition = function (offset, pageBreak) {
              return [
                {
                  table: {
                    // widths: [140, ...columnsList.map(() => "*")],
                    widths: [140, ...columnsList.slice(offset, offset + numberOfColumnsPerPage).map(() => "*")],
                    body: [
                      [
                        { text: "", style: "tableHeader" },
                        ...columnsList.slice(offset, offset + numberOfColumnsPerPage).map((c, ind) => ({text: `${offset+ind+1}.`, style: "tableHeader"}))
                      ],
                      ...getRows(offset)
                    ]
                  },
                  layout: {
                    hLineWidth: function (i, node) {
                      return .6;
                    },
                    vLineWidth: function (i, node) {
                      return .6;
                    },
                    hLineColor: function (i, node) {
                      return '#d3d3d3';
                    },
                    vLineColor: function (i, node) {
                      return '#d3d3d3';
                    }
                  },
                  pageBreak: pageBreak ? 'after' : null
                }
              ]
            }

            let numberOfSections = Math.ceil(columnsList.length / numberOfColumnsPerPage)

            let allSections = Array(numberOfSections).fill().map((n, ind) => {
              let pageBreak = (ind + 1) <numberOfSections
              return getSectionDefinition(ind * numberOfColumnsPerPage, pageBreak)
            })
            
            let docDefinition = {
              // footer: function(currentPage, pageCount) { return currentPage.toString() + ' / ' + pageCount; },
              footer: function(currentPage, pageCount) {
                let footerPageText = currentPage.toString() + ' / ' + pageCount;
                return {
                  columns: [
                    { text: footerPageText, alignment: 'center' }
                  ]
                }
              },
              content: [
                {text: `Žaluzije - ${orderData.order.productName}`, bold: true},
                '\n',
                {
                  alignment: 'justify',
                  columns: [
                    {
                      text: [{text: 'Referenčna številka: ', style: {color: 'gray'}}, `${orderData.order.referenceId || ''}`]
                    },
                    {
                      text: [{text: 'Objekt/Lokacija: ', style: {color: 'gray'}}, `${orderData.order.location || ''}`]
                    },
                    {
                      text: [{text: 'Stranka: ', style: {color: 'gray'}}, `${orderData.order.finalCustomer || ''}`]
                    }
                  ]
                },
                '\n',
                {
                  alignment: 'justify',
                  columns: [
                    {
                      text: [{text: 'Željen datum dobave: ', style: {color: 'gray'}}, `${orderData.order.deliveryDate ? moment(orderData.order.deliveryDate).format("DD.MM.YYYY") : ''}`]
                    },
                    {
                      text: [{text: 'Opomba: ', style: {color: 'gray'}}, `${orderData.order.notice || ''}`]
                    },
                    {
  
                      text: (order.documents && order.documents.length) ?
                        [
                          {text: 'Št. datotek: ', style: {color: 'gray'}},
                          `${order.documents.length || ''}`
                        ]
                        :
                        {text: 'Ni priloženih datotek ', style: {color: 'gray'}}
  
                    }
                  ]
                },
                '\n',
                ...allSections
              ],
              styles: {
                tableHeader: {
                  bold: true
                }
              },
              defaultStyle: {
                fontSize: 8,
                columnGap: 20
              }
            }
            
            pdfMake.createPdf(docDefinition).open();
          }}
        />

        <Divider variant="fullWidth" style={{height: '4px'}}/>

        <div className={classes.tableWrapper}>
        
          <Table id="order-preview-table" className={classes.tabl6} size="small" style={{tableLayout: 'fixed'}}>
            <TableHead id="order-preview-thead">
              <TableRow >
                {renderStickyTableHead(null)}
                {/* {columnsList.slice(0, numberOfColumnsPerPage).map(renderStickyTableHead)} */}
                {currentPageColumnsList.map((column, columnIndex) => {
                  let columnNumber = currentPageOffsetIndex + columnIndex + 1
                  return renderStickyTableHead(column, columnNumber)
                })}
              </TableRow>
            </TableHead>
            <TableBody id="order-preview-tbody">
              {/* {rows && rows.allIds && rows.allIds.map((rowId, rowIndex) => { */}
              {/* {fieldsToRender.map((field, fieldIndex) => { */}
              {fieldsToRender.map((field, fieldIndex) => {
                
                let type = field.type
                let fieldId = field.id

                return (
                  <TableRow key={field.id} className={classes.tableRow} >
                    <TableCell component="td" scope="row" style={{whiteSpace: 'nowrap', width: 340}}>{field.text}</TableCell>
                    
                    {currentPageColumnsList.map((column)  => {
                      // console.log({column})                      

                      let displayValue = ''

                      if (column.valuesIdsByFieldsIds){
                        let valuesIds = column.valuesIdsByFieldsIds[fieldId]
                        if (valuesIds){
                          if (type === 'NUMBER'){
                            valuesIds.forEach(valueId => {
                              let value = values.byId[valueId]
                              displayValue = value.number
                            })
                          }
                          else if (type === 'TEXT'){
                            valuesIds.forEach(valueId => {
                              let value = values.byId[valueId]
                              displayValue = value.text
                            })
                          }
                          else if (type === 'SELECT_ONE'){
                            valuesIds.forEach(valueId => {
                              let value = values.byId[valueId]
                              displayValue = value.fieldOptionText
                            })
                          }
                          else if (type === 'SELECT_MULTIPLE'){
                            let texts = valuesIds.map(valueId => {
                              let value = values.byId[valueId]
                              return value.fieldOptionText
                            })
                            displayValue = <MultiselectValueRender texts={texts} />
                          }
                        }
                      }
                    
                      return (
                        <TableCell key={column.id + "-" + field.id} align="left" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', borderLeft: '1px solid #dadada', width: 'auto'}}>{displayValue}</TableCell>
                      )
                    })}
                  </TableRow >
                )
              })}
            </TableBody>
          </Table>
        </div>

      </Paper>
    </>
  )
}))

const OrderPreview = ({auth, match}) => {
  const {orderId} = match.params
  return (

    <SimpleLayout 
      headerText={`Naročilo ${orderId} - Predogled`}
      component={ShoppingItemList}
      auth={auth}
    />
  )
}

export default withRouter(OrderPreview);