import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import Grow from '@material-ui/core/Grow';

const SubmitOrderDialog = ({
  onConfirm, 
  onCancel, 
  title, 
  content = 'Želite oddati naročilo?', 
  confirmText = 'Oddaj', 
  declineText = 'Prekliči', 
  submitted = false,
  ...rest
}) => (
  <Dialog
    // open={true}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    {...rest}
  >
    {title && (
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    )}
    
    {content && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {!submitted ? (
            content
          ) : (
            <>
              {'Naročilo je uspešno oddano!'}
              <Grow in={submitted}>
                <DoneIcon
                  fontSize="large" 
                  style={{
                    color: "royalblue",
                    color: 'royalblue',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    fontSize: 66,
                    marginTop: 14,
                    marginBottom: -16,
                  }}
                />
              </Grow>
            </>
          )}
        </DialogContentText>
        
      </DialogContent>
    )}

    <DialogActions>
      {!submitted ? (
        <>
          <Button onClick={onCancel} color="default">
            {declineText}
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            {confirmText}
          </Button>
        </>
      ) : (
        <Button onClick={onCancel} color="default">
          OK
        </Button>
      )}
    </DialogActions>
  </Dialog>
)

export default SubmitOrderDialog
