import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-root.Mui-disabled input': {
      opacity: .25
    },
    '& .MuiOutlinedInput-root.Mui-disabled fieldset': {
      borderColor: '#efefef',
    },
  }
});

const OutlinedNumberField = ({id, name, value, defaultValue, text, isEnabled, readOnly, setLocalValue, setNewValue, withPlaceholderClasses, orderLoadStatus, saveDefaultNumberValue, min, max}) => {

  const [defaultValueSaved, setDefaultValueSaved] = useState(false)

  // useEffect(() => {
  //   if (text === 'Zgornji profil / prilagoditev LEVO '){
  //     if (
  //       defaultValue
  //       && (value == '')
  //       && orderLoadStatus === 'loaded'
  //     ){
  //       if (!defaultValueSaved){
  //         console.log(`Saving default value ${defaultValue} for ${text}...`)
  //         saveDefaultNumberValue(defaultValue)
  //         setDefaultValueSaved(true)
  //       }        
  //     }
  //   }
  // }, [orderLoadStatus, value, defaultValue, text, setTimeout, setNewValue, saveDefaultNumberValue])


  let classes = useStyles()

  // let displayValue = (value === "" && defaultValue !== null)
  //   ? defaultValue : value

  // let displayValue = (value === "" && defaultValue !== null)
  //   ? 12345 : value

  // if (displayValue === null)
  //   displayValue = ''

  let placeholder = text
  if (name === 'zgornji_profil-prilagoditev_levo ' || name == 'zgornji_profil-prilagoditev_desno') {
    placeholder = (defaultValue !== null) ? defaultValue.toString() : text
  }

  return (
    <TextField
      onFocus={() => {
        if (name === 'zgornji_profil-prilagoditev_levo ' || name == 'zgornji_profil-prilagoditev_desno') {
          if (
            defaultValue !== null
            && (value == '')
            && orderLoadStatus === 'loaded'
            && !defaultValueSaved
          ){
            saveDefaultNumberValue(defaultValue)
            setDefaultValueSaved(true)
          }
        }
      }}
      id={id}
      className={classes.root}
      style={{
        minWidth: 200,
        width: '100%',
        // opacity: !isEnabled ? .25 : 1,
        // opacity: !isEnabled ? .25 : 1,
        // backgroundColor: readOnly && 'lightblue'
      }} 
      // id={id}
      name={name}
      // label={value ? '' : field.text}
      // placeholder={value ? '' : text}
      // placeholder={value ? '' : defaultValue ? `${defaultValue} (${text})` : text}
      placeholder={placeholder}
      // value={displayValue}
      value={value}
      onChange={e => {
        let val = e.target.value
        console.log({val})
        if (val || val === '0') {
          if ((max || max === 0) && Number(val) > max) {
            val = max
          }
          if ((min || min === 0) && Number(val) < min) {
            val = min
          }

          setLocalValue(Number(val))
        }
        else
          setLocalValue(null)
      }}
      onBlur={(e) => setNewValue(e.target.value)}
      type="number"

      // eslint-disable-next-line 
      inputProps={{
        style: {padding: '8px 5px 5px 5px', textOverflow: 'ellipsis'}
      }}

      // eslint-disable-next-line 
      InputProps={{
        classes: {input: withPlaceholderClasses},
        readOnly
      }}

      disabled={!isEnabled}
      variant="outlined"
    />
  )
}

export default OutlinedNumberField
