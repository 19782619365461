import sonalApi from './sonal'

export function authenticate ({username, password}) {
  if (!username || !password)
    return Promise.reject()

  // return sonalApi.post('/authenticate', {
  //   username, password
  // })
  return sonalApi.post('/authenticate', {
    username, password
  }, {
    useAuthentication: false
  })
    .then(function (response) {
      if (response && response.data && response.data.id_token){
        return response.data.id_token
      }
      else {
        throw new Error('401')
      }
    })
}

export function getAccount(authToken) {

  // reject if no token in local storage
  if (!authToken)
    return Promise.reject();

  // check server if token is valid
  // return axios.get('/api/account', {
  return sonalApi.get('/account', {
    headers: {'Authorization': "Bearer " + authToken},
    toLoginIfFailed: false
  })
    .then(function (response) {
      return response.data
    })
}

export function requestPasswordReset (email) {
  return sonalApi.post('/account/reset-password/init', email, {
    headers: {"Content-Type": "application/json"},
    useAuthentication: false,
    toLoginIfFailed: false
  })
}
// window.requestPasswordReset = requestPasswordReset

export function resetPassword (newPassword, token) { 
  return sonalApi.post('/account/reset-password/finish', {
    key: token,
    newPassword
  }, {
    useAuthentication: false
  })
}

export default {
  authenticate,
  getAccount,
  resetPassword,
  requestPasswordReset
}