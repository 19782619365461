import sonalApi from './sonal'

export function getList (page = 1) {
  let options = {
    params: {
      // size: 3000,
      size: 30,
      page: page - 1,
      sort: 'id,desc'
    }
  }
  // return sonalApi.get('/customer-orders', options) // size=20&sort=id,asc
  return sonalApi.get('/orders', options) // size=20&sort=id,asc
    .then(function (response) {
      if (response && response.data){
        // console.log("headers:", response.headers)
        // console.log("data:", response.data)
        return {
          orders: response.data,
          totalCount: response.headers['x-total-count'] || null
        }
      }
      else {
        throw new Error(401)
      }
    })
}

export function getListWithoutDrafts (page = 1) {
  let options = {
    params: {
      size: 30,
      page: page - 1,
      sort: 'id,desc'
    }
  }

  return sonalApi.get('/orders/filter/status/DRAFT', options)
    .then(function (response) {
      if (response && response.data){
        return {
          orders: response.data,
          totalCount: response.headers['x-total-count'] || null
        }
      }
      else {
        throw new Error(401)
      }
    })
}

export function getById (id) {
  return sonalApi.get(`/orders/${id}`, {toLoginIfFailed: false})
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function updateFieldValue (data) {
  // let data = updatedFieldValueParameters
  return sonalApi.put(`/field-values`, data)
    .then(function (response) {
      if (response && response){
        return response
      }
      else {
        throw new Error(401)
      }
    })
}

export function removeFieldValue (valueId) {
  return sonalApi.delete(`/field-values/${valueId}`)
    .then(function (response) {
      if (response && response){
        return response
      }
      else {
        throw new Error(401)
      }
    })
}

export function createFieldValue (data) {
  // let data = updatedFieldValueParameters
  return sonalApi.post(`/field-values`, data)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function createRows (orderId, positionNumbers) {
  // if (!positionNumbers) 
  // positionNumbers = [1,2]
  return sonalApi.post(`/create-rows/${orderId}`, positionNumbers)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function createOrder (productId, userId, status = 'DRAFT', deliveryDate = null) {
  let data = {
    productId,
    userId,
    status,
    deliveryDate
  }
  return sonalApi.post(`/customer-orders`, data)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function deleteOrder(orderId) {
  
  return sonalApi.delete(`/orders/${orderId}`)
    .then(function (response) {
      if (response){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function deleteRow (rowId) {
  return sonalApi.delete(`/customer-order-rows/${rowId}`)
    .then(function (response) {
      if (response){
        return response
      }
      else {
        throw new Error(401)
      }
    })
}

export function duplicateRow(rowId, times) {
  // return Promise.resolve()
  return sonalApi.post(`/customer-order-rows/${rowId}/multiply/${times}`)
    .then(function (response) {
      if (response){
        return response
      }
      else {
        throw new Error(401)
      }
    })
}


export function updateOrder (updatedOrder) {
  return sonalApi.put(`/customer-orders`, updatedOrder)
    .then(function (response) {
      if (response){
        return response
      }
      else {
        throw new Error(401)
      }
    })
}

export function updateStatus (orderId, status) {
  return sonalApi.put(`/orders/${orderId}/${status}`)
    .then(function (response) {
      if (response){
        return response
      }
      else {
        throw new Error(401)
      }
    })
}

export function statusToIncomplete (orderId, message) {
  let status = 'INCOMPLETE'
  console.log(orderId, message)
  return sonalApi.put(`/orders/${orderId}/${status}`, message, {headers: {"Content-Type": "text/plain"}})
    .then(function (response) {
      if (response){
        return response
      }
      else {
        throw new Error(401)
      }
    })
}

export function submitOrder (orderId) {
  return updateStatus(orderId, 'PENDING')
  // return updateStatus(orderId, 'DRAFT')
}
export function confirmOrder (orderId) {
  return updateStatus(orderId, 'CONFIRMED')
}

export function getAttachmentsList (orderId) {
  return sonalApi.get(`/documents/${orderId}`)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        // console.log(response)
        throw new Error(401)
      }
    })
    .catch(err => {
      // if (err && err.response && err.response.status === 404)
        return []
    })
}

export function uploadFile(orderId, data){
  
  return sonalApi.post(`/upload-file/${orderId}`, data, {timeout: 20000})
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        console.log(response)
        throw new Error(401)
      }
    })

}

export function downloadFile(orderId, fileName){
  return sonalApi.get(`/download-file/${orderId}/${fileName}`, {
    responseType: 'blob'
  })
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function deleteFile(orderId, fileName){
  return sonalApi.delete(`/download-file/${orderId}/${fileName}`)
}

export function createWorkOrders(orderId){
  // return sonalApi.get(`/worksheet/${orderId}`)
  return sonalApi.get(`/work-orders/create/${orderId}`)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function getWorkOrdersByOrderId(orderId){
  return sonalApi.get(`/work-orders/order/${orderId}`)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function getOriginalWorkOrdersByOrderId(orderId){
  return sonalApi.get(`/work-orders/order/${orderId}/version/ORIGINAL`)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function updateWorkOrder(workOrder){
  return sonalApi.put(`/work-orders`, workOrder)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function getErpCustomers(subject){
  return sonalApi.get(`/erp-customers/top30/{subject}?subject=${subject}`)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function getErpDataByOrderId(orderId){
  return sonalApi.get(`/erps/order/${orderId}`)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function getStickersData(orderId){
  return sonalApi.get(`/stikers/print/${orderId}`)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}

export function printStickers(orderId, stickersIds = []){
  return sonalApi.post(`/stikers/print/${orderId}`, stickersIds)
    .then(function (response) {
      if (response && response.data){
        return response.data
      }
      else {
        throw new Error(401)
      }
    })
}



export default {
  getList,
  getListWithoutDrafts,
  getById,
  createOrder,
  deleteOrder,

  updateFieldValue,
  removeFieldValue,
  createFieldValue,

  createRows,
  deleteRow,
  duplicateRow,

  updateOrder,
  submitOrder,
  confirmOrder,
  getAttachmentsList,

  updateStatus,
  statusToIncomplete,
  uploadFile,
  downloadFile,
  deleteFile,
  createWorkOrders,
  getWorkOrdersByOrderId,
  getOriginalWorkOrdersByOrderId,
  updateWorkOrder,
  getErpCustomers,
  getErpDataByOrderId,
  getStickersData,
  printStickers
}