import React, {useState, useEffect, useCallback} from 'react'
// import MainLayout from '../layout/Main'
import SimpleLayout from '../../layout/SingleAppBarLayout'
import {withRouter} from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box';

import ErpTable from './ErpTable'
import Divider from '@material-ui/core/Divider'
import GeneralInfo from './GeneralInfo'

import ordersService from '../../../services/order'

import {useProductConfiguration} from '../../../store/formConfig/FormConfigContext'

const PantheonPreview = ({classes, history, match}) => {

  const [orderData, setOrderData] = useState(null);
  const [erpData, setErpData] = useState(null);

  const [activeTab, setActiveTab] = useState(0)
  
  const {orderId} = match.params

  const loadOrder = useCallback(() => {
    ordersService.getById(orderId)
      .then(setOrderData)
      .catch(e => {
        console.log("e", e)
        let redirectPath = process.env.REACT_APP_BASENAME || '/'
        history.push(redirectPath)
      })
    },
    [orderId, history]
  )

  const loadErpData = useCallback(() => {
    ordersService.getErpDataByOrderId(orderId)
      .then(setErpData)
    },
    [orderId]
  )

  useEffect(() => {
    loadOrder()
  }, [loadOrder]);
  
  useEffect(() => {
    loadErpData()
  }, [loadErpData]);

  let productConfig = useProductConfiguration(orderData && orderData.order && orderData && orderData.order.productId)

  if (!orderId || !orderData || !productConfig)
    return null

  let {order, rows, values} = orderData


  // console.log({erpData})
  return (
    <Paper>
      <GeneralInfo
        hasChanged={false}
        order={order}
        product={productConfig.product}
        history={history}
        onConfirmOrderClick={() => {}}
        saveEnabled={false}
        showOriginal={false}
        onShowOriginalClick={() => {}}
        onSave={() => {}}
      />

      <Divider variant="fullWidth" style={{height: '4px'}}/>

      <AppBar position="static" color="default" elevation={0}> 
        
        <Box justifyContent="space-between" flexWrap="nowrap" display="flex">
          <Tabs value={activeTab}
            // variant="scrollable"  
            // scrollButtons="auto"
            onChange={(e, tabIndex) => {
              setActiveTab(tabIndex)
            }}
            indicatorColor="primary"
            textColor="primary"
          >
            {erpData && erpData.map(({id, workOrderTypeDescription}, ind) => (
              <Tab key={ind} label={workOrderTypeDescription} />
            ))}
          </Tabs>
          
          
        </Box>

      </AppBar>

      <div style={{
        overflow: "auto",
        maxHeight: 'calc(100vh - 292px - 60px)'
      }}>
        <ErpTable positions={erpData && erpData[activeTab] && erpData[activeTab].erpOrderItems} />
      </div>
    </Paper>   
  )
}

const PantheonPreviewPage = ({auth, match, ...rest}) => {
  const {orderId} = match.params
  return (

    <SimpleLayout 
      headerText={`Naročilo ${orderId} - Predogled uvoza v Pantheon`}
      component={PantheonPreview}
      auth={auth}
      {...rest}
    />
  )
}

export default withRouter(PantheonPreviewPage);