import React, {useState, useEffect, useCallback} from 'react'
// import MainLayout from '../layout/Main'
import SimpleLayout from '../../layout/SingleAppBarLayout'
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';

import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import SendIcon from '@material-ui/icons/Send';
import Divider from '@material-ui/core/Divider';

import GeneralInfo from './GeneralInfo'

import DeleteOrderDialog from '../../common/dialog/DeleteOrderDialog'
import SubmitOrderDialog from '../../common/dialog/SubmitOrderDialog'
import ConfirmOrderDialog from '../../common/dialog/ConfirmOrderDialog'
import IncompleteOrderDialog from '../../common/dialog/IncompleteOrderDialog'

import {getRowValues, getFieldRenderDetails} from '../helpers'

import {useUpdateLastChange as useUpdateLastOrdersChange} from '../../../store/orders/OrdersContext'

// import {useAuth} from '../../../AuthContext'97

// import demoOrderData from './Demo/demo-order.json';
// import sonal80T from './Demo/demo-80T.json';

import ordersService from '../../../services/order'

import {useProductConfiguration} from '../../../store/formConfig/FormConfigContext'
// console.log({demoOrderData, sonal80T})

// const demoOrderData = ordersService.get("1")

// import classNames from 'classnames';
const styles = theme => ({
  root: {
    // overflowX: 'scroll'
  },
  tableWrapper: {
    overflowX: "auto",
    // maxHeight: "calc(100vh - 354px)"

    maxHeight: 'calc(100vh - 162px)',
    // overflowY: 'scroll',
    // position: 'relative',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 392px)'
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 356px)'
    },


  },
  tableRow: {
    // cursor: 'pointer'
  },
  // stickyTableHead: {
  //   position: "sticky",
  //   top: 0,
  //   padding: 0,
  //   backgroundColor: "white",
  //   whiteSpace: 'nowrap'
  // }
});

const OrderToolbar = ({submitOrder, submitDisabled}) => {

  return (
    <Toolbar style={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
      <div style={{flex: '1'}}></div>
      <Button
        // size="small"
        disabled={submitDisabled}
        variant="contained"
        color="primary"
        onClick={submitOrder}
        // startIcon={<CheckIcon />}
        endIcon={<SendIcon />}
      >
        Oddaj naročilo  
      </Button>

    </Toolbar>
  )
}

const BottomLine = () => (
  <div style={{
    position: 'absolute',
    borderBottom: '1px solid lightgray',
    width: '100%',
    bottom: 0
  }}></div>
)

const renderStickyTableHead = ({text, id}) => (
  <TableCell
    style={{
      position: "sticky",
      top: 0,
      padding: 0,
      backgroundColor: "white",
      whiteSpace: 'nowrap'
    }}
    key={id}
    // align="right"
  >
    <div style={{padding: '6px 24px 6px 16px'}}>{text}</div>
    <BottomLine />
  </TableCell>
)

const MultiselectValueRender = ({texts}) => (
  <div style={{
    width: 320,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }}>
    <span style={{color: 'gray'}}>{`(Št. izbranih: ${texts.length}) `}</span> {texts.join(', ')}
  </div>
)

const ShoppingItemList = withRouter(withStyles(styles)(({classes, history, match, auth}) => {
  // let {user} = useAuth()

  const [orderData, setOrderData] = useState(null);
  const [workOrdersStatus, setWorkOrdersStatus] = useState(null);

  // const [anchorEl, setAnchorEl] = useState(null);
  const [deleteOrderModalData, setDeleteOrderModalData] = useState(null);
  const [submitOrderModalData, setSubmitOrderModalData] = useState({open: false, submitted: false});
  const [confirmOrderModalOpen, setConfirmOrderModalOpen] = useState(false);
  const [rejectOrderModalOpen, setRejectOrderModalOpen] = useState(false);

  // ToDo: rename rejectOrderModal to incompleteOrderModal

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  let updateLastOrdersChange = useUpdateLastOrdersChange()

  const handleOrderDelete = () => {
    setDeleteOrderModalData({
      onConfirm: () => {
        ordersService.deleteOrder(orderId)
          .then(() => {
            let redirectPath = process.env.REACT_APP_BASENAME || '/'
            updateLastOrdersChange()
            history.push(redirectPath)
          })
      }
    })
    // setAnchorEl(null)
  }

  // const handleConfirmOrderClick = () => {
  //   setDeleteOrderModalData({
  //     onConfirm: () => {
  //       ordersService.deleteOrder(orderId)
  //         .then(() => {
  //           let redirectPath = process.env.REACT_APP_BASENAME || '/'
  //           updateLastOrdersChange()
  //           history.push(redirectPath)
  //         })
  //     }
  //   })
  //   setAnchorEl(null)
  // }

  const {orderId} = match.params

  const loadOrder = useCallback(
    () => {
      ordersService.getById(orderId)
        .then(setOrderData)
        .catch(e => {
          console.log("e", e)
          let redirectPath = process.env.REACT_APP_BASENAME || '/'
          history.push(redirectPath)
        })
    },
    [orderId, history]
  );
  


  useEffect(() => {
    loadOrder()
  }, [loadOrder]);
  // console.log({orderData})

  useEffect(() => {
    if (orderData && !workOrdersStatus){
      ordersService.getWorkOrdersByOrderId(orderId)
        .then((workOrders) => {
          if (Array.isArray(workOrders)) {
            if (workOrders.length === 0){
              setWorkOrdersStatus('not-found')
            }
            else if (workOrders.length > 0){
              setWorkOrdersStatus('found')
            }
          }
        })
        .catch(e => {
          console.log("e", e)
        })
    }
  }, [ordersService, orderData, orderId]);

  let productConfig = useProductConfiguration(orderData && orderData.order && orderData && orderData.order.productId)
  // console.log({productConfig})

  if (!orderId || !orderData || !productConfig)
    return null

    // console.log({orderData})

  let {order, rows, values} = orderData

  let rowsList = rows.allIds
    .map((rowId) => rows.byId[rowId])
    .sort((row1, row2) => row1.orderNo - row2.orderNo)

  let fieldsToRender = productConfig.product && productConfig.product.fieldsIds
    && productConfig.product.fieldsIds.map(fieldId => productConfig.fields.byId[fieldId])

  let rowsWithRenderDetails = rowsList.map((row, rowIndex) => {
    let {rowValues, rowValuesObjects} = getRowValues(row, values, productConfig.fields) 
    let fieldDetailsByFieldId = {}
    let isRowEmpty = true
    let hasEmptyRequiredFields = false

    fieldsToRender.forEach((field, ind) => {
      let valueObj = rowValuesObjects[field.id]
      let fieldDetails = getFieldRenderDetails({field, allOptions: productConfig.options, allRules: productConfig.rules, rowValues, valueObj})
      fieldDetailsByFieldId[field.id] = fieldDetails

      if (rowValues[field.id]){
        isRowEmpty = false
      }
      else {
        // if (fieldDetails.isEnabled){
        if (fieldDetails.isEnabled && field.type !== 'SELECT_MULTIPLE'){
          hasEmptyRequiredFields = true
        }
      }
    })

    let withError = isRowEmpty ? false : hasEmptyRequiredFields
    
    return {
      row,
      rowValues,
      rowValuesObjects,
      isRowEmpty, hasEmptyRequiredFields,
      withError,
      fieldDetailsByFieldId
    }
  })

  let allRowsOk = rowsWithRenderDetails.some(({isRowEmpty}) => !isRowEmpty)
    && rowsWithRenderDetails.every(({withError}) => !withError)
// console.log({submitOrderModalData})
  return (
    <>
      <DeleteOrderDialog
        open={Boolean(deleteOrderModalData)}
        onConfirm={deleteOrderModalData && deleteOrderModalData.onConfirm}
        onCancel={() => {
          setDeleteOrderModalData(null)
        }}
      />

      <SubmitOrderDialog
        open={submitOrderModalData.open}
        submitted={submitOrderModalData && submitOrderModalData.submitted}
        onExited={() => setSubmitOrderModalData({open: false, submitted: false})}
        onConfirm={() => {
          if (orderData && orderData.order && orderData.order.id){
            ordersService.submitOrder(orderData.order.id)
              .then(() => {
                updateLastOrdersChange()
                loadOrder()
                // alert("aaa")
                setSubmitOrderModalData({
                  ...submitOrderModalData,
                  submitted: true,
                })
              })
              // setSubmitOrderModalData(null)
          }
        }}
        onCancel={() => setSubmitOrderModalData({
          ...submitOrderModalData,
          open: false
        })}
      />

      <ConfirmOrderDialog
        open={!!confirmOrderModalOpen}
        deliveryDate={orderData.order.deliveryDate}
        onConfirm={(confirmOrderDate) => {
          // console.log({confirmOrderDate})
          if (orderData && orderData.order && orderData.order.id){
            ordersService.confirmOrder(orderData.order.id)
              .then(() => {
                updateLastOrdersChange()
                loadOrder()
              })
              setConfirmOrderModalOpen(false)
          }
        }}
        onCancel={() => setConfirmOrderModalOpen(false)}
      />

      <IncompleteOrderDialog
        open={rejectOrderModalOpen}
        onConfirm={(message) => {
          if (!message)
            return
            
          if (orderData && orderData.order && orderData.order.id){

            ordersService.statusToIncomplete(orderData.order.id, message)
                .then(() => {
                  updateLastOrdersChange()
                  loadOrder()
                  setRejectOrderModalOpen(false)
                })
          }
        }}
        onCancel={() => setRejectOrderModalOpen(false)}
      />

      <Paper className={classes.root}>

        <GeneralInfo
          order={order}
          product={productConfig.product}
          history={history}
          onDeleteOrderClick={handleOrderDelete}
          onConfirmOrderClick={() => setConfirmOrderModalOpen(true)}
          onStatusChange={(newStatus) =>  {
            if (newStatus === 'CONFIRMED')
              setConfirmOrderModalOpen(true)
            else if (newStatus === 'INCOMPLETE')
              setRejectOrderModalOpen(true)
            else {
              // if (newStatus === 'DRAFT' || newStatus === 'CONFIRMED' || newStatus === 'REJECTED' || newStatus === 'PENDING')
              if (order.status !== 'DRAFT')
               ordersService.updateStatus(orderData.order.id, newStatus)
                .then(() => {
                  updateLastOrdersChange()
                  loadOrder()
                })
            }
          }}
          workOrdersStatus={workOrdersStatus}
          onCreateWOClick={() => {
            if (workOrdersStatus === 'not-found'){
              setWorkOrdersStatus('creating')
              ordersService.createWorkOrders(order.id)
                .then(() => {
                  history.push(`/orders/${order.id}/work-orders`)
                })
            }
            else if (workOrdersStatus === 'found'){
              history.push(`/orders/${order.id}/work-orders`)
            }
          }}
        />



        <Divider variant="fullWidth" style={{height: '4px'}}/>

        <div className={classes.tableWrapper}>
        
          <Table id="order-view-table" className={classes.tabl6} size="small">
            <TableHead id="order-view-thead">
              <TableRow >
                {renderStickyTableHead('')}
                {/* {productConfig.fields.allIds.map(fieldId => { */}
                {fieldsToRender.map(renderStickyTableHead)}
              </TableRow>
            </TableHead>
            <TableBody id="order-view-tbody">
              {/* {rows && rows.allIds && rows.allIds.map((rowId, rowIndex) => { */}
              {rowsList.map((row, rowIndex) => {
                // let row = rows.byId[rowId]
                // console.log({row})
                return (
                  <TableRow key={row.id} className={classes.tableRow} >
                    <TableCell component="th" scope="row">{rowIndex +1}</TableCell>
                    
                    {fieldsToRender.map((field)  => {
                      let {id, type} = field
                      let fieldId = field.id
                      // let {id, type} = productConfig.fields.byId[fieldId]
                      let displayValue = ''
                      // let displayValue = row[field.id] || ''
                      // let displayValue
                      // if (type === 'FUNCTION'){
                      //   let fieldFunction = eval(field.value)
                      //   displayValue = fieldFunction(row)
                      // }
                      // else 
                      //   displayValue = row[field.name] || ''

                      if (row.valuesIdsByFieldsIds){
                        let valuesIds = row.valuesIdsByFieldsIds[fieldId]
                        if (valuesIds){
                          if (type === 'NUMBER'){
                            valuesIds.forEach(valueId => {
                              let value = values.byId[valueId]
                              displayValue = value.number
                            })
                          }
                          else if (type === 'TEXT'){
                            valuesIds.forEach(valueId => {
                              let value = values.byId[valueId]
                              displayValue = value.text
                            })
                          }
                          else if (type === 'SELECT_ONE'){
                            valuesIds.forEach(valueId => {
                              let value = values.byId[valueId]
                              displayValue = value.fieldOptionText
                            })
                          }
                          else if (type === 'SELECT_MULTIPLE'){
                            let texts = valuesIds.map(valueId => {
                              let value = values.byId[valueId]
                              return value.fieldOptionText
                            })
                            // console.log(texts)
                            // displayValue = `Št. izbranih: ${valuesIds && valuesIds.length}`

                            // texts = valuesIds.map(valueId => options.find(o => o.id === valueId).text)
                            
                            displayValue = <MultiselectValueRender texts={texts} />

                          }
                        }
                      }
                    
                      return (
                        <TableCell id={`${field.name}`} key={row.id + '-' + id} align="right" style={{whiteSpace: 'nowrap'}}>{displayValue}</TableCell>
                      )
                    })}
                    
                  </TableRow >
                )
              })}
            </TableBody>
          </Table>
        </div>

        {/* {orderData && orderData.order && orderData.order.status === 'PENDING' && ( */}
        {orderData && orderData.order && orderData.order.status === 'DRAFT' && (
          <OrderToolbar
            submitOrder={() => {
              setSubmitOrderModalData({open: true, submitted: false})
            }}
            userLogin={orderData.order.userLogin}
            submitDisabled={orderData.order.userLogin !== auth.user.login || !allRowsOk || !order.id}
          />
        )}
      </Paper>
    </>
  )
}))

const OrderPreview = ({auth, match}) => {
  const {orderId} = match.params
  return (

    <SimpleLayout 
      headerText={`Naročilo ${orderId}`}
      component={ShoppingItemList}
      auth={auth}
    />
  )
}

export default withRouter(OrderPreview);