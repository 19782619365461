import React from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from "@material-ui/pickers";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import FileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FileBrowserDialog from '../../../FileBrowserDialog';

import {useAuth} from '../../../../../AuthContext'
import FinalCustomer from './FinalCustomer'

const renderStatusText = (status) => {

  if (!status)
    return null

  let icon = null
  let color = 'black'
  let statusText = ''

  if (status === 'DRAFT'){
    color = 'gray'
    // icon = <EditIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
    statusText = 'Osnutek'
  }

  return (
    <span align="right" style={{color}}>{statusText}{icon}</span>
  )
}

const renderCreatedByText = (userName) => (<>
  <span style={{ color: "#757575" }}>Uporabnik: </span>
  {userName}
</>)
const renderUserCompanyText = (company, userName) => (<>
  <span style={{ color: "#757575" }}>Naročnik: </span>
  {company}
  {userName && <span style={{ color: "#757575" }}>{` (${userName})`}</span>}
</>)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  moreButton: {
    marginLeft: theme.spacing(2),
  },
  statusText: {
    // flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  companyText: {
    marginLeft: '2em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexShrink: 4
  },
  title: {
    marginRight: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  fieldsContainer: {
    // padding: theme.spacing(2),
    padding: theme.spacing(2, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3)
    }
  }
}));



const getMinDate = () => {
  let date = new Date()
  date.setDate(date.getDate() + 15)
  return date
}

const GeneralInfo = ({
  order, product, commonInfoFieldClasses, onDeleteOrderClick, onDateChange, onNoticeChange,
  onTextFieldChange, onTextFieldBlur,
  reloadOrder, readOnlyFields
}) => {
  // console.log({order})

  const classes = useStyles();

  const [moreMenuAnchorEl, setMMAnchorEl] = React.useState(null);


  const textChangeHandler = (fieldName) => (
    (e) => onTextFieldChange(fieldName, e.target.value)
  )


  const handleMoreClick = event => {
    // console.log(event.currentTarget)
    setMMAnchorEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setMMAnchorEl(null);
  };

  const handleDeleteOrder = () => {
    onDeleteOrderClick(order.id);
    setMMAnchorEl(null)
  };

  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  const [noticeModalOpen, setNoticeModalOpen] = React.useState(false);
  const [noticeLocalText, setNoticeLocalText] = React.useState(null);

  const [fileModalOpen, setFileModalOpen] = React.useState(false);
  // console.log({order})
  const minDate = getMinDate()

  let noticeOneRowValue = order.notice ? order.notice.split('\n').join(' ') : ''
  let orderHasDocuments = order.documents && order.documents.length > 0

  const {isUser, isAdmin, isManager, isOperator, user} = useAuth()
  const isOnlyUser = isUser && !isOperator && !isManager && !isAdmin

  return (
    <div>
      <AppBar position="relative" color="default" elevation={0}>
        <Toolbar variant="dense">
         
          <Typography variant="h6" component="h6" className={classes.title}>
            {product.productTypeName} - {product.name}
          </Typography>
          {/* <Button color="inherit">Login</Button> */}

          <Typography variant="subtitle1" component="h6" className={classes.statusText}>
            {order.id && (<>
              <span style={{color: '#757575'}}>Status: </span>{renderStatusText(order.status)}
            </>)}
          </Typography>

          <Typography
            variant="subtitle1"
            component="h6"
            className={classes.companyText}
          >
             {isOnlyUser ?
              renderCreatedByText(order.userName)
              : renderUserCompanyText(order.userCompany, order.userName)
            }
          </Typography>

          <div style={{flexGrow: 1, width: '1em'}}></div>
          
          <IconButton disabled={!order.id} onClick={handleMoreClick} className={classes.moreButton} color="inherit" aria-label="menu">
            <MoreVertIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div className={classes.fieldsContainer}>
        

        <Grid container spacing={3} >
          
          <Grid item xs={12} sm={6} md={4}>
            <TextField fullWidth
              id="referenceId"
              label="Referenčna številka"
              margin="none"
              onChange={textChangeHandler('referenceId')}
              onBlur={() => onTextFieldBlur('referenceId')}
              value={order.referenceId || ''}
              InputProps={{readOnly: readOnlyFields}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField fullWidth
              id="location"
              label="Objekt/Lokacija"
              margin="none"
              onChange={textChangeHandler('location')}
              onBlur={() => onTextFieldBlur('location')}
              value={order.location || ''}
              InputProps={{readOnly: readOnlyFields}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FinalCustomer
              textChangeHandler={textChangeHandler}
              onTextFieldChange={onTextFieldChange}
              onTextFieldBlur={onTextFieldBlur}
              finalCustomer={order.finalCustomer}
              readOnlyFields={readOnlyFields}
              renderErpSelect={isAdmin || isManager || isOperator}
            />
            {/* <TextField fullWidth
              id="finalCustomer"
              label="Stranka"
              margin="none"
              onChange={textChangeHandler('finalCustomer')}
              onBlur={() => onTextFieldBlur('finalCustomer')}
              value={order.finalCustomer || ''}
              InputProps={{readOnly: readOnlyFields}}
            /> */}

          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker fullWidth
              readOnly={readOnlyFields}
              id="deliveryDate"
              open={datePickerOpen}
              onOpen={() => setDatePickerOpen(true)}
              onClose={() => setDatePickerOpen(false)}
              // style={{cursor: 'pointer'}}
              label="Željen datum dobave"
              format="DD.MM.YYYY"
              value={order.deliveryDate}
              minDate={minDate}
              // open={datepickerOpen}
              // onFocus={() => setDatepickerOpen(true)}
              // onOpen={() => setDatepickerOpen(true)}
              // onClose={() => setDatepickerOpen(false)}
              minDateMessage={"Datum ne sme biti pred minimalnim"}
              // onChange={(newDateObj, dateString) => {
              //   console.log({newDateObj, dateString})
              //   onDateChange(dateString)
              // }}
              onChange={onDateChange}
              animateYearScrolling
              margin="none"
              className={commonInfoFieldClasses}

              onKeyPress={(e) => {
                setDatePickerOpen(true)
                // if (e.key === 'Enter')
                //   setDatePickerOpen(true)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              id="notice"
              label="Dodaj opombo"
              readOnly
              fullWidth
              inputProps={{
                style:{textOverflow: 'ellipsis'}
              }}
              InputProps={{
                readOnly: true,
              }}
              value={noticeOneRowValue || ''}
              // onChange={textChangeHandler('notice')}
              // onBlur={() => onTextFieldBlur('notice')}
              margin="none"
              onClick={() => setNoticeModalOpen(true)}
              onKeyPress={(e) => {
                setNoticeModalOpen(true)
                // if (e.key === ' ' || e.key === 'Enter') 
                //   setNoticeModalOpen(true)
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={4} style={{marginTop: 'auto'}}>
            <input style={{display: 'none'}}
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button fullWidth variant="outlined" component="span" startIcon={<AttachFileIcon />}>
                Priložite datoteke
              </Button>
            </label>
          </Grid> */}
          <Grid container item xs={12} sm={12} md={4} style={{marginTop: 12}}>
            {/* <input style={{display: 'none'}}
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
            /> */}
            {orderHasDocuments && (
              <Grid item xs={6} style={{alignSelf: 'center', display: orderHasDocuments ? 'flex' : 'none'}}>
                <Typography variant="subtitle1" component="h6" style={{cursor: 'pointer'}} onClick={() => setFileModalOpen(true)}>
                <span style={{ color: "#757575" }}>Št. datotek: </span>
                <span id="numOfDocuments">{order.documents.length}</span>
                </Typography>
              </Grid>
            )}
            <Grid item xs={orderHasDocuments ? 6 : 12}>
              <Button disabled={!order.id} variant="outlined" fullWidth component="span" startIcon={<FileIcon color={order.id ? "primary" : "disabled"}/>} onClick={() => setFileModalOpen(true)}>
                {orderHasDocuments ? 'Datoteke' : 'Priloži datoteke'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Menu
        id="order-more-menu"
        anchorEl={moreMenuAnchorEl}
        keepMounted
        open={Boolean(moreMenuAnchorEl)}
        onClose={handleMoreClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleDeleteOrder}>Izbriši naročilo</MenuItem>
      </Menu>

      <Dialog onClose={() => {
        if (!noticeLocalText) {
          setNoticeModalOpen(false)
          setNoticeLocalText(null)
        }
      }} 
        aria-labelledby="edit-notice-dialog"
        open={noticeModalOpen}
        fullWidth
        maxWidth="sm"
      >
        {/* <DialogTitle >Opomba</DialogTitle> */}
        <DialogContent>
          <TextField
            // autoFocus
            InputProps={{
              readOnly: readOnlyFields
            }}
            label="Dodaj opombo"
            multiline
            fullWidth
            rowsMax="10"
            rows="4"
            value={noticeLocalText !== null ? noticeLocalText : order.notice || ''}
            onChange={(e) => setNoticeLocalText(e.target.value)}
            // onBlur={() => onTextFieldBlur('notice')}
            margin="none"
            onClick={() => setNoticeModalOpen(true)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setNoticeModalOpen(false)
            setNoticeLocalText(null)
          }}>
            Prekliči
          </Button>
          <Button onClick={(e) => {

            if (noticeLocalText !== null && noticeLocalText !== order.notice){
              onNoticeChange(noticeLocalText)
            }
            setNoticeModalOpen(false)
            setNoticeLocalText(null)
          }} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>


      <FileBrowserDialog
        open={fileModalOpen}
        order={order}
        orderHasDocuments={orderHasDocuments}
        onClose={() => setFileModalOpen(false)}
        reloadOrder={reloadOrder}
      />

    </div>
  )
}

export default GeneralInfo