import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import ErrorIcon from '@material-ui/icons/Error';

import { DatePicker } from "@material-ui/pickers";

const IncompleteOrderDialog = ({
  onConfirm, 
  onCancel,
  ...rest
}) => {

  let [message, setMessage] = useState('')

  return (
    <Dialog
      // open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onExited={() => setMessage('')}
      {...rest}
    >
      <DialogTitle id="form-dialog-title"> Nepopolno naročilo <ErrorIcon style={{verticalAlign: 'sub', color: 'red'}}/></DialogTitle>
      
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {'Vpišite napako naročila, ki se bo poslala kupcu'}
        </DialogContentText>

        <TextField
          label="Opis napake"
          multiline
          fullWidth
          rows="4"
          rowsMax="8"
          value={message}
          variant="outlined" 
          onChange={e => setMessage(e.target.value)}
          // onBlur={onNoticeBlur}
          // className={classes.textField}
          margin="none"
        />

        
      </DialogContent>
      
      <DialogActions>
        {/* <Button onClick={onCancel} color="primary"> */}
        <Button onClick={onCancel} color="default">
          {'Prekliči'}
        </Button>
        <Button onClick={() => onConfirm(message)} color="primary" autoFocus disabled={false} >
        {/* <Button onClick={onConfirm} color="default" autoFocus> */}
          {'Potrdi in pošlji'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default IncompleteOrderDialog