
const resetFieldsRecursively = (fieldId, rowValuesObjects, allOptions, allRules, fieldsIdsToReset = []) => {
  let previousValue = rowValuesObjects[fieldId]

  if (!previousValue)
    return fieldsIdsToReset

  if (previousValue.optionId || previousValue.number || previousValue.text){

    if (!fieldsIdsToReset.includes(fieldId))
      fieldsIdsToReset.push(fieldId)

    let previousOption = allOptions.byId[previousValue.optionId]
  
    if (previousOption && previousOption.childrenRulesIds) {
      previousOption.childrenRulesIds.forEach(ruleId => {
        let rule = allRules.byId[ruleId]
        // console.log({rule})
        if (rule.childFieldId){
          fieldsIdsToReset = resetFieldsRecursively(rule.childFieldId, rowValuesObjects, allOptions, allRules, fieldsIdsToReset)
          // console.log({rule})
  
        }
        if (rule.childOptionFieldId){
          let childOption = allOptions.byId[rule.childOptionFieldId]
          if (childOption && childOption.fieldId){
            let childValueObject = rowValuesObjects[childOption.fieldId]
            if (childValueObject && childValueObject.optionId){
              let isSelected = childOption.id === childValueObject.optionId
              if (isSelected){
                fieldsIdsToReset = resetFieldsRecursively(childOption.fieldId, rowValuesObjects, allOptions, allRules, fieldsIdsToReset)
              }
            }
            
          }
        }
      })
    }
  }

  return fieldsIdsToReset
  
}

const getReadOnlyUpdates = (fieldId, rowValuesObjects, allOptions, allRules, newValue, readOnlyUpdates=[]) => {
  let option = allOptions.byId[newValue]
  if (option && option.childrenRulesIds) {
    option.childrenRulesIds.forEach(ruleId => {
      let rule = allRules.byId[ruleId]
      if (rule.readonly){
        readOnlyUpdates.push(rule)
      }
    })
  }
  return readOnlyUpdates
}

export {
  resetFieldsRecursively,
  getReadOnlyUpdates
}