import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IncompleteOrderDialog from '../../../common/dialog/IncompleteOrderDialog';

export default ({
  // positions,
  erpData
}) => {

  let positions = erpData

  if (!positions)
    return null

  return (
    <Table size="small" style={{
      tableLayout: 'fixed',
    }}>
      <TableHead>
        <TableRow >
          <TableCell align="left" style={{
              width: 225
          }}>shopField</TableCell>
          {positions.map((item, index) => (
            <TableCell key={index} align="left" style={{
              width: 225
            }}>{index + 1}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        
        {positions[0] && positions[0].erpOrderPartsList.map((erpItem, ind) => {  
          return (
            <TableRow key={ind} >
              <TableCell
                key={ind}
                align="left" style={{
                  overflow: 'hidden', textOverflow: 'ellipsis', borderLeft: '1px solid #dadada', width: 'auto',
                  // color: 'gray' : 'unset'
                }}
              >
                {erpItem.shopField}
              </TableCell>

              {positions.map((position, pIndex) => {
                let erpPosition = positions[pIndex] && positions[pIndex].erpOrderPartsList && positions[pIndex].erpOrderPartsList[ind]
                return (
                  <TableCell component="td" align="left" key={pIndex} style={{ overflow: 'hidden'}}>
                    {/* <div>{erpPosition && erpPosition.shopField}</div> */}
                    <div><span style={{color: 'gray', width: 65, display: 'inline-block'}}>shopId:</span> {erpPosition && erpPosition.shopId}</div>
                    <div><span style={{color: 'gray', width: 65, display: 'inline-block'}}>erpId:</span> {erpPosition && erpPosition.erpId}</div>
                    <div><span style={{color: 'gray', width: 65, display: 'inline-block'}}>quantity:</span> {erpPosition && erpPosition.quantity}</div>
                    <div><span style={{color: 'gray', width: 65, display: 'inline-block'}}>color:</span> {erpPosition && erpPosition.color}</div>
                    <div><span style={{color: 'gray', width: 65, display: 'inline-block'}}>length:</span> {erpPosition && erpPosition.length}</div>
                    
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}

      </TableBody>
    </Table>
  )
}