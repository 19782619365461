import moment from 'moment'

const getRowDefinition = function ({item, quantity}) {
  return [
    {
      noWrap: false,
      text: item.value
    },
    quantity.toString()
  ]
}

const generateElectronicsDefinition = function ({
  orderData,
  workOrderTypeText,
  workOrder
}) {

  const itemsObject = workOrder.worksheetRows && workOrder.worksheetRows.items
  const itemsKeys = Object.keys(itemsObject)
  const itemList = itemsKeys.map(key => itemsObject[key])
  // console.log({itemList})

  let allRowsDefinition = [
    {
      table: {
        widths: ['auto', '*'],
        body: [
          [
            { text: "Tip", style: "tableHeader" },
            { text: "Količina", style: "tableHeader" },
          ],
          ...itemList.map(getRowDefinition),
          [
            { text: "Opomba", noWrap: false },
            { text: workOrder.note, noWrap: false }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) {
          return .6;
        },
        vLineWidth: function (i, node) {
          return .6;
        },
        hLineColor: function (i, node) {
          return '#d3d3d3';
        },
        vLineColor: function (i, node) {
          return '#d3d3d3';
        }
      },
      // null
    }
  ]
  
  let docDefinition = {
    footer: function(currentPage, pageCount) {
      let footerPageText = currentPage.toString() + ' / ' + pageCount;
      return {
        columns: [
          { text: footerPageText, alignment: 'center' }
        ]
      }
    },
    content: [
      {text: `Delovni nalog - ${workOrderTypeText} (${orderData.order.productName})`, bold: true},
      '\n',
      {
        alignment: 'justify',
        columns: [
          {
            text: [{text: 'Referenčna številka: ', style: {color: 'gray'}}, `${orderData.order.referenceId || ''}`]
          },
          {
            text: [{text: 'Objekt/Lokacija: ', style: {color: 'gray'}}, `${orderData.order.location || ''}`]
          },
          {
            text: [{text: 'Stranka: ', style: {color: 'gray'}}, `${orderData.order.finalCustomer || ''}`]
          }
        ]
      },
      '\n',
      {
        alignment: 'justify',
        columns: [
          {
            text: [{text: 'Željen datum dobave: ', style: {color: 'gray'}}, `${orderData.order.deliveryDate ? moment(orderData.order.deliveryDate).format("DD.MM.YYYY") : ''}`]
          },
          {
            text: [{text: 'Opomba: ', style: {color: 'gray'}}, `${orderData.order.notice || ''}`]
          },
          {

            text: (orderData.order.documents && orderData.order.documents.length) ?
              [
                {text: 'Št. datotek: ', style: {color: 'gray'}},
                `${orderData.order.documents.length || ''}`
              ]
              :
              {text: 'Ni priloženih datotek ', style: {color: 'gray'}}

          }
        ]
      },
      '\n',
      ...allRowsDefinition
    ],
    styles: {
      tableHeader: {
        bold: true
      }
    },
    defaultStyle: {
      fontSize: 9,
      columnGap: 20
    }
  }

  return docDefinition

}

export default generateElectronicsDefinition