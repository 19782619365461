import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
// import IconButton from '@material-ui/core/IconButton';
// import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';

import Fade from '@material-ui/core/Fade';
import LoadingSpinner from '../layout/LoadingSpinner';

import Link from '@material-ui/core/Link'
import RecoverPasswordForm from './RecoverPasswordForm.js'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1)* 3,
    marginRight: theme.spacing(1)* 3,
    [theme.breakpoints.up(400 + theme.spacing(1)* 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  h1: {
    marginTop: theme.spacing(1)* 8
  },
  paper: {
    marginTop: theme.spacing(1)* 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1)* 2}px ${theme.spacing(1)* 3}px ${theme.spacing(1)* 3}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1)* 3,
  },
});

function LogIn(props) {
  const { classes, onLoginClick, isLoggingIn, credentialsError, cancelCredentialsError} = props;
  console.log({props})

  // const [rememberMe, toggleRememberMe] = React.useState(true);

  // const [languageMenuEl, setLanguageMenuEl] = React.useState(null)

  const { t } = useTranslation();

  const [forgetPasswordOpen, setForgetPasswordOpen] = useState(false)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  // const changeLanguage = React.useCallback((languageCode) => {
  //   i18n.changeLanguage(languageCode)
  //   setLanguageMenuEl(null)
  // })

  const submitForm = useCallback((e) => {
    e.preventDefault();
    onLoginClick({username, password})
  }, [username, password, onLoginClick])

  const onUsernameChange = useCallback((e) => {
    setUsername(e.target.value)
    if (credentialsError)
      cancelCredentialsError()
  }, [credentialsError, cancelCredentialsError])

  if (isLoggingIn) {
    return (
      <Fade in={true}>
        <LoadingSpinner style={{position: 'absolute'}}/>
      </Fade>
    )
  }
  
  return (
    
    <React.Fragment>

      <Fade in={true}>
        <Typography className={classes.h1} component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
          {t('Spletna Trgovina Sonal')}
        </Typography>
      </Fade>

        <Fade in={true}>
          <main className={classes.main}>
            <Paper className={classes.paper} style={{position: 'relative'}}>

              {forgetPasswordOpen ? (
                <Fade in={true}>
                  <RecoverPasswordForm onBackClick={() => setForgetPasswordOpen(false)}/>
                </Fade>
              ) : (
                <>
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    {t('Prijava')}
                  </Typography>
                  <form className={classes.form} onSubmit={submitForm}>
                    <FormControl margin="normal" required fullWidth error={credentialsError}>
                      <InputLabel htmlFor="username">{t('Uporabniško ime')}</InputLabel>
                      <Input id="username" name="username" autoComplete="username" autoFocus value={username} onChange={onUsernameChange}/>
                      { credentialsError &&
                        <FormHelperText id="component-helper-text">{t('Napačno uporabniško ime ali geslo')}</FormHelperText>
                      }
                    </FormControl>
                    <FormControl margin="normal" required fullWidth error={credentialsError}>
                      <InputLabel htmlFor="password">{t('Geslo')}</InputLabel>
                      <Input name="password" type="password" id="password" autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)}/>
                      { credentialsError &&
                        <FormHelperText id="component-helper-text">{t('Napačno uporabniško ime ali geslo')}</FormHelperText>
                      }
                    </FormControl>

                        <Link href="#" variant="body2" style={{
                          lineHeight: '2em',
                          float: 'right',
                          color: 'gray'
                        }}
                        onClick={e => {
                          e.preventDefault()
                          setForgetPasswordOpen(true)
                        }}
                        >
                          Ste pozabili geslo?
                        </Link>
                    
                    {/* <FormControlLabel
                      control={<Checkbox checked={rememberMe} value="remember" color="primary" onChange={() => toggleRememberMe(!rememberMe)}/>}
                      label={t('Zapomni si uporabniško ime')}
                    /> */}

                    {/* <IconButton
                      style={{position: 'absolute', right: 0, top: 0}}
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={(e) => setLanguageMenuEl(e.currentTarget)}
                    >
                      <LanguageIcon />
                    </IconButton>

                    <Menu
                      anchorEl={languageMenuEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(languageMenuEl)}
                      onClose={() => setLanguageMenuEl(null)}
                    >
                      <MenuItem onClick={() => changeLanguage('si')}>Slovensko</MenuItem>
                      <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                    </Menu> */}


                    <Button
                      // type="button"
                      id="login-button"
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      // onClick={onLoginClick}
                      disabled={username === '' || password === ''}
                    >
                      {t('Prijava')}
                    </Button>
                    
                  </form>
                </>
              )}
            </Paper>
          </main>
        </Fade>

    </React.Fragment>
  );
}

LogIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LogIn);

/*


* vsa Naročila (po datumu)
  - kategorizacija po stanju naročil (zavrnjena, v teku, drafts...)

  * Naročilo (vse postavke po vrstnem redu)
    - kategorije artiklov (žaluzija, senčilo, tenda, markiza)


*/
