import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Spletna Trgovina Sonal": "Sonal Web Shop",
      "Prijava": "Login",
      "Email": "Email",
      "Uporabniško ime": "Username",
      "Geslo": "Password",
      // "Zapomni si uporabniško ime": "Remember username",
      "Naročila": "Orders",
      "Napačno uporabniško ime ali geslo": "Wrong credentials"
    }
  },
  si: {
    translation: {
      "Spletna Trgovina Sonal": "Spletna Trgovina Sonal",
      // "Prijava": "Prijava",
      // "Email": "Email",
      // "Geslo": "Geslo",
      // "Zapomni si uporabniško ime": "Zapomni si uporabniško ime"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "si",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;