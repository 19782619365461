import userApi from '../api/user'

function saveAuthToken (authToken) {
  localStorage.setItem('auth_token', authToken)
}

export function login (credentials) {
  return userApi.authenticate(credentials)
    .then(saveAuthToken)
    .then(getMe)
}

export function getMe() {

  let authToken = localStorage.getItem('auth_token')

  // reject if no token in local storage
  if (!authToken)
    return Promise.reject();

  return userApi.getAccount(authToken)
}

export function logout () {
  return new Promise((resolve) => {
    localStorage.removeItem('auth_token')
    resolve()
  })
}


export function requestPasswordReset (email) {
  return userApi.requestPasswordReset(email)
}

export function resetPassword (newPassword, token) {
  return userApi.resetPassword(newPassword, token)
}

export default {
  // login: fakeLogin,
  // logout: fakeLogout,
  // getMe: fakeGetMe
  login,
  logout,
  getMe,

  requestPasswordReset,
  resetPassword
}