import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const BottomLine = () => (
  <div style={{
    position: 'absolute',
    borderBottom: '1px solid lightgray',
    width: '100%',
    bottom: 0
  }}></div>
)

const renderStickyTableHead = ({text, id}) => (
  <TableCell
    style={{
      position: "sticky",
      top: 0,
      padding: 0,
      backgroundColor: 'white',
      zIndex: 3,
      whiteSpace: 'nowrap'
    }}
    key={id}
    // align="right"
  >
    <div style={{padding: 8}}>{text}</div>
    <BottomLine />
  </TableCell>
)

const TableHeader = ({fields, id}) => {
  // console.log({fields})
  return (
    <TableHead id={id}>
      <TableRow>
        {renderStickyTableHead({text: '', id: 'empty'})}
        {fields && fields.map(renderStickyTableHead)}

        {/* <TableCell style={{padding: '0', position: 'sticky', top: 0, backgroundColor: '#f5f5f5', zIndex: 3}}></TableCell>
        {fields && fields.map((field, ind) => (
          <TableCell key={ind} style={{padding: '8px', whiteSpace: 'nowrap', position: 'sticky', top: 0, backgroundColor: '#f5f5f5', zIndex: 3}} >{field.text}</TableCell>
        ))} */}
        {/* <TableCell style={{visibility: 'hidden', padding: '8px 10px'}}>Možnosti</TableCell> */}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader