import React, {useState} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export function useEditPopupState(){
  const [editPopupData, setEditPopupData] = useState({open: false, text: null, onConfirm: null, numberField: false})
  
  return {
    isOpen: editPopupData.open,
    text: editPopupData.text,
    open: ({text, onConfirm, numberField = false}) => {
      setEditPopupData({
        open: true,
        text,
        onConfirm,
        numberField
      })
    },
    close: () => {
      setEditPopupData(s => ({...s, open: false}))
    },
    updateText: (text) => {
      setEditPopupData(s => ({...s, text}))
    },
    reset: () => {
      setEditPopupData({
        open: false,
        text: null,
        onConfirm: null,
      })
    },
    onConfirm: editPopupData.onConfirm,
    numberField: editPopupData.numberField
  }
}

export default ({
  isOpen,
  text,
  open,
  close,
  updateText,
  reset,
  onConfirm,

  numberField = false
}) => {

  return (
    <Dialog 
        onClose={reset}
        disableEscapeKeyDown={true}
        onEscapeKeyDown={close}
        onBackdropClick={close}
        open={isOpen}
        fullWidth
        maxWidth="sm"
      >
        {/* <DialogTitle >Opomba</DialogTitle> */}
        <DialogContent>
          <TextField
            autoFocus
            label="Uredi celico"
            multiline={numberField ? false : true}
            type={numberField ? 'number' : 'text'}
            fullWidth
            rowsMax="10"
            rows="1"
            value={text || ''}
            onChange={(e) => {
              let text = e.target.value
              updateText(text)
            }}
            margin="none"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>
            Prekliči
          </Button>
          <Button
            onClick={(e) => {
              if (onConfirm)
                onConfirm(text)

              close()
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
  )
}