import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import OrderEditor from './OrderEditor'
import OrderView from './OrderView'
import OrderPreview from './OrderPreview'
import WorkOrders from './WorkOrders'
import PantheonPreview from './PantheonPreview'
import PantheonPreviewByWorkOrder from './PantheonPreviewByWorkOrder'
import PrintStickers from './PrintStickers'
// import WorkOrdersOriginal from './WorkOrdersOriginal'
// import ordersService from '../../services/order'

const OrdersRouter = ({auth, match, location}) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/:orderId`}
        render={() => (
          <OrderView auth={auth} />
        )} 
      />
      <Route exact path={[
          `${match.path}/new/:productId`,
          `${match.path}/:orderId/edit`
        ]}
        render={({match}) => {
          let isNew = match.path === '/orders/new/:productId'
          return (
            <OrderEditor exact auth={auth} match={match} isNew={isNew}/>
          )
        }}
      />
      <Route exact path={`${match.path}/:orderId/preview`}
        render={() => (
          <OrderPreview auth={auth} />
        )} 
      />

      <Route
        exact
        path={[
          `${match.path}/:orderId/work-orders`,
          `${match.path}/:orderId/work-orders/:version`
        ]}
        render={({match}) => {
          return (
            <WorkOrders auth={auth} showOriginal={match.params.version === 'original'} version={match.params.version}/>
          )
        }} 
      />

      <Route exact path={`${match.path}/:orderId/pantheon-preview`}
        render={() => (
          <PantheonPreview auth={auth} />
        )} 
      />
      <Route exact path={`${match.path}/:orderId/pantheon-preview-by-work-order`}
        render={() => (
          <PantheonPreviewByWorkOrder auth={auth} />
        )} 
      />
      <Route exact path={`${match.path}/:orderId/stickers`}
        render={() => (
          <PrintStickers auth={auth} />
        )} 
      />

      <Redirect to={'/'} />
    </Switch>
  )
}

export default withRouter(OrdersRouter);