import React, {useState} from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';

import ordersService from '../../../services/order'

const FileBrowsingContent = ({
  order,
  orderHasDocuments,
  reloadOrder,
  readOnly = false,
  uploadingFile,
  setUploadingFile,
  setShowError,
  onClose
}) => {

  return (
    <>
      <DialogTitle >Datoteke</DialogTitle>
      <DialogContent>
        <List dense disablePadding>
          {orderHasDocuments && order.documents.map(fileName => (
            <ListItem disableGutters button key={fileName} onClick={() => {
              ordersService.downloadFile(order.id, fileName)
            }}>
              <ListItemIcon>
                <FileIcon color="primary"/>
              </ListItemIcon>
              <ListItemText style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                primary={fileName}
              />
              {!readOnly && (
                <ListItemSecondaryAction>              
                  <IconButton edge="end" aria-label="delete" onClick={() => {
                    ordersService.deleteFile(order.id, fileName)
                      .then(reloadOrder)
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}

          {uploadingFile && (
            <ListItem disableGutters key={'uploading'}>
              <ListItemIcon>
                <CircularProgress size={24} />
              </ListItemIcon>
              <ListItemText style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                primary={`${uploadingFile.name} - Nalaganje...`}
              />
              <ListItemSecondaryAction />
            </ListItem>
          )}
        </List>
        {!readOnly && (<>
          {orderHasDocuments && <Divider style={{margin: '.3em 0'}}/>}
          <List dense disablePadding>
            <label htmlFor="contained-button-file">
              <ListItem disableGutters button key={"fileName"} component="span" onClick={() => {
                // ordersService.downloadFile(order.id, fileName)
              }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText
                  primary={'Dodaj'}
                />
              </ListItem>
            </label>
          </List>
        </>)}

        <input style={{display: 'none'}}
          onChange={(e) => {
            e.preventDefault()
            if (e.target.files[0]){
              setUploadingFile(e.target.files[0])

              const data = new FormData() 
              data.append('file', e.target.files[0])

              ordersService.uploadFile(order.id, data)
                .then(ddd => {
                  if (reloadOrder)
                    reloadOrder().then(() => setUploadingFile(null))
                })
                .catch(err => {
                  console.log(err)
                  setUploadingFile(null)
                  setShowError(true)
                  // if (err && err.response && err.response.status === 400){
                  //   setUploadingFile(null)
                  //   setShowError(true)
                  // }
                })
              
            }
              
          }}
          id="contained-button-file"
          type="file"
        />

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Ok
          </Button>
        </DialogActions>
    </>
  )
}

const ErrorContent = ({onClose}) => {
  return (<>
    <DialogTitle>Datoteke - <span style={{color: 'red'}} >napaka</span> <ErrorIcon style={{verticalAlign: 'textBottom'}}/> </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Največja velikost datoteke je presežena. Največja velikost je XX MB.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onClose}>
        Ok
      </Button>
    </DialogActions>
  </>)
}

const FileBrowserDialog = ({
  onClose,
  open,
  order,
  orderHasDocuments,
  reloadOrder,
  readOnly = false
}) => {

  const [uploadingFile, setUploadingFile] = useState(null);
  const [showError, setShowError] = useState(false);

  return (
    <Dialog onClose={showError ? () => setShowError(false) : onClose} 
      aria-labelledby="edit-notice-dialog"
      open={open}
      fullWidth
      maxWidth="xs"
    >

      {showError ? (
        <ErrorContent
          onClose={() => setShowError(false)}
        />
      ):(
        <FileBrowsingContent
          order={order}
          orderHasDocuments={orderHasDocuments}
          reloadOrder={reloadOrder}
          readOnly={readOnly}
          uploadingFile={uploadingFile}
          setUploadingFile={setUploadingFile}
          showError={showError}
          setShowError={setShowError}
          onClose={onClose}
        />
      )}

      
    </Dialog>
  )
}

export default FileBrowserDialog