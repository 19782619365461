import React from 'react'
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

const BottomBar = ({addRows, submitOrder, addRowsDisabled, submitDisabled}) => {

  return (
    <Toolbar style={{height: 64, borderTop: '1px solid rgba(224, 224, 224, 1)'}}>

      <Button disabled={addRowsDisabled}
        style={{marginRight: '1em'}}
        // variant='outlined'
        onClick={() => addRows({quantity: 3})}
        startIcon={<AddIcon />}
      >
        Dodaj vrstice
      </Button>
      <div style={{flex: '1'}}></div>

      <Button disabled={submitDisabled}
        // size="small"
        variant="contained"
        color="primary"
        onClick={submitOrder}
        // startIcon={<CheckIcon />}
        endIcon={<SendIcon />}
      >
        Oddaj naročilo  
      </Button>

    </Toolbar>
  )
}

export default BottomBar