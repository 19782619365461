import React, {useState, useEffect, useCallback} from 'react'
// import MainLayout from '../layout/Main'
import SimpleLayout from '../../layout/SingleAppBarLayout'
import { withStyles } from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';

// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';


// import Typography from '@material-ui/core/Typography';
import StickersAccordion from './StickersAccordion'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import GeneralInfo from './GeneralInfo'

import ordersService from '../../../services/order'

import {useProductConfiguration} from '../../../store/formConfig/FormConfigContext'

const PrintStickers = ({classes, history, match}) => {

  const [orderData, setOrderData] = useState(null);
  const [stickersData, setStickersData] = useState(null);
  const [selectedStickers, setSelectedStickers] = useState({});
  
  const {orderId} = match.params

  const loadOrder = useCallback(() => {
    ordersService.getById(orderId)
      .then(setOrderData)
      .catch(e => {
        console.log("e", e)
        let redirectPath = process.env.REACT_APP_BASENAME || '/'
        history.push(redirectPath)
      })
    },
    [orderId, history]
  )

  const loadStickersData = useCallback(() => {
    ordersService.getStickersData(orderId)
      .then(setStickersData)
    },
    [orderId]
  )

  useEffect(() => {
    loadOrder()
  }, [loadOrder]);
  
  useEffect(() => {
    loadStickersData()
  }, [loadStickersData]);

  let productConfig = useProductConfiguration(orderData && orderData.order && orderData && orderData.order.productId)

  if (!orderId || !orderData || !productConfig)
    return null

  let {order} = orderData


  return (
    <Paper>
      <GeneralInfo
        hasChanged={false}
        order={order}
        product={productConfig.product}
        history={history}
        onConfirmOrderClick={() => {}}
        printEnabled={true}
        showOriginal={false}
        onShowOriginalClick={() => {}}
        onPrint={() => {
          let stickersIds = stickersData && stickersData.originalData && selectedStickers && stickersData.originalData
            .filter(s => selectedStickers[s.id])
            .map(s => s.id)
          // console.log({stickersIds})
          if (stickersIds && stickersIds.length > 0)
            ordersService.printStickers(orderId, stickersIds)
              .then(() => {
                alert('Printanje...')
              })
              .catch(e => {
                alert('Zgodila se je napaka.')
              })
        }}
      />

      <Divider variant="fullWidth" style={{height: '4px'}}/>

      <div style={{
        overflow: "auto",
        maxHeight: 'calc(100vh - 292px - 60px)'
      }}>
        <StickersAccordion
          stickersData={stickersData}
          selectedStickers={selectedStickers}
          setSelectedStickers={setSelectedStickers}
        />
      </div>
    </Paper>   
  )
}

const PrintStickersPage = ({auth, match, ...rest}) => {
  const {orderId} = match.params
  return (

    <SimpleLayout 
      headerText={`Naročilo ${orderId} - Printanje nalepk`}
      component={PrintStickers}
      auth={auth}
      {...rest}
    />
  )
}

export default withRouter(PrintStickersPage);