export const normalize = (items, options = {}) => {

  let {idAttribute = 'id', byIdAttribute = 'byId', allIdsAttribute = 'allIds'} = options

  return items.reduce((normalizedObject, item) => ({
    [byIdAttribute]: {...normalizedObject[byIdAttribute], [item[idAttribute]]: item},
    [allIdsAttribute]: [...normalizedObject[allIdsAttribute], item[idAttribute]]
  }),
    {
      [byIdAttribute]: {},
      [allIdsAttribute]: []
    }
  )
}

export const createNormalization = options => items => normalize(items, options)

export default {
  normalize,
  createNormalization
}