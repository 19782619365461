import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames'
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import EditIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';

import Popper from '@material-ui/core/Popper';
// import Collapse from '@material-ui/core/Collapse';

// import FormHelperText from '@material-ui/core/FormHelperText';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';

import TableRow from '@material-ui/core/TableRow';

// import Button from '@material-ui/core/Button';
// import RefreshIcon from '@material-ui/icons/Refresh';
// import SearchIcon from '@material-ui/icons/Search';
// import FormControl from '@material-ui/core/FormControl';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import TextField from '@material-ui/core/TextField';
// import FormLabel from '@material-ui/core/FormLabel';
// import InputAdornment from '@material-ui/core/InputAdornment';

import {useLocation} from "react-router-dom";

import Fade from '@material-ui/core/Fade';
import LoadingSpinner from '../layout/LoadingSpinner'

import moment from 'moment'
import queryString from 'query-string'
// import orderService from '../../services/order'

import {
  useOrdersSelectors,
  useOrdersState,
  useReload as useReloadOrders,
  // useGetAll,
  // useGetDrafts,G
  // useGetConfirmed
} from '../../store/orders/OrdersContext'
// import {useOrdersDispatch} from '../../store/orders/OrdersContext'

// import {useProducts} from '../../store/formConfig/FormConfigContext'

const renderStatusCell = (status, isOnlyUser, ind) => {
  let icon = null
  let color = 'black'
  let statusText = ''

  if (status === 'DRAFT'){
    color = 'gray'
    icon = <EditIcon style={{verticalAlign: 'middle', margin: '-5px 10px'}}/>
    statusText = 'Osnutek'
  }
  if (status === 'PENDING'){
    color = 'royalblue'
    icon = <DoneIcon style={{verticalAlign: 'middle', margin: '-5px 10px'}}/>
    statusText = 'Oddano'
  }
  if (status === 'REJECTED'){
    color = 'red'
    icon = <ErrorIcon style={{verticalAlign: 'middle', margin: '-5px 10px'}}/>
    statusText = 'Nepopolno'
  }
  if (status === 'INCOMPLETE'){
    color = 'red'
    icon = <ErrorIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
    statusText = 'Nepopolno'
  }
  if (status === 'CONFIRMED'){
    color = 'green'
    icon = <DoneAllIcon style={{verticalAlign: 'middle', margin: '-5px 10px'}}/>
    statusText = 'Potrjeno'
  }


  if (status === 'TECH_CHECKED'){
    color = 'green'
    icon = <DoneAllIcon style={{visibility: 'hidden', verticalAlign: 'middle', margin: '-5px 10px'}}/>
    statusText = isOnlyUser ? 'Potrjeno' : 'Tehnično potrjeno'
    if (isOnlyUser){
      icon = <DoneAllIcon style={{verticalAlign: 'middle', margin: '-5px 10px'}}/>
      statusText = 'Potrjeno'
    }
  }
  if (status === 'IN_PROGRESS'){
    color = 'green'
    icon = <DoneAllIcon style={{visibility: 'hidden', verticalAlign: 'middle', margin: '-5px 10px'}}/>
    statusText = isOnlyUser ? 'Potrjeno' : 'V izdelavi'
    if (isOnlyUser){
      icon = <DoneAllIcon style={{verticalAlign: 'middle', margin: '-5px 10px'}}/>
      statusText = 'Potrjeno'
    }
  }
  if (status === 'FINISHED'){
    color = 'green'
    icon = <DoneAllIcon style={{visibility: 'hidden', verticalAlign: 'middle', margin: '-5px 10px'}}/>
    statusText = isOnlyUser ? 'Potrjeno' : 'Končano'
    if (isOnlyUser){
      icon = <DoneAllIcon style={{verticalAlign: 'middle', margin: '-5px 10px'}}/>
      statusText = 'Potrjeno'
    }
  }
  if (status === 'DELIVERED'){
    color = 'green'
    icon = <DoneAllIcon style={{visibility: 'hidden', verticalAlign: 'middle', margin: '-5px 10px'}}/>
    statusText = isOnlyUser ? 'Potrjeno' : 'Dobavljeno'
    if (isOnlyUser){
      icon = <DoneAllIcon style={{verticalAlign: 'middle', margin: '-5px 10px'}}/>
      statusText = 'Potrjeno'
    }
  }
  if (status === 'REJECTED'){
    color = 'red'
    icon = <ErrorIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
    statusText = 'Zavrnjeno'
  }

  return (
    <TableCell id={`status`} align="right" style={{...singleRowCell, color}}>{statusText}{icon}</TableCell>
  )
}

const renderNoticeCell = (notice, ind, setNoticePopoverData) => {
  let icon = <InfoIcon color="primary" style={{verticalAlign: 'middle', margin: '-5px 10px'}}/>
  return (
    // <TableCell align="right" style={{...singleRowCell, color}}>{statusText}{icon}</TableCell>
    <TableCell
      id={`notice`}
      align="center"
      onMouseEnter={(e) => {
        setNoticePopoverData({notice, anchor: e.currentTarget})
      }}
      onMouseLeave={() => {
        setNoticePopoverData(null)
      }}
    >
      {notice ? icon : null}
    </TableCell>
  )
}

let singleRowCell = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: 230
}

const OrdersList = ({orders, classes, history, page, filtersOpen, auth, ordersListRef, isLoading}) => {
  // console.log({auth})
  let {isUser, isOperator, isManager, isAdmin} = auth

  let isOnlyUser = isUser && !isOperator && !isManager && !isAdmin
  
  
  // const products = useProducts()
  // console.log({products})

  // if (!products)

  let ordersHeight = `calc(100% - 64px${filtersOpen ? ' - 384px' : ''})`

  const [noticePopoverData, setNoticePopoverData] = React.useState(null);

  return (
    <div ref={ordersListRef} style={{overflowX: 'auto', position: 'relative', height: ordersHeight, opacity: isLoading ? .4 : 1}}>
      <Table id="orders-list-table" className={classes.table}>
        <TableHead id="orders-list-thead">

          {isOnlyUser && (
            <TableRow >
              <TableCell className={classes.tableHeaderCell}><div className={classes.tableHeaderCellInner}>&nbsp;</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="left"><div className={classes.tableHeaderCellInner}>Ref. št.</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="left"><div className={classes.tableHeaderCellInner}>Datum oddaje</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="left"><div className={classes.tableHeaderCellInner}>Datum dobave</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="left"><div className={classes.tableHeaderCellInner}>Stranka</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="left"><div className={classes.tableHeaderCellInner}>Objekt</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="right"><div className={classes.tableHeaderCellInner}>Produkt</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="right"><div className={classes.tableHeaderCellInner}>Uporabnik</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="right"><div className={classes.tableHeaderCellInner}>Priloge</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="center"><div className={classes.tableHeaderCellInner}>Opomba</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="right" ><div style={{paddingRight: '60px'}} className={classes.tableHeaderCellInner}>Status</div></TableCell>
            </TableRow>
          )}

          {(isOperator || isManager || isAdmin) && (
            <TableRow >
              <TableCell className={classes.tableHeaderCell}><div className={classes.tableHeaderCellInner}>&nbsp;</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="left"><div className={classes.tableHeaderCellInner}>Št. del. naloga</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="left"><div className={classes.tableHeaderCellInner}>Datum prejema</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="left"><div className={classes.tableHeaderCellInner}>Datum dobave</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="left"><div className={classes.tableHeaderCellInner}>Naročnik</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="left"><div className={classes.tableHeaderCellInner}>Ref. št. (naročnika)</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="right"><div className={classes.tableHeaderCellInner}>Produkt</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="right"><div className={classes.tableHeaderCellInner}>Priloge</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="center"><div className={classes.tableHeaderCellInner}>Opomba</div></TableCell>
              <TableCell className={classes.tableHeaderCell} align="right" ><div style={{paddingRight: '60px'}} className={classes.tableHeaderCellInner}>Status</div></TableCell>
            </TableRow>
          )}

        </TableHead>
        <TableBody id="orders-list-tbody">
          {orders && orders.map((order, ind) => {
            // console.log({"order.productId": order.productId})
            // console.log({"products.byId": products.byId})
            // let productName = order && products && products.byId[order.productId].name || ''
            // console.log('productName', productName)

            let numOfDocuments = (order.documents && order.documents.length > 0) ? 
              order.documents.length : 0

            if (isOnlyUser) {
              return (
                <TableRow hover key={ind} style={{cursor: 'pointer'}} onClick={() => history.push('/orders/' + order.id)} >
                  <TableCell scope="row" style={{paddingRight: "24px"}}>{ind+1}</TableCell>
                  <TableCell id={`referenceId`} align="left" style={singleRowCell} >{order.referenceId}</TableCell>
                  <TableCell id={`orderDate`} align="left">{order.orderDate && moment(order.orderDate).format('DD.MM.YYYY')}</TableCell>
                  <TableCell id={`deliveryDate`} align="left">{order.deliveryDate && moment(order.deliveryDate).format('DD.MM.YYYY')}</TableCell>
                  <TableCell id={`finalCustomer`} align="left" style={singleRowCell} >{order.finalCustomer}</TableCell>
                  <TableCell id={`location`} align="left"  style={singleRowCell} >{order.location}</TableCell>
                  <TableCell id={`productName`} align="right" style={singleRowCell}>{order.productName}</TableCell>
                  <TableCell id={`userName`} align="right" style={singleRowCell}>{order.userName}</TableCell>
                  <TableCell id={`numOfDocuments`} align="right" style={{paddingTop: 0, paddingBottom: 0}} style={singleRowCell} >
                    {(numOfDocuments > 0) && <>
                      {numOfDocuments} x 
                      <FileIcon fontSize="small" style={{verticalAlign: 'middle'}} color="primary"/>
                    </>}
                  </TableCell>
                  {renderNoticeCell(order.notice, ind, setNoticePopoverData)}
                  {renderStatusCell(order.status, isOnlyUser, ind)}
                </TableRow >
              )
            }

            if (isOperator || isManager || isAdmin){
              return (
                <TableRow hover key={ind} style={{cursor: 'pointer'}} onClick={() => history.push('/orders/' + order.id)} >
                  <TableCell scope="row" style={{paddingRight: "24px"}}>{ind+1 + ((page-1)*30)}</TableCell>
                  <TableCell id={`orderNumber`} align="left">{order.orderNumber}</TableCell>
                  <TableCell id={`orderDate`}  align="left">{order.orderDate && moment(order.orderDate).format('DD.MM.YYYY')}</TableCell>
                  <TableCell id={`deliveryDate`} align="left">{order.deliveryDate && moment(order.deliveryDate).format('DD.MM.YYYY')}</TableCell>
                  <TableCell id={`userCompany`} align="left" style={singleRowCell}>{order.userCompany}</TableCell>
                  <TableCell id={`referenceId`} align="left">{order.referenceId}</TableCell>
                  <TableCell id={`productName`} align="right" style={singleRowCell}>{order.productName}</TableCell>
                  <TableCell id={`numOfDocuments`} align="right" style={{paddingTop: 0, paddingBottom: 0}} style={singleRowCell} >
                    {(numOfDocuments > 0) && <>
                      {numOfDocuments} x 
                      <FileIcon fontSize="small" style={{verticalAlign: 'middle'}} color="primary"/>
                    </>}
                  </TableCell>
                  {renderNoticeCell(order.notice, ind, setNoticePopoverData)}
                  {renderStatusCell(order.status, isOnlyUser, ind)}
                </TableRow >
              )
            }

            return null
          })}
        </TableBody>
      </Table>

      <Popper
        id="mouse-over-popover"
        // className={classes.popover}
        // classes={{
        //   paper: classes.paper,
        // }}
        open={Boolean(noticePopoverData && noticePopoverData.anchor && noticePopoverData.notice && noticePopoverData.notice !== "")}
        anchorEl={noticePopoverData && noticePopoverData.anchor}
        onClose={() => {
          console.log('on close')
          setNoticePopoverData(null)
        }}
      >
        <Paper style={{padding: 16}}>
          <Typography component="pre">{noticePopoverData && noticePopoverData.notice}</Typography>
        </Paper>
      </Popper> 
    </div>
  )
}

const styles = theme => {
  // console.log(theme)
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 2,
      color: theme.palette.common.white,
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(1)* 3,
      // marginTop: theme.mixins.toolbar.minHeight
    },

    filterToolbar: {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      zIndex: theme.zIndex.drawer + 1,
      // color: theme.palette.secondar,
    },
    paginationText: {
      // marginLeft: 'auto'
    },
    paginationButtonsContainer: {
      marginLeft: 12
    },
    // filters:{},
    filtersButton: {
      marginLeft: 12
    },
    filtersButtonOpen: {
      // top: '32px',
      // transform: 'translateY(32px)',
    },
    filter: {
      padding: "1em",
      // backgroundColor: 'white',
      '&:not(:first-child)': {
        marginLeft: theme.spacing(1),
        // borderLeft: '1px solid',
        // borderColor: theme.palette.grey['400']
      },
    },
    listToolBar: {
      justifyContent: "flex-end"
    },
    mainContent: {
      padding: theme.spacing(1)* 3,
    },

    toolbar: theme.mixins.toolbar,
    paper: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(1)* 2,
      paddingBottom: theme.spacing(1)* 2,
    },
    fab: {
      left: 48,
      zIndex: 5,
      margin: theme.spacing(1),
      transform: 'translateY(-50%)',
      marginTop: 0,
      marginBottom: 0,
      alignSelf: 'baseline',
      position: 'fixed'
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    titleSeparator: {
      width: "1px",
      height: "48px",
      // backgroundColor: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.primary.customLight,
      marginLeft: theme.spacing(1)* 3,
      marginRight: theme.spacing(1)* 3
    },

    // tableRowHower: {
    //   '&:hover': {
    //     backgroundColor: "red",
    //   },
    // },
    // table: {
    //   height: 'calc(100% - 64px)'
    // }
    tableHeaderCell: {
      position: 'sticky',
      top: 0,
      // borderBottom: '1px solid darkgray',
      backgroundColor: 'white',
      padding: 0
    },
    tableHeaderCellInner: {
      borderBottom: '1px solid darkgray',
      whiteSpace: 'nowrap',
      padding: 16
    }
  }
};

// const Filter = ({classes, children, ...rest}) => (
//   <div className={classes}
//     elevation={0}
//     {...rest}
//   >
//     {children}
//   </div>
// )

// const Filters = ({classes, rest}) => (
//   <div className={classes.filters} style={{
//     backgroundColor: 'rgb(239, 239, 239)',
//     // border: '1px solid gray'
//   }}>
//     <Toolbar style={{
//       paddingTop: '12px',
//       alignItems: 'stretch'
//     }}>

//       <Filter classes={classes.filter}>
//         <FormControl component="fieldset" >
//           <FormLabel component="legend">Status</FormLabel>
//           <FormGroup style={{
//             marginTop: '12px'
//           }}>
//             {["Osnutek", "Oddano", "Prevzeto", "Potrjeno", "Nepopolno", "Zbrisano"].map(label => (
//               <FormControlLabel
//                 control={
//                   <Checkbox color="primary"/>


//                 }
//                 label={label}
//                 key={label}
//               />
//             ))}
//           </FormGroup>
//         </FormControl>
//       </Filter>

//       <div style={{backgroundColor: '#b1b1b1', width: '1px', margin: '8px'}}></div>
      
//       <Filter classes={classes.filter}>
//         <FormControl component="fieldset" >
//           <FormLabel component="legend">Datum</FormLabel>
//           <FormGroup style={{
//             // marginTop: '12px'
//           }}>
//             <TextField 
//               id="date"
//               label="Od"
//               type="date"
//               // defaultValue={new Date().toISOString().substring(0, 10)}
//               className={classes.textField}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               margin="normal"
//             />
//             <TextField
//               id="date"
//               label="Do"
//               type="date"
//               // defaultValue="2017-05-24"
//               className={classes.textField}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               margin="normal"
//             />
//           </FormGroup>
//         </FormControl>
//       </Filter>

//       <div style={{backgroundColor: '#b1b1b1', width: '1px', margin: '8px'}}></div>      

//       <Filter classes={classes.filter}>
//         <FormControl component="fieldset" >
//           <FormLabel component="legend">Iskanje</FormLabel>
//           <FormGroup style={{
//             // marginTop: '12px'
//           }}>
//             <TextField
//               // id="standard-search"
//               placeholder="Opis"
//               label="Po opisu"
//               type="search"
//               className={classes.textField}
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <SearchIcon />
//                   </InputAdornment>
//                 ),
//               }}
//               margin="normal"
//             />
//             <TextField
//               // id="standard-search"
//               label="Po šifri"
//               placeholder="Šifra"
//               type="search"
//               className={classes.textField}
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <SearchIcon />
//                   </InputAdornment>
//                 ),
//               }}
//               margin="normal"
//             />
//           </FormGroup>
//         </FormControl>
//       </Filter>
      
//     </Toolbar>
//     <Toolbar >
//       <Button 
//         variant="contained" 
//       >
//         <RefreshIcon />
//         Posodobi
//       </Button>
//     </Toolbar>
//   </div>
// )

const Pagination = ({page, totalOrdersCount, history, classes}) => {
  
  const location = useLocation();

  let isVisible = Pagination && totalOrdersCount && history && classes
  
  let paginationNumbers = {
    from: ((page-1) * 30) + 1,
    to: Math.min(page*30, totalOrdersCount),
    total: Number(totalOrdersCount)
  }
  // console.log({paginationNumbers})

  return (<>
      <Typography variant="subtitle1" className={classes.paginationText}>
        {isVisible && (`
          ${paginationNumbers.from}-${paginationNumbers.to} od ${paginationNumbers.total} 
        `)}
      </Typography>
    
      <div className={classNames(classes.paginationButtonsContainer)}>
        <IconButton id="previous-page-button" disabled={page === 1 || !isVisible} onClick={() => {
          let currentQueryParams = queryString.parse(location.search)
          if (page === 2){
            let {page: removedPage, ...withoutPage} = currentQueryParams
            console.log({withoutPage, currentQueryParams})
            history.push(`/?${queryString.stringify(withoutPage)}`)
          }
          else {
             history.push(`/?${queryString.stringify({...currentQueryParams, page: page - 1})}`)
          }
        }}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton id="next-page-button" disabled={paginationNumbers.to === paginationNumbers.total || !isVisible} onClick={() => {
          let currentQueryParams = queryString.parse(location.search)
          history.push(`/?${queryString.stringify({...currentQueryParams, page: page + 1})}`)
        }}>
          <ChevronRightIcon />
        </IconButton>
      </div>
    
  </>)
}

function Orders({classes, component, history, auth}) {

  const location = useLocation();
  
  let queryParams = queryString.parse(location.search)
  let page = 1
  if (queryParams.page && typeof Number(queryParams.page) === 'number')
    page = Number(queryParams.page)
  if (!page || isNaN(page))
    page = 1

  let showDrafts = (queryParams.withDrafts === 'true') ? true : false

  // const [filtersOpen, setFiltersOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  // const [orders, setOrders] = useState(null);

  // useEffect(() => {
    // orderService.getList()
    //   .then(orders => {
    //     // console.log('OFS', orders)
    //     setOrders(orders)
    //     // setIsLoading(false)
    //   })

  // }, []);

  const reloadOrders = useReloadOrders()

  let {updatedAt, lastChangeAt, isLoading} = useOrdersState()

  useEffect(() => {
    if (lastChangeAt > updatedAt)
      reloadOrders(page, showDrafts)
  }, [updatedAt, lastChangeAt, reloadOrders, page, showDrafts]);


  useEffect(() => {
    // console.log(page)
    reloadOrders(page, showDrafts)
      .then(() => {
        // console.log(ordersListRef)
        ordersListRef && ordersListRef.current && ordersListRef.current && ordersListRef.current.scrollTo(0, 0)
      })
  }, [page, reloadOrders, showDrafts]);

  const ordersListRef = useRef(null);

  // console.log(reloadOrders)


  

  // console.log({updatedAt, lastChangeAt})
  
  // let all = useGetAll()
  // let drafts = useGetDrafts()
  // let confirmed = useGetConfirmed()
  // console.log('rendering... ', {all, drafts, confirmed})

  // let {getAll, getDrafts, getConfirmed} = useOrdersSelectors()
  let all = useOrdersSelectors().getAll()
  let totalOrdersCount = useOrdersSelectors().getTotalCount()
  // console.log({totalOrdersCount})
  // let drafts = getDrafts()
  // let confirmed = getConfirmed()
  // console.log('rendering... ', {all, drafts, confirmed})

  // console.log({ordersFromContext, getOrders: getOrders(), getOrdersLength: getOrdersLength()})

  // let ordersFromSelector = getOrders()
  // let lengthFromSelector = getOrdersLength()
  // console.log('Rendering in orders.', {
  //   ordersFromSelector, lengthFromSelector
  // })

  // useDebugValue('demooo');

  let {isUser, isOperator, isManager, isAdmin} = auth

  // console.log(page)

  return (
    <Fade in={true}>
      <Paper style={{width: '100%', marginTop: "24px", height: 'calc(100% - 24px)', overflow: 'hidden'}} >
        <Toolbar position="static" className={classes.listToolBar}>
          <Fab id={"new-order-button"} color="primary" variant="extended" aria-label="New order" className={classes.fab} onClick={() => history.push('/products')} >
            <AddIcon className={classes.extendedIcon} />
            Novo naročilo
          </Fab>

          {/* { isLoading &&  */}
            <LoadingSpinner visible={isLoading} style={{padding: '1em', width: 'unset', position: 'absolute', right: 0, left: 0}}/>
          {/* } */}

          <IconButton style={{marginRight: '12px'}} id="refresh-orders-button" disabled={isLoading || (page !== 1)} onClick={() => {
            reloadOrders(page, showDrafts)
          }}>
            <RefreshIcon />
          </IconButton>

          {(isAdmin || isManager) && all && (
            <FormControlLabel
              value="show-drafts"
              control={<Checkbox id="show-drafts-checkbox" color="primary" checked={showDrafts} onChange={(e) => {

                let currentQueryParams = queryString.parse(location.search)
                if (e.target.checked){
                  history.push(`/?${queryString.stringify({...currentQueryParams, withDrafts: true})}`)
                }
                else {
                  // let {withDrafts: removedWithDrafts, ...withoutDrafts} = currentQueryParams
                  // history.push(`/?${queryString.stringify(withoutDrafts)}`)
                  history.push(`/`)
                }
              }} />}
              label="Prikaži osnutke"
              // label="Osnutki naročnikov"
              labelPlacement="end"
            />
          )}

          <Pagination 
            page={page}
            totalOrdersCount={totalOrdersCount}
            history={history}
            classes={classes}
          />

          {/* <Button 
            variant="contained" 
            className={classNames(classes.filtersButton, {
              // [classes.filtersButtonOpen]: filtersOpen,
            })}
            onClick={() => setFiltersOpen(!filtersOpen)} 
          >
            {filtersOpen ?  
              <CloseIcon />
              : <FilterListIcon
            />}
            
          </Button> */}
        </Toolbar>

        {/* <Collapse in={filtersOpen} >
          <Filters classes={classes} />
        </Collapse> */}

        {/* <Divider /> */}

        { all &&
          <OrdersList orders={all} classes={classes} history={history} page={page} filtersOpen={false} auth={auth} ordersListRef={ordersListRef} isLoading={isLoading} />
        }

        {/* {isLoading ? (
          <LoadingSpinner style={{padding: '1em'}}/>
        ) : (
          <OrdersList orders={orders} classes={classes} history={history}/>
        )} */}

      </Paper>
    </Fade>
  );
}

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Orders);
