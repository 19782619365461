import React from 'react'
import ConfirmDialog from './Confirm'

const DeleteOrderDialog = ({open, onConfirm, onCancel}) => (
  <ConfirmDialog
    open={open}
    // title="Brisanje naročila"
    content="Želite zbrisati osnutek naročila?"
    confirmText="Zbriši" 
    declineText="Prekliči"
    onConfirm={onConfirm}
    onCancel={onCancel}
  />
)

export default DeleteOrderDialog