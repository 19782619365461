import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done'
import ErrorIcon from '@material-ui/icons/Error'

export default function InfoDialog({title, text, open = false, buttonText, onContinue, showSuccessIcon = false, showErrorIcon= false}) {
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
    // setOpen(false);
  // };

  // text-align: center;
  //   padding: 6px;
  //   background: #e1ffe1;


  return (
    <Dialog
      style={{textAlign: 'center'}}
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      { showSuccessIcon && (
        <div style={{ textAlign: 'center', paddingTop: 20 }}>
          <DoneIcon style={{
            color: 'white',
            fontSize: '46px',
            background: 'green',
            borderRadius: '25px',
            padding: '4px'
          }}/>
        </div>
      )}

      { showErrorIcon && (
        <div style={{ textAlign: 'center', paddingTop: 20 }}>
          <ErrorIcon style={{
            color: 'red',
            fontSize: '46px',
          }}/>
        </div>
      )}

      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="info-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue} color="primary">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
