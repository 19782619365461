import React, {useReducer, useEffect, useCallback, useState, useRef} from 'react'
import SimpleLayout from '../../layout/SingleAppBarLayout'
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ErrorIcon from '@material-ui/icons/ErrorOutlineTwoTone';
import TableHeader from './components/TableHeader'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'

// import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';

import BottomBar from './components/BottomBar'
import GeneralInfo from './components/GeneralInfo'
// import ConfirmDialog from '../../common/dialog/Confirm'
import DeleteRowDialog from '../../common/dialog/DeleteRowDialog'
import DuplicateRowDialog from '../../common/dialog/DuplicateRowDialog'
import DeleteOrderDialog from '../../common/dialog/DeleteOrderDialog'
import SubmitOrderDialog from '../../common/dialog/SubmitOrderDialog'

import {useUpdateLastChange as useUpdateLastOrdersChange} from '../../../store/orders/OrdersContext'

import ordersService from '../../../services/order'
// import {getLocalizedText, getLocalizedOptionText} from '../../../utils/fieldLocalization'

import {getRowValues, getFieldRenderDetails} from '../helpers'

import {useProductConfiguration} from '../../../store/formConfig/FormConfigContext'
import Row from './components/Row';
import orderReducer from './reducer'
import actions from './components/actions';
// import CountingMultiSelect from './components/common/CountingMultiSelect';
// import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
// import Typography from '@material-ui/core/Typography';

import {usePopupState, bindTrigger, bindPopover, bindPopper} from 'material-ui-popup-state/hooks'
import handleSpecialChangeCases from './specialChangeCases'
// import color from '@material-ui/core/colors/amber';

import {useThrottle, useThrottleCallback} from '@react-hook/throttle'

import isDeepEqual from 'fast-deep-equal/react'

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const styles = theme => ({
  root: {
    position: 'relative'
  },
  commonInfoField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200
  },
  optionsTable: {
    "-webkit-box-shadow": "-2px 1px 4px -2px rgba(0,0,0,0.5)",
    backgroundColor: 'white',
    position: 'absolute',
    right: 0,
    // width: '70px',
    zIndex: 5
  },
  errorTable: {
    "-webkit-box-shadow": "-2px 1px 4px -2px rgba(0,0,0,0.5)",
    // backgroundColor: 'white',
    position: 'absolute',
    left: 0,
    // width: '70px',
    zIndex: 5
  },
  // tableWrapper: {
  //   overflowX: 'scroll',
  //   position: 'relative',
  //   // marginRight: '80px'
  //   // padding: '16px 0'
  // },
  tableRow: {
    cursor: 'pointer',
    height: 'unset',
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.background.default
      // backgroundColor: '#efefef',
    },
    // borderColor: 'transparent'
  },
  tableCell: {
    // borderColor: 'transparent'
    padding: '2px',
    border: 'none'
  },
  tableHeightWrapper: {
    maxHeight: 'calc(100vh - 162px)',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 452px)'
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 396px)'
    },
  },
  outerTableContainer: {
    width: 'calc(100% - 80px)',
    height: '100%',
    maxHeight: 'calc(100vh - 162px)',
    overflowX: 'auto',
    overflowY: 'scroll',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 452px)'
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 396px)'
    },
  }
});

// function useOrderInfo({isNew, productId, orderId}) {
  
  
  
//   const [orderData, setOrderData] = useState(null);




//   useEffect(() => {
//     if (orderId) {
//       ordersService.getById(orderId)
//         .then(orderData => {
//           setOrderData(orderData)
//         })
//     }
      
//   }, [orderId]);


//   const productConfig = useProductConfiguration(Number(productId || (orderData && orderData.order && orderData.order.productId)))


//   return {
//     productConfig,
//     orderData
//   }
// }

const BottomLine = () => (
  <div style={{
    position: 'absolute',
    borderBottom: '1px solid lightgray',
    width: '100%',
    bottom: 0
  }}></div>
)

const renderStickyTableHead = ({text, id}) => (
  <TableCell
    style={{
      position: "sticky",
      top: 0,
      padding: 0,
      backgroundColor: 'white',
      zIndex: 3,
      whiteSpace: 'nowrap'
    }}
    key={id}
    align="right"
  >
    <div style={{padding: 8}}>{text}</div>
    <BottomLine />
  </TableCell>
)


const OrderEditor = withRouter(withStyles(styles)(({classes, match, history, auth}) => {

  // useEffect(() => {
  //   console.log('mounted')
  // }, []);

  const {
    orderId: orderIdInUrl,
    productId: productIdInUrl
  } = match.params

  // const [orderData, setOrderData] = useState(null);
  const [orderData, dispatchOrderActions] = useReducer(orderReducer, null);
  // console.log({orderData})
  const loadOrder = useCallback(
    () => {
      // console.log('INIT', orderData)
      if (orderIdInUrl) {
        return ordersService.getById(orderIdInUrl)
          .then(orderData => {
            // console.log('INIT', orderData)
            dispatchOrderActions({type: 'INIT', orderData})
          })
      }
    },
    [orderIdInUrl],
  );

  // let [scrollTop, setScrollTop] = useState(0)
  const optionsEl = useRef(null);

  // const setOrderData = () => {}

  // const [counter, setCounter] = useState(0);
  // useEffect(() => {
  //   setInterval(() => {
  //     setCounter(value => value + 1)
  //   }, 2000)
  // }, [])

  useEffect(() => {
    if (orderIdInUrl) {
      setOrderLoadStatus('loading')
      loadOrder()
        .then(() => {
          setOrderLoadStatus('loaded')
        })
        .catch(e => {
          console.log("e", e)
          let redirectPath = process.env.REACT_APP_BASENAME || '/'
          history.push(redirectPath)
        })
    }
  }, [orderIdInUrl, loadOrder, history]);

  const productId = Number(productIdInUrl || (orderData && orderData.order && orderData.order.productId))
  const productConfig = useProductConfiguration(productId)

  useEffect(() => {
    if (productIdInUrl) {
      // setOrderData({})
      dispatchOrderActions({type: 'INIT', orderData: {
        order: {
          deliveryDate: null,
          finalCustomer: null,
          // id: 105,
          location: null,
          name: null,
          notice: null,
          orderDate: null,
          productId: productIdInUrl,
          productName: productConfig && productConfig.product && productConfig.product.name,
          referenceId: null,
          rowCount: null,
          status: "DRAFT",
          userId: auth.user.id,
          userLogin: auth.user.login
        },
        rows: {
          byId: {
            'fake-id-1': {id: 'fake-id-1', orderNo: 1, valuesIdsByFieldsIds: {}},
            'fake-id-2': {id: 'fake-id-2', orderNo: 2, valuesIdsByFieldsIds: {}},
            'fake-id-3': {id: 'fake-id-3', orderNo: 3, valuesIdsByFieldsIds: {}}
          }, 
          allIds: ['fake-id-1', 'fake-id-2', 'fake-id-3']
        },
        values: {byId: {}}
      }})
    }

    // setTimeout(() => history.replace(`/orders/104/edit`), 3000)
    // setTimeout(() => history.replace(`/orders/new/1`), 3000)
  // }, [productIdInUrl, auth.user.id, auth.user.login, productConfig]);
  // }, [productIdInUrl, auth.user.id, auth.user.login]);
  }, []);
  // }, [productIdInUrl]);

// console.log({orderData})

  let updateLastOrdersChange = useUpdateLastOrdersChange()

  
  // const onScroll = useCallback(
  //   (e) => {
  //     if (optionsEl && optionsEl.current){
  //       optionsEl.current.scrollTop = e.target.scrollTop
  //       console.log(e.target.scrollTop)
  //     }
  //   },
  //   [],
  // );


  const onScroll = useThrottleCallback((scrollTop) => {
    // optionsEl.current.scrollTop = scrollTop
    // console.log(scrollTop)

    optionsEl.current.style.transform = `translateY(-${scrollTop}px)`;

  }, 10, true)

  // TODO: move styles/classes to subcomponents
  let { 
    // tableWrapper: tableWrapperClasses,
    tableRow: tableRowClasses,
    tableCell: tableCellClasses,
    commonInfoField: commonInfoFieldClasses,
    outerTableContainer,
    tableHeightWrapper,
    optionsTable,
    errorTable,
    root,
    ...rowClasses
  } = classes

  // const { i18n } = useTranslation();

  const [orderLoadStatus, setOrderLoadStatus] = useState('dummy-data')
  const editingDisabled = ['creating', 'loading'].includes(orderLoadStatus)

  const [rowMenuInfo, setRowMenuInfo] = useState(null);
  const [deleteRowModalData, setDeleteRowModalData] = useState(null);
  const [deleteOrderModalData, setDeleteOrderModalData] = useState(null);
  const [submitOrderModalData, setSubmitOrderModalData] = useState({open: false, submitted: false});


  const duplicateRowModalState = usePopupState({ variant: 'popover', popupId: 'duplicateRows' })
  const rowErrorPopperState = usePopupState({ variant: 'popper', popupId: 'errorModal' })

  const [popperAllowed, setPopperAllowed] = React.useState(false);
  const [optionHintImage, setOptionHintImage] = React.useState({anchorEl: null, src: null});

  const isCreated = useRef(false);

  // console.log({popperAllowed})
  const handlePopperOpen = (target, src) => {
    setOptionHintImage({
      anchorEl: target,
      src
    });
  };
  const handlePopperClose = () => {
    setOptionHintImage({anchorEl: null, src: null});
  };
  // const optionImageOpen = Boolean(optionImgAnchorEl);

  function handleOptionsClick(target, index, selectedRowsIds = [], isFirstOfConnectedRows, rowsWithRenderDetails) {
    // let subRowIds = rowsWithRenderDetails
    console.log({rowsWithRenderDetails})
    // console.log({target})
    setRowMenuInfo({anchorEl: target, targetIndex: index, selectedRowsIds, isFirstOfConnectedRows});
  }
  function handleDuplicate(e) {
    duplicateRowModalState.open(e.target)
    // duplicateRowModalState.open()
    // let newItem = {
    //   ...orderData.rows[rowMenuInfo.targetIndex],
    //   id: Math.random()
    // }
    // setOrderData({
    //   ...orderData,
    //   rows: [
    //     ...orderData.rows.slice(0, rowMenuInfo.targetIndex + 1),
    //     newItem,
    //     ...orderData.rows.slice(rowMenuInfo.targetIndex + 1)
    //   ]
    // })
    // setRowMenuInfo(null);
  }

  // TODO: implement delete without order re-loading (!loadOrder)
  function handleRowDelete (rowId) {
    rowId && ordersService.deleteRow(rowId)
      .then(() => {
        loadOrder()
        setDeleteRowModalData(null)
        setRowMenuInfo(null);
      })
  }

  function openDeleteRowModal() {
    setDeleteRowModalData({
      onConfirm: () => {
        if (!orderData.order.id){
          setDeleteRowModalData(null)
        }
        else {
          if (Array.isArray(rowMenuInfo && rowMenuInfo.selectedRowsIds)) {
            if (rowMenuInfo.selectedRowsIds[0]) {

              ordersService.deleteRow(rowMenuInfo.selectedRowsIds[0])
                .then(() => {
                  if (rowMenuInfo.selectedRowsIds[1])
                    return ordersService.deleteRow(rowMenuInfo.selectedRowsIds[1])
                })
                .then(() => {
                  if (rowMenuInfo.selectedRowsIds[2])
                    return ordersService.deleteRow(rowMenuInfo.selectedRowsIds[2])
                })
                .then(() => {
                  loadOrder()
                  setDeleteRowModalData(null)
                  setRowMenuInfo(null);
                })
            }
          }
          // handleRowDelete((rowMenuInfo && rowMenuInfo.selectedRowsIds && rowMenuInfo.selectedRowsIds[0]) || rowId)
        }
      }
    })

    // setOrderData({...orderData, rows: orderData.rows.filter((_, i) => i !== rowMenuInfo.targetIndex)})
    // setRowMenuInfo(null);
  }

  function handleClose () {
    setRowMenuInfo(null)
  }

  // const language = i18n.language

  // console.log({productId, productConfig})

  const orderRef = useRef(orderData && orderData.order)
  if (orderData && orderData.order && !isDeepEqual(orderRef.current, orderData && orderData.order))
    orderRef.current = orderData && orderData.order

  if (!productConfig || !orderData)
    return null

  // console.log({productConfig, orderData})


  // if (!productConfig)
  //   return null

  // if  (!isNew && !orderData)
  //   return null

  // if (isNew && !productConfig)
  //   return "Loading..."

  // if (!orderData)
  //   return null

  // console.log({orderData, productConfig})

  // let productFields = product.fieldsIds.map(id => allFields.byId[id])
    // .splice(25);
  // console.log({productFields})
  // let productOptions = productConfig.options.allIds.map(id => productConfig.options.byId[id])
    // .filter(f => ['Vrsta pogona', 'Tip elektromotorja'].includes(f.text))
    // .filter(f => ['Vrsta pogona', 'Krmiljenje / možnih je več izborov'].includes(f.text))
    // .filter(f => ['Alu maska', 'Nosilec žaluzije'].includes(f.text))
    // .filter(f => ['Alu maska', 'Nosilec žaluzije', 'Tip maske', 'Pozicija naročnika'].includes(f.text))
    // .filter(f => ['Alu maska', 'Tip maske', 'Mera "c"', 'Mera "e" / Multibox'].includes(f.text))

    // console.log({bt: bindTrigger(duplicateRowModalState)})


  
  let {order, rows, values} = orderData

  if (order.status !== 'DRAFT'){
    // console.log('This order is not a DRAFT! Shouldn\'t be here')
    history.replace('/')
  }

  let rowsList = rows.allIds
    .map((rowId) => rows.byId[rowId])
    .sort((row1, row2) => row1.orderNo - row2.orderNo)

  // TODO: Memoize fieldsToRender, try others 
    
  let fieldsToRender = productConfig.product && productConfig.product.fieldsIds
    && productConfig.product.fieldsIds.map(fieldId => productConfig.fields.byId[fieldId])
    // .filter(f => ['Spajanje žaluzije', 'Stransko vodilo LEVO', 'Stransko vodilo LEVO / Barva', 'Stransko vodilo LEVO / Distančnik'].includes(f.text))
    // .filter(f => ['Pozicija naročnika', 'Vrsta pogona', 'Krmiljenje / možnih je več izborov'].includes(f.text))
    // .filter(f => ['Vrsta pogona', 'Tip elektromotorja'].includes(f.text))
    // .filter(f => ['Alu maska', 'Tip maske', 'Tip maske', 'Mera "c"', 'Mera "e" / Multibox'].includes(f.text))
    // .filter(f => ['Alu maska', 'Nosilec žaluzije', 'Tip maske', 'Tip maske', 'Mera "c"', 'Mera "e" / Multibox'].includes(f.text))
    // .filter(f => ['Pozicija naročnika', 'Alu maska', 'Nosilec žaluzije', 'Tip maske', 'Tip maske', 'Mera "c"', 'Mera "e" / Multibox'].includes(f.text))
    // .filter(f => ['Pozicija naročnika', 'Spajanje žaluzije'].includes(f.text))
    // .filter(f => ['Pozicija naročnika', 'Spajanje žaluzije', 'Stransko vodilo LEVO', 'Stransko vodilo LEVO / Barva', 'Stransko vodilo LEVO / Distančnik', 'Stransko vodilo DESNO', 'Stransko vodilo DESNO / Barva', 'Stransko vodilo DESNO / Distančnik'].includes(f.text))
    // .filter(f => ['Spajanje žaluzije', 'Stransko vodilo LEVO', 'Stransko vodilo LEVO / Barva', 'Stransko vodilo LEVO / Distančnik', 'Stransko vodilo DESNO', 'Stransko vodilo DESNO / Barva', 'Stransko vodilo DESNO / Distančnik'].includes(f.text))
    // .filter(f => ['Alu maska', 'Dolžina maske', 'Dolžina levi del', 'Dolžina desni del'].includes(f.text))
    // .filter(f => ['Spajanje žaluzije'].includes(f.text))
  

    // let isFirstOfConnectedRows = false
    // let isSubRow = false

    // if (renderDetails && renderDetails.rowValuesObjects) {
    //   for (let valueObject of Object.values(renderDetails.rowValuesObjects)) {
    //     if (["Spojena 1/2", "Spojena 1/3"].includes(valueObject.text)) {
    //       isFirstOfConnectedRows = true
    //     }
        // if (["Spojena 2/2", "Spojena 2/3", "Spojena 3/3"].includes(valueObject.text)) {
        //   isSubRow = true
        // }
    //     if (isFirstOfConnectedRows && isSubRow)
    //       break;
    //   }
    // }


  let rowsWithRenderDetails = rowsList.map((row, rowIndex) => {
    let {rowValues, rowValuesObjects} = getRowValues(row, values, productConfig.fields) 
    // console.log({rowValuesObjects})
    let fieldDetailsByFieldId = {}
    let isRowEmpty = true
    let hasEmptyRequiredFields = false
    let isFirstOfConnectedRows = false
    let isSubRow = false
    let isFirstOfTwo = false
    let isSecondOfTwo = false
    let isFirstOfThree = false
    let isSecondOfThree = false
    let isThirdOfThree = false


    fieldsToRender.forEach((field, ind) => {
      let valueObj = rowValuesObjects[field.id]
      let fieldDetails = getFieldRenderDetails({field, allOptions: productConfig.options, allRules: productConfig.rules, rowValues, valueObj})
      fieldDetailsByFieldId[field.id] = fieldDetails

      if (rowValues[field.id] || rowValues[field.id] === 0){
        isRowEmpty = false
      }
      else {
        // if (fieldDetails.isEnabled){
        if (fieldDetails.isEnabled && field.type !== 'SELECT_MULTIPLE'){
          hasEmptyRequiredFields = true
        }
      }

      if (valueObj && valueObj.text === "Spojena 1/2") {
        isFirstOfConnectedRows = true
        isFirstOfTwo = true
      }
      if (valueObj && valueObj.text === "Spojena 2/2") {
        isSubRow = true
        isSecondOfTwo = true
      }

      if (valueObj && valueObj.text === "Spojena 1/3") {
        isFirstOfConnectedRows = true
        isFirstOfThree = true
      }
      if (valueObj && valueObj.text === "Spojena 2/3") {
        isSubRow = true
        isSecondOfThree = true
      }
      if (valueObj && valueObj.text === "Spojena 3/3") {
        isSubRow = true
        isThirdOfThree = true
      }



      // if (valueObj && ["Spojena 1/2", "Spojena 1/3"].includes(valueObj.text)) {
      //   isFirstOfConnectedRows = true
      // }
      // else if (valueObj && ["Spojena 2/2", "Spojena 2/3", "Spojena 3/3"].includes(valueObj.text)) {
      //   isSubRow = true
      // }
    })

    let withError = isRowEmpty ? false : hasEmptyRequiredFields

    return {
      row,
      rowValues,
      rowValuesObjects,
      isRowEmpty,
      hasEmptyRequiredFields,
      withError,
      fieldDetailsByFieldId,
      isFirstOfConnectedRows,
      isSubRow,
      isFirstOfTwo,
      isSecondOfTwo,
      isFirstOfThree,
      isSecondOfThree,
      isThirdOfThree
    }
  })

  let allRowsOk = rowsWithRenderDetails.some(({isRowEmpty}) => !isRowEmpty)
    && rowsWithRenderDetails.every(({withError}) => !withError)

  const addRows = async ({quantity = 3, orderId}) => {
    let allOrderNumbers = rowsList.map(row => row.orderNo)
    let max = Math.max(0, ...allOrderNumbers)

    let positionNumbers = [...Array(quantity).keys()]
      .map(index => index + 1 + max)

    if (orderId || order.id){
      // console.log('order.id')
      return ordersService.createRows(orderId || order.id, positionNumbers)
        .then(addedRows => {
          dispatchOrderActions({type: 'ADD_ROWS', addedRows})
          return addedRows
        })  
    }
    else {
      dispatchOrderActions({type: 'ADD_ROWS', addedRows: positionNumbers.map(orderNo => ({orderNo, id: Math.random()}))})
    }
  }

  const insertRows = async ({quantity = 1, orderId, number}) => {
    
    // console.log({number, orderId})
    // let allOrderNumbers = rowsList.map(row => row.orderNo)
    // let max = Math.max(0, ...allOrderNumbers)

    let positionNumbers = [...Array(quantity).keys()]
      .map(index => number)

    // console.log({positionNumbers})

    // dispatchOrderActions({type: 'ADD_ROWS', addedRows})

    // console.log(orderId || order.id)

    if (orderId || order.id){

      // setTimeout(() => {
        // dispatchOrderActions({type: 'INSERT_ROWS', orderNo: number, quantity,
        //   insertedRows: positionNumbers.map(orderNo => ({orderNo, id: Math.random()}))
        // })
      // }, 500)

      // return 
      // console.log('order.id')
      return ordersService.createRows(orderId || order.id, positionNumbers)
        .then(insertedRows => {
          // console.log({before: insertedRows})
          insertedRows = insertedRows.reverse()
            .map((row, ri) => ({...row, orderNo: row.orderNo + ri}))

          // console.log({after: insertedRows})

          // console.log(insertedRows.map(r => r.orderNo))

          // console.log({insertedRows})


          dispatchOrderActions({type: 'INSERT_ROWS', orderNo: number, quantity, insertedRows})



          // dispatchOrderActions({type: 'ADD_ROWS', addedRows})
          // dispatchOrderActions({type: 'INSERT_ROWS', orderNo})
          // console.log({orderIdInUrl})
          // loadOrder()
          return insertedRows
          // return delay(500)
          //   .then(() => insertedRows)
        })  
    }
    // else {
    //   dispatchOrderActions({type: 'ADD_ROWS', addedRows: positionNumbers.map(orderNo => ({orderNo, id: Math.random()}))})
    // }
  }

  let numberOfSelectedRows = rowMenuInfo && rowMenuInfo.selectedRowsIds && rowMenuInfo.selectedRowsIds.length

  return (
    <React.Fragment>

      <DeleteRowDialog
        open={Boolean(deleteRowModalData)}
        onConfirm={deleteRowModalData && deleteRowModalData.onConfirm}
        numberOfSelectedRows={numberOfSelectedRows}
        onCancel={() => {
          setDeleteRowModalData(null)
          setRowMenuInfo(null);
        }}
      />

      <DuplicateRowDialog
        popoverProps={bindPopover(duplicateRowModalState)}
        // open={Boolean(duplicateRowModalState.isOpen)}
        onConfirm={(numbersOfDuplicates) => {
          ordersService.duplicateRow(rowMenuInfo.selectedRowsIds && rowMenuInfo.selectedRowsIds[0], numbersOfDuplicates)
            .then(orderData => {
              loadOrder()
              duplicateRowModalState.close()
              setRowMenuInfo(null);
            })
        }}
        onCancel={() => {
          duplicateRowModalState.close()
          setRowMenuInfo(null);
        }}
      />

      <DeleteOrderDialog
        open={Boolean(deleteOrderModalData)}
        onConfirm={deleteOrderModalData && deleteOrderModalData.onConfirm}
        onCancel={() => {
          setDeleteOrderModalData(null)
          setRowMenuInfo(null);
        }}
      />
      
      <DeleteOrderDialog
        open={Boolean(deleteOrderModalData)}
        onConfirm={deleteOrderModalData && deleteOrderModalData.onConfirm}
        onCancel={() => {
          setDeleteOrderModalData(null)
          setRowMenuInfo(null);
        }}
      />

      <SubmitOrderDialog
        open={submitOrderModalData.open}
        submitted={submitOrderModalData && submitOrderModalData.submitted}
        onExited={() => {
          setSubmitOrderModalData({open: false, submitted: false})
          if (submitOrderModalData.submitted){
            let replacePath = `/orders/${orderData.order.id}`
            history.replace(replacePath)
          }
        }}
        onConfirm={() => {
          if (orderData && orderData.order && orderData.order.id){
            ordersService.submitOrder(orderData.order.id)
              .then(() => {
                updateLastOrdersChange()
                setSubmitOrderModalData({
                  ...submitOrderModalData,
                  submitted: true,
                })
              })
          }
        }}
        onCancel={() => setSubmitOrderModalData({
          ...submitOrderModalData,
          open: false
        })}
      />

      {/* <SubmitOrderDialog
        open={submitOrderModalOpen}
        onConfirm={() => {
          if (orderData && orderData.order && orderData.order.id){
            ordersService.submitOrder(orderData.order.id)
              .then(() => {
                updateLastOrdersChange()
                let redirectPath = `/orders/${orderData.order.id}`
                history.push(redirectPath)
              })
          }
        }}
        onCancel={() => setSubmitOrderModalOpen(false)}
      /> */}

      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={100}
        id="simple-menu" anchorEl={rowMenuInfo && rowMenuInfo.anchorEl}
        open={Boolean(rowMenuInfo && (!deleteRowModalData && !duplicateRowModalState.isOpen))}
        onClose={handleClose}>
        {/* <MenuItem onClick={handleDuplicate}>Podvoji vrstico</MenuItem> */}
        <MenuItem id="duplicate-row-option" disabled={rowMenuInfo && rowMenuInfo.isFirstOfConnectedRows} {...bindTrigger(duplicateRowModalState)}
          onClick={handleDuplicate}
        >{
          numberOfSelectedRows == 1 ? 'Podvoji vrstico'
          : numberOfSelectedRows == 2 ? 'Podvoji vrstici'
          : numberOfSelectedRows == 3 ? 'Podvoji vrstice'
          : null
        }</MenuItem>
        <MenuItem id="delete-row-option" onClick={() => {
          openDeleteRowModal()
        }}>{
          numberOfSelectedRows == 1 ? 'Odstrani vrstico'
          : numberOfSelectedRows == 2 ? 'Odstrani vrstici'
          : numberOfSelectedRows == 3 ? 'Odstrani vrstice'
          : null
        }</MenuItem>
      </Menu>

      <div className={classes.tableContainer}>
        <Paper className={root} >

          <GeneralInfo
            readOnlyFields={editingDisabled}
            order={order}
            product={productConfig.product}
            commonInfoFieldClasses={commonInfoFieldClasses}
            onDateChange={(newDate) => {
              let value = newDate.format('YYYY-MM-DD')
              dispatchOrderActions(actions.updateOrderAttribute('deliveryDate', value))
              // ordersService.updateOrder({...order, deliveryDate: value})

              if (!productIdInUrl)
                ordersService.updateOrder({...order, deliveryDate: value})
              else {
                if (!order.id && productIdInUrl && auth && auth.user && auth.user.id){
                  setOrderLoadStatus('creating')
                  return ordersService.createOrder(productIdInUrl, auth.user.id)
                    .then(createdOrder => {
                      let newRowIndexes = rowsList.map((item, index) => index+1)
                      return ordersService.createRows(createdOrder.id, newRowIndexes)
                        .then((rows) => [createdOrder, rows])
                    })
                    .then(([createdOrder, rows]) => {
                      ordersService.updateOrder({...order, id: createdOrder.id, deliveryDate: value})
                        .then(() => {
                          setTimeout(() => {
                            history.replace(`/orders/${createdOrder.id}/edit`)
                            setOrderLoadStatus('created')
                          }, 400)
                        })
                    })
                }
              }
            }}

            onNoticeChange={(newNotice) => {
              dispatchOrderActions(actions.updateOrderAttribute('notice', newNotice))
              
              if (!productIdInUrl)
                ordersService.updateOrder({...order, notice: newNotice})
              else {
                if (!order.id && productIdInUrl && auth && auth.user && auth.user.id){
                  setOrderLoadStatus('creating')
                  return ordersService.createOrder(productIdInUrl, auth.user.id)
                    .then(createdOrder => {
                      let newRowIndexes = rowsList.map((item, index) => index+1)
                      return ordersService.createRows(createdOrder.id, newRowIndexes)
                        .then((rows) => [createdOrder, rows])
                    })
                    .then(([createdOrder, rows]) => {
                      ordersService.updateOrder({...order, id: createdOrder.id, notice: newNotice})
                        .then(() => {
                          setTimeout(() => {
                            history.replace(`/orders/${createdOrder.id}/edit`)
                            setOrderLoadStatus('created')
                          }, 400)
                        })
                    })
                }
              }
            }}

            onTextFieldChange={(fieldName, value) => {
              // console.log('onTextFieldChange')
              // console.log(fieldName, value)
              dispatchOrderActions(actions.updateOrderAttribute(fieldName, value))
            }}
            onTextFieldBlur={(fieldName) => {
              // console.log('order')
              // console.log({order})
              let order = orderRef.current
              // console.log(orderRef.current && orderRef.current.finalCustomer)

              if (!productIdInUrl)
                ordersService.updateOrder(order)
              else {
                if (!order[fieldName])
                  return null

                if (!order.id && productIdInUrl && auth && auth.user && auth.user.id){
                  setOrderLoadStatus('creating')
                  return ordersService.createOrder(productIdInUrl, auth.user.id)
                    .then(createdOrder => {
                      let newRowIndexes = rowsList.map((item, index) => index+1)
                      return ordersService.createRows(createdOrder.id, newRowIndexes)
                        .then((rows) => [createdOrder, rows])
                    })
                    .then(([createdOrder, rows]) => {
                      ordersService.updateOrder({...order, id: createdOrder.id})
                        .then(() => {
                          setTimeout(() => {
                            history.replace(`/orders/${createdOrder.id}/edit`)
                            setOrderLoadStatus('created')
                          }, 400)
                        })
                    })
                }
              }
            }}

            onDeleteOrderClick={(orderId) => {
              setDeleteOrderModalData({
                onConfirm: () => {
                  ordersService.deleteOrder(orderId)
                    .then(() => {
                      let redirectPath = process.env.REACT_APP_BASENAME || '/'
                      updateLastOrdersChange()
                      history.push(redirectPath)
                    })
                }
              })
            }}

            reloadOrder={loadOrder}
          />

          <Divider variant="fullWidth" style={{height: '4px'}}/>
            <div className={tableHeightWrapper}>

              <div className={outerTableContainer} 
                // style={{width: 'calc(100% - 80px)'}}
                onScroll={(e) => {
                  if (optionsEl && optionsEl.current){
                    onScroll(e.target.scrollTop)
                  }
                }
                }
              >

                {/* <div style={{
                  position: 'absolute',
                  right: 0,
                  width: 299,
                  backgroundColor: 'lightblue',
                  top: 64,
                  zIndex: 33
                }}>test</div> */}
                
                {/* <div style={{position: 'sticky', left: 0, zIndex: 4}} > */}

                {/* Sticky row options bar on the right (Možnosti)  */}

                {/* <div style={{
                  position: 'sticky',
                  left: 'calc(100% - 80px)',
                  zIndex: 4,
                  width: 80,

                  position: '-webkit-sticky',
                  position: 'sticky',
                  display: 'block',

                }} >
                  <Table className={classes.tabl6 + " " + optionsTable} >
                    <TableHead>
                      <TableRow>
                        {renderStickyTableHead({text: 'Možnosti', id: 'options'})}
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {rowsWithRenderDetails.map(({row, withError}, ind) => {

                        return (
                          <TableRow key={ind} className={tableRowClasses}>
                            <TableCell className={tableCellClasses} style={{padding: "2px 0px", textAlign: 'center'}}>
                              <IconButton disabled={!order.id} onClick={(e) => handleOptionsClick(e.currentTarget, ind, row.id)} size={'small'} className={classes.button} aria-label="Options" style={{padding: "5px"}}>
                                <MoreHorizIcon />
                              </IconButton>
                            </TableCell>
                            { withError &&
                              <TableCell className={tableCellClasses} style={{padding: "2px 0px", textAlign: 'center', position: 'absolute', left: 0}}>
                                <ErrorIcon onMouseEnter={rowErrorPopperState.open} onMouseLeave={rowErrorPopperState.close} style={{color: 'red', fontSize: '2.5em', padding: '0px 1px', width: 30}}/>
                              </TableCell>
                            }
                          </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
                </div> */}

                

                <Table id="order-editor-table" className={classes.tabl6} >

                  <TableHeader id="order-editor-thead" fields={fieldsToRender} />
                  
                  <TableBody id="order-editor-tbody">
                    {
                      //rows.allIds && rows.allIds // .splice(0, 1).map((rowId, ind) => {
                        // rowsList.map((row, ind) => {
                        rowsWithRenderDetails.map((renderDetails, ind) => {
                          let {
                            row,
                            // rowValues,
                            // rowValuesObjects,
                            withError,
                            fieldDetailsByFieldId
                          } = renderDetails
                          

                          // let rowFullyFilled = fieldsToRender.every(({id: fieldId}) => {
                          //   let fieldValuesIds = row.valuesIdsByFieldsIds[fieldId]
                          //   if (fieldValuesIds && fieldValuesIds.length > 0){
                          //     return fieldValuesIds.every(valueId => {
                          //       let valueObj = values.byId[valueId]
                          //       if (valueObj.text || valueObj.number || valueObj.optionId){
                          //         // console.log(valueObj.text || valueObj.number || valueObj.optionId)
                          //         return true
                          //       }
                          //     })
                          //   }
                          //   return false
                          // })

                          // let rowError = !rowFullyFilled

                          const beforeFirstCreatedValue = async ({redirectTimeout = 400} = {}) => {
                            // console.log('beforeFirstCreatedValue', orderLoadStatus)

                            // TODO:
                            // Save all default values to backend

                            if (isCreated.current)
                              return

                            isCreated.current = true
                                
                            if (!productIdInUrl)
                              return null

                            if (productIdInUrl && auth && auth.user && auth.user.id){
                              // console.log('we\'re creating order...')
                              setOrderLoadStatus('creating')
                              return ordersService.createOrder(productIdInUrl, auth.user.id)
                                .then(createdOrder => {
                                  let newRowIndexes = rowsList.map((item, index) => index+1)
                                  return ordersService.createRows(createdOrder.id, newRowIndexes)
                                    .then((rows) => [createdOrder, rows])
                                })
                                .then(([createdOrder, rows]) => {

                                  setOrderLoadStatus('created')

                                  if (redirectTimeout) {
                                    setTimeout(() => {
                                      history.replace(`/orders/${createdOrder.id}/edit`)
                                    }, redirectTimeout)
                                  }

                                  if (rows && rows[ind] && rows[ind].id)
                                      return {createdOrder, rows, thisRowId: rows[ind].id, thisOrderId: createdOrder.id}

                                  // return new Promise((resolve, reject) => {
                                  //   setTimeout(() => {
                                  //     history.replace(`/orders/${createdOrder.id}/edit`)
                                  //     setOrderLoadStatus('created')

                                  //     if (rows && rows[ind] && rows[ind].id)
                                  //       resolve(rows[ind].id)
                                  //     else console.log('error creating order')
                                      
                                  //   }, 2000)
  
                                  //   if (rows && rows[ind] && rows[ind].id)
                                  //     return rows[ind].id
                                  // })
                                })
                            }

                            else return Promise.resolve()

                          }

                          // console.log({rowsWithRenderDetails})
                          
                          return (
                            <Row
                              orderLoadStatus={orderLoadStatus}
                              editingDisabled={editingDisabled}
                              fieldDetailsByFieldId={fieldDetailsByFieldId}
                              withError={withError}
                              fields={productConfig.fields}
                              fieldsToRender={fieldsToRender}
                              allOptions={productConfig.options}
                              allRules={productConfig.rules}
                              // isSelected={rowMenuInfo && rowMenuInfo.targetIndex === ind}
                              isSelected={Boolean(rowMenuInfo && rowMenuInfo.selectedRowsIds && rowMenuInfo.selectedRowsIds.includes(row.id))}
                              // style={{backgroundColor: (rowMenuInfo && rowMenuInfo.targetIndex === ind ? '#ffe9d6' : 'initial')}}
                              // style={{boxShadow: (menuInfo && menuInfo.targetIndex === ind ? 'rgb(255, 166, 87) 0px 0px 0px 3px inset' : 'none')}}
                              tableCellClasses={tableCellClasses}
                              tableRowClasses={tableRowClasses}
                              classes={rowClasses}
                              // key={row.id}
                              key={ind}
                              number={ind+1}
                              row={row}
                              rowSecond={rowsWithRenderDetails[ind+1] && rowsWithRenderDetails[ind+1].row}
                              rowThird={rowsWithRenderDetails[ind+2] && rowsWithRenderDetails[ind+2].row}
                              rowFourth={rowsWithRenderDetails[ind+3] && rowsWithRenderDetails[ind+3].row}
                              rowFifth={rowsWithRenderDetails[ind+4] && rowsWithRenderDetails[ind+4].row}
                              values={values}
                              dispatch={dispatchOrderActions}
                              handlePopperClose={handlePopperClose}
                              handlePopperOpen={handlePopperOpen}
                              setPopperAllowed={setPopperAllowed}

                              beforeFirstCreatedValue={beforeFirstCreatedValue}
                              rowCount={rowsWithRenderDetails.length}
                              handleSpecialChangeCases={async ({...attrs}) => {                                

                                // let {field} = attrs

                                await handleSpecialChangeCases({
                                  ...attrs,
                                  rowsWithRenderDetails, ind, dispatchOrderActions, actions, ordersService, beforeFirstCreatedValue,
                                  // productField: productConfig.fields.byId[field.id],
                                  allOptions: productConfig.options, allValues: values, addRows, deleteRow: handleRowDelete,
                                  insertRows: ({...rest}) => insertRows({...rest, number: ind+1})
                                })
                              }}
                              rowsWithRenderDetails={rowsWithRenderDetails}
                              history={history}
                              
                              // addRows={addRows}
                            />
                          )
                        })
                    }
                  </TableBody>

                </Table>

                {/* <div style={{width: '80px'}}></div> */}
              </div>

              {/* Row options table */}
              <div className={tableHeightWrapper + " " + optionsTable} style={{
                width: 80,
                // backgroundColor: 'lightblue',
                right: 0,
                top: 0,
                position: 'absolute',

                height: '100%',
                overflow: 'hidden',
                scrollBehavior: 'smooth'
              }}>
                <Table className={classes.tabl6 }>
                    <TableHead>
                      <TableRow>
                        {renderStickyTableHead({text: 'Možnosti', id: 'options'})}
                      </TableRow>
                    </TableHead>
                    <TableBody ref={optionsEl} style={{transition: 'transform 120ms linear'}}>
                      {rowsWithRenderDetails.map((renderDetails, ind) => {
                        let {
                          row, withError, isFirstOfConnectedRows, isSubRow,
                          isFirstOfTwo,
                          isSecondOfTwo,
                          isFirstOfThree,
                          isSecondOfThree,
                          isThirdOfThree
                        } = renderDetails
                        // console.log({renderDetails})

                        // let isFirstOfConnectedRows = false
                        // let isSubRow = false

                        // if (renderDetails && renderDetails.rowValuesObjects) {
                        //   for (let valueObject of Object.values(renderDetails.rowValuesObjects)) {
                        //     if (["Spojena 1/2", "Spojena 1/3"].includes(valueObject.text)) {
                        //       isFirstOfConnectedRows = true
                        //     }
                        //     if (["Spojena 2/2", "Spojena 2/3", "Spojena 3/3"].includes(valueObject.text)) {
                        //       isSubRow = true
                        //     }
                        //     if (isFirstOfConnectedRows && isSubRow)
                        //       break;
                        //   }
                        // }

                        // if (renderDetails && renderDetails.rowValuesObjects) {
                        //   isSubRow = Object.values(renderDetails.rowValuesObjects).find(valueObject => ["Spojena 2/2", "Spojena 2/3", "Spojena 3/3"].includes(valueObject.text))
                        //   if (isSubRow)
                        //     optionsDisabled = true
                        // }

                        let optionsDisabled = !order.id || isSubRow

                        return (
                          <TableRow key={ind} className={tableRowClasses}>
                            <TableCell className={tableCellClasses} style={{padding: "2px 0px", textAlign: 'center'}}>
                              <IconButton id={'row-options-button'} disabled={optionsDisabled}
                                onClick={(e) => {
                                  let selectedRowIdList = [row.id]
                                  console.log({rowsWithRenderDetails})
                                  if (isFirstOfThree) {
                                    let secondRow = rowsWithRenderDetails[ind+1]
                                    if (secondRow && secondRow.isSecondOfThree){
                                      if (secondRow.row && secondRow.row.id)
                                        selectedRowIdList.push(secondRow.row.id)
                                    }

                                    let thirdRow = rowsWithRenderDetails[ind+2]
                                    if (thirdRow && thirdRow.isThirdOfThree){
                                      if (thirdRow.row && thirdRow.row.id)
                                        selectedRowIdList.push(thirdRow.row.id)
                                    }
                                  }
                                  else if (isFirstOfTwo) {
                                    let secondRow = rowsWithRenderDetails[ind+1]
                                    if (secondRow && secondRow.isSecondOfTwo){
                                      if (secondRow.row && secondRow.row.id)
                                        selectedRowIdList.push(secondRow.row.id)
                                    }
                                  }
                                  handleOptionsClick(e.currentTarget, ind, selectedRowIdList, isFirstOfConnectedRows, rowsWithRenderDetails)
                                }}
                                size={'small'} className={classes.button} aria-label="Options" style={{padding: "5px"}}
                                >
                                <MoreHorizIcon />
                              </IconButton>
                            </TableCell>
                            { withError &&
                              <TableCell className={tableCellClasses} style={{padding: "2px 0px", textAlign: 'center', position: 'absolute', left: 0}}>
                                <ErrorIcon id="row-error-icon" onMouseEnter={rowErrorPopperState.open} onMouseLeave={rowErrorPopperState.close} style={{color: 'red', fontSize: '2.5em', padding: '0px 1px', width: 30}}/>
                              </TableCell>
                            }
                          </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
              </div>

            </div>
            
            <BottomBar
              addRows={addRows}
              submitOrder={() => {
                setSubmitOrderModalData({open: true, submitted: false})
              }}
              addRowsDisabled={!(orderData && orderData.order && orderData.order.id)}
              submitDisabled={orderData.order.userLogin !== auth.user.login || !allRowsOk || !order.id}
            />
        </Paper>
      </div>

      {/* select box with images popper */}
      <Popper open={popperAllowed && Boolean(optionHintImage.anchorEl)} anchorEl={optionHintImage.anchorEl} placement="left" style={{zIndex: 50}}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          }
        }}
      >
        <Paper style={{padding: 16}}>
          <img src={optionHintImage.src} alt=""
            // width="500" height="333"
            width="333" height="222"
          />
        </Paper>
      </Popper>

      <Popper {...bindPopper(rowErrorPopperState)} placement="left" transition style={{zIndex: 1}}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={150}>
            <Paper elevation={2} id="row-error-text">
              <Typography style={{padding: 8, color: 'red'}}>
                Izpolnite prazna polja
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>

      {/* <Popover
        // style={{pointerEvents: 'none'}}
        id={'demo-popover'}
        open={}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
      </Popover> */}


    </React.Fragment>
  )
}))

const OrderEditorPage = ({auth, match, isNew}) => {
  // console.log({match})

  const {orderId} = match.params

  let headerText = orderId  ? 
    `Urejanje naročila ${orderId}`
    : 'Novo naročilo'

  return (
    <SimpleLayout
      headerText={headerText}
      component={OrderEditor}
      auth={auth}
      isNew={isNew}
      // mainContentStyles={{backgroundColor: 'red'}}
    />
  )
}

export default withRouter(OrderEditorPage);
