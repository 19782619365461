import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root'

ReactDOM.render(
  <Root />,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}