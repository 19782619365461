import handleFieldChangeFromName from '../handleFieldChangeFromName'
import handleFieldResetFromName from '../handleFieldResetFromName'

export default async ({
  field, newValueOptionId, allFieldsToRender,
  rowsWithRenderDetails, ind, dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService,
  // productField,
  allOptions, createdOrderId, actualRows, addRows,
  rowsWithRenderDetailsRef
}) => {

  let currentRow = rowsWithRenderDetails[ind]
  let secondRow = rowsWithRenderDetails[ind + 1]
  let thirdRow = rowsWithRenderDetails[ind + 2]
  // let fourthRow = rowsWithRenderDetails[ind + 3]
  // let fifthRow = rowsWithRenderDetails[ind + 4]

  let selectedOption = allOptions.byId[newValueOptionId]
  if (!selectedOption)
    return

  let oldOptionId = currentRow.rowValues[field.id]
  let oldOption = allOptions.byId[oldOptionId]
  // console.log('stranskoVodiloLevo')

  let conditionField1 = allFieldsToRender.find(f => {
    return (f.name === 'spajanje_zaluzije')
  })
  let valueObj1 = currentRow && currentRow.rowValuesObjects && currentRow.rowValuesObjects[conditionField1.id]

  let conditionField2 = allFieldsToRender.find(f => {
    return (f.name === 'stransko_vodilo_levo')
  })
  let valueObj2 = currentRow && currentRow.rowValuesObjects && currentRow.rowValuesObjects[conditionField2.id]

  if (!valueObj1 || !valueObj2)
    return

  let conditionOption1 = allOptions.byId[valueObj1.optionId]
  let conditionOptionText1 = conditionOption1 && conditionOption1.text

  let conditionOption2 = allOptions.byId[valueObj2.optionId]
  let conditionOptionText2 = conditionOption2 && conditionOption2.text

  if (!conditionOptionText1 || !conditionOptionText2)
    return

  if (!['enojno_vodilo', 'MultiBox_1-1', 'MultiBox_1-1-1', 'Multibox_1-1-2', 'MultiBox_1-1-3'].includes(conditionOption2.value))
    return
  
  // console.log({currentRow, secondRow, thirdRow, fourthRow, fifthRow})
  
  // let fieldDetails = currentRow && currentRow.fieldDetailsByFieldId && currentRow.fieldDetailsByFieldId[field.id]

  let handleChange = (targetFieldName, targetOptionText, rowWithDetails, actualRowId) => {
    handleFieldChangeFromName({
      targetFieldName,
      targetOptionText,
      rowWithDetails,
      actualRowId,
      
      createdOrderId,
      dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService, allFieldsToRender, allOptions
    })
  }

  let handleReset = (targetFieldName, rowWithDetails, actualRowId) => {
    handleFieldResetFromName({
      targetFieldName,
      rowWithDetails,
      actualRowId,
      
      createdOrderId,
      dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService, allFieldsToRender, allOptions
    })
  }

  if (conditionOptionText1 === 'Samostojna'){
    if (currentRow) {
      let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
      handleChange('stransko_vodilo_desno-barva', selectedOption.text, currentRow, actualRowId)
    }
  }
  
  else if (conditionOptionText1 === 'Spojena 1/2') {
    // handling first (Spojena 1/2) row
    if (currentRow) {
      let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
      handleChange('stransko_vodilo_desno-barva', selectedOption.text, currentRow, actualRowId)
    }

    // handling second (Spojena 2/2) row
    if (secondRow){
      let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
      handleChange('stransko_vodilo_desno-barva', selectedOption.text, secondRow, actualRowId)
    }
  }

  else if (conditionOptionText1 === 'Spojena 1/3') {
    // handling first (Spojena 1/3) row
    if (currentRow) {
      let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
      handleChange('stransko_vodilo_desno-barva', selectedOption.text, currentRow, actualRowId)
    }

    // handling second (Spojena 2/3) row
    if (secondRow){
      let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
      handleChange('stransko_vodilo_desno-barva', selectedOption.text, secondRow, actualRowId)
    }

    // // handling third (Spojena 3/3) row
    if (thirdRow){
      // let thirdOption = fieldDetails && fieldDetails.options && fieldDetails.options.find(o => o.text === 'Spojena 3/3')
      let actualRowId = actualRows && actualRows[ind+2] && actualRows[ind+2].id
      handleChange('stransko_vodilo_desno-barva', selectedOption.text, thirdRow, actualRowId)
    }
  }
}