import React, { Component, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import LoginPage from './components/Login'
import SetPassword from './components/SetPassword'

import Orders from './components/Orders'
// import Order from './components/Orders/Order'
// import NewOrder from './components/Orders/New'
// import OrderEditor from './components/Orders/OrderEditor/index.js'
import OrdersRouter from './components/Orders/Router'

// import ProductsMenu from './components/ProductsMenu'
import ProductsMenu from './components/ProductsMenu'

import {AuthConsumer} from './AuthContext'
import Fade from '@material-ui/core/Fade'

import { withTranslation } from 'react-i18next';

import {OrdersProvider} from './store/orders/OrdersContext'
import {FormConfigProvider} from './store/formConfig/FormConfigContext'

const Logout = ({onLogout}) => {
  useEffect(() => {
    // console.log('onLogout...')
    onLogout()
  // }, [onLogout])
  })
  return null
}

// const renderMainLayout = (headerText, auth) => matchProps => (
//   <MainLayout
//     headerText={headerText}
//     matchProps={matchProps}
//     auth={auth}
//   />
// )

class App extends Component {

  componentDidMount () {
    document.title = this.props.t('Spletna Trgovina Sonal')
  }

  render () {

    return <AuthConsumer>
      {(auth) => {

        let {
          initialCheckInProgress,
          isLoggingIn,
          credentialsError, cancelCredentialsError,
          user,
          login,
          logout
        } = auth

        if (initialCheckInProgress)
          return null
        // if (isLoggingIn)
        //   return <LoadingSpinner style={{position: 'absolute'}}/>

        return <>
          {user ? (
            <Fade in={true}><span>
              <FormConfigProvider>
                <OrdersProvider>
                  <Switch>
                    <Route exact path="/" render={() => <Orders auth={auth} />} />
                    <Route path="/products" render={({match}) => <ProductsMenu auth={auth} match={match} />}/>
                    <Route path="/orders" render={() => <OrdersRouter auth={auth} />} />
                    <Route exact path="/logout" render={() => <Logout onLogout={logout} />} />
                    
                    {/* <Redirect to="/" /> */}
                    <Route render={({location}) => {
                      if (location.pathname !== '/set-password')
                        return <Redirect to="/" />
                    }}/>
                  </Switch>
                </OrdersProvider>
              </FormConfigProvider>
            </span></Fade>
          ):(
            <Switch>
              <Route exact path="/login"
                render={() => (
                  <LoginPage
                    onLoginClick={login}
                    isLoggingIn={isLoggingIn}
                    credentialsError={credentialsError}
                    cancelCredentialsError={cancelCredentialsError}
                  />
                )}
              />
              <Route render={({location}) => {
                if (location.pathname !== '/set-password')
                  return <Redirect to="/login" />
              }}/>
              
            </Switch>
          )}

          <Route exact path="/set-password" render={() => <SetPassword />} />
        </>

        // if (user) {
        //   return (
        //     <Fade in={true}><span>
        //       <FormConfigProvider>
        //         <OrdersProvider>
        //           <Switch>
        //             {/* <Route exact path="/" render={() => <div>Logged in {JSON.stringify(user)}</div>} /> */}
        //             {/* <Route exact path="/" component={Orders} /> */}

        //             <Route exact path="/" render={() => <Orders auth={auth} />} />
        //             {/* <Route exact path="/" render={(matchProps) => <MainLayout headerText="Vsa naročila" matchProps={matchProps} />} /> */}

        //             {/* <Route exact path="/orders/new" render={() => <NewOrder auth={auth} />}/> */}

        //             {/* <Route exact path="/products" component={ProductsMenu}/> */}
        //             <Route path="/products" render={({match}) => <ProductsMenu auth={auth} match={match} />}/>


        //             <Route path="/orders" render={() => <OrdersRouter auth={auth} />} />

        //             <Route exact path="/logout" render={() => <Logout onLogout={logout} />} />

        //             <Route exact path="/set-password" render={() => <SetPassword />} />

        //             <Redirect to="/" />
        //           </Switch>
        //         </OrdersProvider>
        //       </FormConfigProvider>
        //     </span></Fade>
        //   )
        // }
        // else {
        //   return (
        //     <Switch>
        //       <Route exact path="/login"
        //         render={() => (
        //           <LoginPage
        //             onLoginClick={login}
        //             isLoggingIn={isLoggingIn}
        //             credentialsError={credentialsError}
        //             cancelCredentialsError={cancelCredentialsError}
        //           />
        //         )}
        //       />
        //       <Route exact path="/set-password" render={() => <SetPassword />} />
        //       <Redirect to="/login" />
        //     </Switch>
        //   )
        // }
      }}
    </AuthConsumer>
  }
}

export default withTranslation()(App)