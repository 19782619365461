import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';

import OutlinedSelect from '../common/OutlinedSelect';
import OutlinedTextField from '../common/OutlinedTextField';
import OutlinedNumberField from '../common/OutlinedNumberField';
import CountingMultiSelect from '../common/CountingMultiSelect';

import actions from '../actions'
import orderService from '../../../../../services/order'
import {resetFieldsRecursively, getReadOnlyUpdates} from './rowUpdateHelpers'
import {arrayDiff} from './helpers'
import {getRowValues} from '../../../helpers'

import formConfigApi from '../../../../../services/api/formConfig'

// const normalizeFields = (fields) => ({
//   allNames: fields.map(field => field.name),
//   byName: fields.reduce((fieldsObject, field) => {
//     fieldsObject[field.name] = field
//     return fieldsObject
//   }, {})
// })

const updateFV = (...attrs) => {
  console.log('updateFV')
  return orderService.updateFieldValue(...attrs)
    .catch(() => {
      alert('Prišlo je do napake.')
    })
}

// let placeholderLabelColor = '#a2a2a2'
let placeholderLabelColor = '#b9b9b9'

// const OutlinedSelectMultiple = ({value, text, readOnly, name, disabled, classes, onChange, children, renderValue}) => {

//   return (
//     <FormControl
//       style={{
//         minWidth: '240px',
//         width: '100%',
//         opacity: disabled ? .25 : 1,
//         maxWidth: '630px'
//       }}
//       variant="outlined" 
//       // margin="dense"
//       // classes={{
//       //   focused: classes.invisible
//       // }}
//     >
//       <InputLabel
//         disableAnimation={true}
//         classes={{
//           root: classes.smallLabel,
//           focused: classes.invisible
//         }}
//       >
//         {
//           (Array.isArray(value) && value.length === 0)
//             ? text : ''
//         }
//       </InputLabel>

//       <Select
//         multiple
//         // renderValue={value => {
//         //   return `${value.length} selected: ` + value
//         // }}
//         renderValue={renderValue}
//         inputProps={{style:{padding: '5px'}, readOnly}}
//       // margin="dense"
//         value={value}
//         name={name}
//         disabled={disabled}
//         classes={{
//           icon: disabled ? classes.disabled : classes.icon,
//           // input: classes.smallPadding
//           // root,marginNormal,marginDense,fullWidth
//         }}
//         // placeholder="asdf"
//         input={<OutlinedInput
//           // placeholder="asdf"
//           labelWidth={0}
//           // style={{padding: '5px'}}
//           classes={{
//             input: classes.smallPadding
//           }}
//         />}
//         onChange={onChange}
//         MenuProps={{
//           transitionDuration: 100,
//           anchorOrigin: {
//             vertical: 'bottom',
//             horizontal: 'right',
//           },
//           transformOrigin: {
//             vertical: 'top',
//             horizontal: 'right',
//           },
//           getContentAnchorEl: null
//         }}
//       >
//         {children}
//       </Select>
//     </FormControl>
//   )
// }


const MultiselectValueRender = ({texts}) => (
  <>
    {/* <span style={{color: 'gray'}}>{`(${texts.length}) `}</span> {texts.join(', ')} */}
    {/* <span style={{color: 'gray'}}>{`Št. izbranih: ${texts.length} `}</span> {texts.join(', ')} */}
    <span style={{color: 'gray'}}>{`(Št. izbranih: ${texts.length}) `}</span> {texts.join(', ')}
    {/* <span style={{color: 'gray'}}>{`(${texts.length}x) `}</span> {texts.join(', ')} */}
  </>
)

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const executeRowActions = async ({rowActions, row, values, dispatch, fieldValuesIds, fields, beforeFirstCreatedValue, field, actualRowId, createdOrderId, history}) => {
  // console.log({actualRowId})

  // rowActions.allIds.forEach(async (fieldId) => {

  let allPromises = []
  let bfcvResult = null

  await asyncForEach(rowActions.allIds, async (fieldId) => {
    // await delay(50)

    let action = rowActions.byFieldId[fieldId]
    // console.log({action})
    if (action.type === 'reset'){

      let fieldValuesIds = row.valuesIdsByFieldsIds[fieldId]

      if (fieldValuesIds && fieldValuesIds.length > 0){
        fieldValuesIds.forEach(fieldValueId => {
          dispatch(actions.removeFieldValue(fieldId, row))

          let currentValueObj = values.byId[fieldValueId]
          if (currentValueObj && (currentValueObj.text || currentValueObj.number || currentValueObj.optionId)) {
            let p = orderService.removeFieldValue(fieldValueId)
            allPromises.push(p)
          }
        })
      }      
    }
    if (action.type === 'create'){

      // console.log({action})

      if (action.newValue){
        if (action.fieldType === 'NUMBER'){
          let newValueObj = {
            fieldId,
            type: 'NUMBER',
            number: action.newValue,
            customerOrderRowId: row.id
          }

          let {id, ...withoutId} = newValueObj
          orderService.createFieldValue(withoutId)
            .then((createdFieldValue) => {
              dispatch(actions.addFieldValue({...createdFieldValue, type: 'NUMBER' }, fieldId, row))
            })
          
        }
        else {

          let newValueObj = {
            type: 'SELECT_ONE',
            id: Math.random(),
            fieldId: fieldId,
            optionId: action.newValue,
            dirtyStatus: 'is-saving'  
          }
  
          // console.log(actions.selectOneCreateValue(fields.byId[fieldId], row, newValueObj))
          dispatch(actions.selectOneCreateValue(fields.byId[fieldId], row, newValueObj))
          // console.log(actions.selectOneCreateValue(fields.byId[fieldId], row, newValueObj))
          let {id, optionId, ...withoutOptionIdAndId} = newValueObj
  
          // console.log("1")
          // console.log("2")
  
          bfcvResult = bfcvResult || (await beforeFirstCreatedValue({redirectTimeout: null}))
          let {thisOrderId, thisRowId} = bfcvResult || {}
          // console.log("1")
          actualRowId = actualRowId || thisRowId
          createdOrderId = createdOrderId || thisOrderId
  
  
          let p = orderService.createFieldValue({...withoutOptionIdAndId, customerOrderRowId: (actualRowId || row.id), fieldOptionId: action.newValue})
            .then((createdFieldValue) => {
              if (!actualRowId)
                dispatch(actions.selectOneUpdateValueWithNewId({...createdFieldValue, type: field.type}, newValueObj.id, fieldId, row))
            })
  
          allPromises.push(p)
        }
      }
      else if (action.rule){
        // console.log(({action}))
        let currentField = fields.byId[action.rule.childFieldId]

        let valueObj = {
          id: Math.random(),
          number: currentField.type === 'NUMBER' ? action.rule.intValue : null,
          text: currentField.type === 'TEXT' ? action.rule.strValue : null,
          type: currentField.type,
          fieldId: currentField.id,
          dirtyStatus: 'is-saving'
        }
        // console.log({valueObj})
        dispatch(actions.addFieldValue(valueObj, fieldId, row))

        let {thisOrderId, thisRowId} = (await beforeFirstCreatedValue()) || {}
        actualRowId = actualRowId || thisRowId
        createdOrderId = createdOrderId || thisOrderId
        // console.log({actualRowId})

        let {id, ...withoutId} = valueObj
        let p = orderService.createFieldValue({...withoutId, customerOrderRowId: actualRowId || row.id})
          .then((createdFieldValue) => {
            if (!actualRowId)
              dispatch(actions.updateFieldValueWithNewId({...createdFieldValue, type: currentField.type}, valueObj.id, fieldId, row))
          })
        allPromises.push(p)
      }
    }
    else if(action.type === 'update'){

      if (action.fieldType === 'NUMBER'){
        if (fieldValuesIds && fieldValuesIds[0]) {

          let currentValueObj = values.byId[fieldValuesIds[0]]
          console.log('update', currentValueObj)

          if (currentValueObj){
            let newValueObj = {
              id: currentValueObj.id,
              fieldId: fieldId,
              type: 'NUMBER',
              number: action.newValue,
              customerOrderRowId: row.id
            }
  
            orderService.updateFieldValue(newValueObj)
              .then(() => {
                dispatch(actions.updateFieldValue({dirtyStatus: null, number: action.newValue, localValue: null}, fieldId, row))
              })
          }
        }
      }
      else {
        let actionFieldValuesIds = row.valuesIdsByFieldsIds[fieldId]
      
        if (action.newValue){

          // console.log(action.selectedOption)
  
          // dispatch(actions.selectOneUpdateValue(action.newValue, fieldId, row, 'is-saving'))
          dispatch(actions.updateFieldValue({
            optionId: action.newValue,
            dirtyStatus: 'is-saving',
            ...((action.selectedOption && action.selectedOption.text) ? {text: action.selectedOption.text} : {})
          }, fieldId, row))
  
          if (actionFieldValuesIds && actionFieldValuesIds[0]){
            let currentValueObj = values.byId[actionFieldValuesIds[0]]
  
            let newValueObj = {
              ...currentValueObj,
              customerOrderRowId: row.id,
              fieldOptionId: action.newValue
            }
  
            let p = orderService.updateFieldValue(newValueObj)
              .then(() => dispatch(actions.updateFieldValue({dirtyStatus: null}, fieldId, row)))
            allPromises.push(p)
          }
        }
        else if (action.rule){
          if (actionFieldValuesIds && actionFieldValuesIds[0]){
            // console.log({actionFieldValuesIds})
            // let currentValueObj = values.byId[action.rule.childFieldId]
            let currentValueObj = values.byId[actionFieldValuesIds[0]]
  
            // console.log({rrr: action.rule, currentValueObj})
            let currentField = fields.byId[fieldId]
            let newValueObj = {
              id: currentValueObj.id,
              fieldId: currentValueObj.fieldId,
              type: currentField.type,
              number: currentField.type === 'NUMBER' ? action.rule.intValue : null,
              text: currentField.type === 'TEXT' ? action.rule.strValue : null,
              customerOrderRowId: row.id
            }
  
            dispatch(actions.updateFieldValue({
              dirtyStatus: 'is-saving',
              number: currentField.type === 'NUMBER' ? action.rule.intValue : null,
              text: currentField.type === 'TEXT' ? action.rule.strValue : null,
            }, currentValueObj.fieldId, row))
            
            if (currentValueObj.text){
              let p = orderService.updateFieldValue(newValueObj)
                .then(() => dispatch(actions.updateFieldValue({dirtyStatus: null, text: action.rule.strValue, localValue: null}, field.id, row)))
              allPromises.push(p)
            }
            else if (currentValueObj.number){
              let p = orderService.updateFieldValue(newValueObj)
                .then(() => dispatch(actions.updateFieldValue({dirtyStatus: null, number: action.rule.intValue, localValue: null}, field.id, row)))
              allPromises.push(p)
            }
          }
        }
      }      
    }
  })

  // await Promise.all(allPromises)
  // return bfcvResult

  // Promise.all(allPromises)
  return bfcvResult


    // .then((values) => {
    //   // console.log('All done', createdOrderId)
    //   if (createdOrderId)
    //     history.replace(`/orders/${createdOrderId}/edit`)
    // })
}

const Row = ({
  fields, allOptions, allRules, fieldsToRender,
  tableRowClasses, isSelected, tableCellClasses, number, classes,
  row, values, dispatch, beforeFirstCreatedValue,
  setPopperAllowed, handlePopperClose, handlePopperOpen,
  withError, fieldDetailsByFieldId, editingDisabled, orderLoadStatus,
  handleSpecialChangeCases = () => {},
  rowsWithRenderDetails,
  history,
  // addRows
}) => {

  // console.log({orderLoadStatus})

  const orderLoadedCallbackRef = React.useRef(null);

  React.useEffect(() => {
    if (orderLoadStatus === 'loaded') {
      if (orderLoadedCallbackRef.current) {
        orderLoadedCallbackRef.current()
      }
    }
  }, [orderLoadStatus])

  const rowsWithRenderDetailsRef = React.useRef(rowsWithRenderDetails);
  React.useEffect(() => {
    // console.log('rowsWithRenderDetailsRef update')
    rowsWithRenderDetailsRef.current = rowsWithRenderDetails
  }, [rowsWithRenderDetails])


  // let [rowValues, setRowValues] = useState(getInitialRowValues(row, values, fields))

  // console.log({row, values})

  // let {rowValues, rowValuesObjects} = getInitialRowValues(row, values, fields) 
  let {rowValues, rowValuesObjects} = getRowValues(row, values, fields) 
  // console.log({rowValues, rowValuesObjects})

  // let backgroundColor = isSelected ? '#ffe9d6' : 'initial'

  let backgroundColor = isSelected ? '#ffe9d6' :
    withError ? 'rgb(255, 211, 211)' : 'initial'
    // withError ? '#ffe6e6' : 'initial'

    // console.log({row})

  return (
    <TableRow className={tableRowClasses} style={{
      backgroundColor: backgroundColor
    }}>
      <TableCell key={"number"} className={tableCellClasses}>
        <Typography className={'row-counter'} style={{padding: '5px 10px'}}  >{" " + number + " "}</Typography>
        {/* <Typography className={'row-counter'} style={{padding: '5px 10px', whiteSpace: 'nowrap'}}  >{" " + row.orderNo + " (" + row.id + ") "}</Typography> */}
      </TableCell>


      {fieldsToRender.map((field, ind, allFieldsToRender) => {

        // let isEnabled = field.alwaysEnabled
        // let isReadonly = false

        // let defaultOptions = field.optionsIds && field.optionsIds
        //   .map(optionsId => allOptions.byId[optionsId])

        // let filteredOptions = null

        // if (!isEnabled){
        //   if (field.parentRulesIds) {
        //     // console.log('Mera "c" rules:')
        //     field.parentRulesIds.forEach(ruleId => {
        //       let rule = allRules.byId[ruleId]
        //       if (rule.parentOptionFieldId){
        //         let parentOption = allOptions.byId[rule.parentOptionFieldId]
        //         if (parentOption && parentOption.id === rowValues[parentOption.fieldId]){
        //           isEnabled = true

        //           if (rule.readonly && (rule.intValue || rule.strValue))
        //             isReadonly = true

        //           // ToDo: ? 
        //           // if (field.text === 'Mera "c"' && rule.readonly){
        //           //   // console.log('OK', {rule}, {parentOption})
        //           //   readonlyValue = rule.intValue || rule.strValue
        //           // }
        //         }
        //       }
        //     })
        //   }
          
        //   if (!isEnabled){
        //     filteredOptions = defaultOptions && 
        //       defaultOptions.filter(option => {
        //         return option.parentRulesIds && option.parentRulesIds.some(ruleId => {
        //           let rule = allRules.byId[ruleId]
        //           if (rule && rule.parentOptionFieldId) {
        //             let parentOption = allOptions.byId[rule.parentOptionFieldId]

        //             if (parentOption && parentOption.id === rowValues[parentOption.fieldId])
        //               return true
        //           }
        //           return false
        //         })
        //       })

        //     if (filteredOptions && filteredOptions.length > 0)
        //       isEnabled = true
        //   }
        // }

        let value = rowValues[field.id]
        let valueObj = rowValuesObjects[field.id]

        // console.log({rowValuesObjects})

        let {
          isEnabled,
          isReadonly,
          options
        } = fieldDetailsByFieldId[field.id] || {}

        if (editingDisabled)
          isReadonly = true

        // console.log({row})
        // options && console.log({options})

        // console.log(fieldDetailsByFieldId[field.id])
        // if (field.text === 'Zgornji profil / prilagoditev LEVO')
        // if (field.text === 'Zgornji profil / prilagoditev DESNO')
        // if (field.text.includes('Zgornji profil / prilagoditev LEVO '))
        //   console.log(field)


        // let {
        //   isEnabled,
        //   isReadonly,
        //   options
        // } = getFieldRenderDetails({field, allOptions, allRules, rowValues, valueObj})

        if (field.name === 'dolzina_levi_del') {
          let conditionField = allFieldsToRender.find(f => f.name === 'dolzina_maske')
          // console.log({conditionField})
          if (conditionField) {
            let conditionValue = rowValues[conditionField.id]
            if (conditionValue > 3000)
              isEnabled = true
          }
        }

        else if (field.name === 'dolzina_desni_del') {
          let conditionField = allFieldsToRender.find(f => f.name === 'dolzina_maske')
          // console.log({conditionField})
          if (conditionField) {
            let conditionValue = rowValues[conditionField.id]
            if (conditionValue > 3000)
              isEnabled = true
          }
        }
    
        // console.log({field})

        return (
          <TableCell key={ind} className={tableCellClasses}>
            {renderField({
              // htmlId: `${number}-${field.name}`,
              valueObj,
              htmlId: `${field.name}`,
              id: field.id,
              name: field.name, 
              text: field.text,
              type: field.type,
              rowValuesObjects, field,
              isEnabled, 
              value,
              defaultInt: field.defaultInt,
              readOnly: isReadonly,
              orderLoadStatus,
              min: field.min,
              max: field.max,
              // readOnly: Boolean(readonlyValue) ,

              saveDefaultNumberValue: (newValue) => {
                // console.log('saveDefaultNumberValue', newValue)

                if (field.type === 'NUMBER'){

                  let newValueObj = {
                    fieldId: field.id,
                    type: field.type,
                    number: field.type === 'NUMBER' ? newValue : null,
                    text: field.type === 'TEXT' ? newValue : null,
                    customerOrderRowId: row.id
                  }

                  newValueObj.customerOrderRowId = row.id

                  orderService.createFieldValue(newValueObj)
                    .then((createdFieldValue) => {
                      // console.log({createdFieldValue})
                        dispatch(actions.addFieldValue({...createdFieldValue, type: field.type}, field.id, row))
                    })
                }
              },
              
              setLocalValue: (newValue = '') => {
                // console.log({newValue})
                if (newValue == null)
                  newValue = ''

                // if (field.type === 'NUMBER') {
                //   if (newValue > 3000)
                //     return 
                // }
  
                if (field.type === 'NUMBER' || field.type === 'TEXT'){
                  if (field.name === 'dolzina_maske') { 
                    if (newValue > 6000)
                      return null
                  }
                  if (field.name === 'dolzina_levi_del') { 
                    if (newValue > 3000)
                      return null
                  }
                  if (field.name === 'dolzina_desni_del') { 
                    if (newValue > 3000)
                      return null
                  }

                  let fieldValuesIds = row.valuesIdsByFieldsIds[field.id]
                  if (fieldValuesIds && fieldValuesIds.length > 0){
                    let updateData = {
                      localValue: newValue,
                      dirtyStatus: 'local'
                    }
                    // console.log({updateData})
                    dispatch(actions.updateFieldValue(updateData, field.id, row))
                  }
                  else {
                    let valueObj = {
                      id: Math.random(),
                      localValue: newValue,
                      type: field.type,
                      fieldId: field.id,
                      dirtyStatus: 'local'
                    }
                    dispatch(actions.addFieldValue(valueObj, field.id, row))
                  }
                }
              },
              setNewValue: async (newValue) => {

                

                // console.log({newValue})
                
                // let actualRowId = actualRowId || await beforeFirstCreatedValue({redirectTimeout: null})
                // let actualRowId = actualRowId || (await beforeFirstCreatedValue({redirectTimeout: null})).thisRowId
                let actualRowId = null
                let createdOrderId = null
                // console.log({actualRowId})
                // return null

                let fieldValuesIds = row.valuesIdsByFieldsIds[field.id]

                // console.log({row})

                if (field.type === 'NUMBER' || field.type === 'TEXT'){
                  if (newValue !== ''){
                    if (fieldValuesIds && fieldValuesIds.length === 1){

                      let currentValueObj = values.byId[fieldValuesIds[0]]
                      // console.log({currentValueObj})

                      if (currentValueObj.localValue === undefined || currentValueObj.localValue === null)
                        return null
                      
                      if (
                        (field.type === 'NUMBER' && (currentValueObj.localValue !== currentValueObj.number))
                        || (field.type === 'TEXT' && (currentValueObj.localValue !== currentValueObj.text))
                      ) {


                        if (['dolzina_levi_del', 'dolzina_desni_del'].includes(field.name)) { 
                          if (newValue > 3000)
                            return null

                          let sourceField = allFieldsToRender.find(field => field.name === 'dolzina_maske')
                          let valueObject = rowValuesObjects[sourceField.id]
                          if (valueObject.number !== undefined) {
                            if (newValue < (valueObject.number - 3000))
                              newValue = valueObject.number - 3000
                          }
                        }

                        let newValueObj = {
                          id: currentValueObj.id,
                          fieldId: currentValueObj.fieldId,
                          type: field.type,
                          number: field.type === 'NUMBER' ? newValue : null,
                          text: field.type === 'TEXT' ? newValue : null,
                          customerOrderRowId: row.id
                        }
  
                        dispatch(actions.updateFieldValue({dirtyStatus: 'is-saving'}, field.id, row))

                        // console.log({currentValueObj})
                        
                        // if already saved value on the server
                        if (currentValueObj.text){
                          updateFV(newValueObj)
                            .then(() => dispatch(actions.updateFieldValue({dirtyStatus: null, text: newValue, localValue: null}, field.id, row)))
                        }
                        else if (currentValueObj.number || currentValueObj.number === 0){
                          updateFV(newValueObj)
                            .then(() => dispatch(actions.updateFieldValue({dirtyStatus: null, number: newValue, localValue: null}, field.id, row)))
                        }
                        // without server saved value
                        else {
                          let {thisOrderId, thisRowId} = (await beforeFirstCreatedValue()) || {}
                          actualRowId = actualRowId || thisRowId
                          createdOrderId = createdOrderId || thisOrderId

                          if (actualRowId)
                            newValueObj.customerOrderRowId = actualRowId
                            
                          let {id, ...withoutId} = newValueObj

                          orderService.createFieldValue(withoutId)
                            .then((createdFieldValue) => {
                              // console.log({createdFieldValue})
                              if (!actualRowId)
                                dispatch(actions.updateFieldValueWithNewId({...createdFieldValue, type: field.type}, currentValueObj.id, field.id, row))
                            })
                        }
                      }
                    }
                  }
                  else {
                    if (fieldValuesIds && fieldValuesIds.length === 1){
                      dispatch(actions.removeFieldValue(field.id, row))
                      let currentValueObj = values.byId[fieldValuesIds[0]]
                      if (currentValueObj && (currentValueObj.text || currentValueObj.number))
                        orderService.removeFieldValue(fieldValuesIds[0])
                    }
                  }

                  if (field.name === 'dolzina_maske') {
                    let targetField1 = allFieldsToRender.find(field => field.name === 'dolzina_levi_del')
                    let targetField2 = allFieldsToRender.find(field => field.name === 'dolzina_desni_del')

                    let targetField1Id = targetField1 && targetField1.id
                    let targetField2Id = targetField2 && targetField2.id

                    if (targetField1Id && targetField2Id) {
                      let rowActions = {byFieldId: {
                        [targetField1Id]: {type: 'reset'},
                        [targetField2Id]: {type: 'reset'}
                      }, allIds: [targetField1Id, targetField2Id]}
  
                      await executeRowActions({rowActions, row, values, dispatch, fieldValuesIds, fields, beforeFirstCreatedValue, field, actualRowId, createdOrderId, history})
                    }
                  }

                  if (field.name === 'dolzina_levi_del' && newValue) {
                    let sourceField = allFieldsToRender.find(field => field.name === 'dolzina_maske')
                    let targetField = allFieldsToRender.find(field => field.name === 'dolzina_desni_del')

                    if (sourceField && targetField) {
                      let valueObject = rowValuesObjects[sourceField.id]
                      if (valueObject.number > Number(newValue)) {

                        let rowActions = {byFieldId: {}, allIds: [targetField.id]}

                        let fieldValuesIds = row.valuesIdsByFieldsIds[targetField.id]
                        console.log('executeRowActions', fieldValuesIds)
  
                        if (fieldValuesIds && fieldValuesIds.length > 0){
                          rowActions.byFieldId[targetField.id] = {type: 'update', newValue: valueObject.number - Number(newValue), fieldType: 'NUMBER'}
                        }
                        else
                          rowActions.byFieldId[targetField.id] = {type: 'create', newValue: valueObject.number - Number(newValue), fieldType: 'NUMBER'}
                        await executeRowActions({rowActions, row, values, dispatch, fieldValuesIds, fields, beforeFirstCreatedValue, field, actualRowId, createdOrderId, history})
                        
                      }
                    }
                  }

                  if (field.name === 'dolzina_desni_del' && newValue) {
                    let sourceField = allFieldsToRender.find(field => field.name === 'dolzina_maske')
                    let targetField = allFieldsToRender.find(field => field.name === 'dolzina_levi_del')

                    if (sourceField && targetField) {
                      let valueObject = rowValuesObjects[sourceField.id]
                      if (valueObject.number > Number(newValue)) {

                        let rowActions = {byFieldId: {}, allIds: [targetField.id]}

                        let fieldValuesIds = row.valuesIdsByFieldsIds[targetField.id]
                        // console.log('executeRowActions', fieldValuesIds)
  
                        if (fieldValuesIds && fieldValuesIds.length > 0){
                          rowActions.byFieldId[targetField.id] = {type: 'update', newValue: valueObject.number - Number(newValue), fieldType: 'NUMBER'}
                        }
                        else
                          rowActions.byFieldId[targetField.id] = {type: 'create', newValue: valueObject.number - Number(newValue), fieldType: 'NUMBER'}
                        await executeRowActions({rowActions, row, values, dispatch, fieldValuesIds, fields, beforeFirstCreatedValue, field, actualRowId, createdOrderId, history})
                        
                      }
                    }
                  }
                }
                else if (field.type === 'SELECT_ONE'){

                  // console.log(field.name)

                  // if (field.name === 'spajanje_zaluzije') {
                  //   let {thisOrderId, thisRowId, createdOrder, rows} = (await beforeFirstCreatedValue({redirectTimeout: null})) || {}
                  //   actualRowId = actualRowId || thisRowId
                  //   createdOrderId = createdOrderId || thisOrderId
                  //   await handleSpecialChangeCases({field, newValueOptionId: newValue, allFieldsToRender, createdOrderId, actualRows: rows})
                  // }

                  //  ======================
                  //  Row actions
                  //  ======================

                  let rowActions = {byFieldId: {}, allIds: []}

                  // reset actions
                  let fieldsIdsToReset = resetFieldsRecursively(field.id, rowValuesObjects, allOptions, allRules)
                  // let fieldsIdsToReset = []
                  fieldsIdsToReset.forEach(fieldId => {
                    rowActions.allIds.push(fieldId)
                    rowActions.byFieldId[fieldId] = {type: 'reset'}
                  })
                  // console.log(rowActions.byFieldId)
                  // console.log(JSON.stringify(rowActions.byFieldId))
                  // return

                  let selectedOption = allOptions.byId[newValue]

                  // read only update actions
                  let readOnlyUpdates = getReadOnlyUpdates(field.id, rowValuesObjects, allOptions, allRules, newValue)
                  readOnlyUpdates.forEach(rule => {
                    if (!rowActions.byFieldId[rule.childFieldId]){
                      rowActions.allIds.push(rule.childFieldId)
                    }

                    let ruleValueIds = row.valuesIdsByFieldsIds[rule.childFieldId]
                    if (ruleValueIds && ruleValueIds.length > 0){
                      rowActions.byFieldId[rule.childFieldId] = {type: 'update', rule}
                    }
                    else
                      rowActions.byFieldId[rule.childFieldId] = {type: 'create', rule}
                  })

                  // create & update actions
                  if (!rowActions.byFieldId[field.id]){
                    rowActions.allIds.push(field.id)
                  }

                  if (fieldValuesIds && fieldValuesIds.length > 0){
                    rowActions.byFieldId[field.id] = {type: 'update', newValue, selectedOption}
                  }
                  else
                    rowActions.byFieldId[field.id] = {type: 'create', newValue}

                  // console.log({rowActions: rowActions.byFieldId})
                  // console.log({rowActions})

                  //  ======================
                  //  Special cases
                  //  ======================

                  if (field.name === 'pogonska_palica-barva_kardanskega_prevodnika'){    
                    
                    let targetField1 = allFieldsToRender.find(field => field.name === 'pogonska_palica-barva_zglobne_rocice')
                    let targetField2 = allFieldsToRender.find(field => field.name === 'pogonska_palica-barva_cevi_palice')

                    let targetField1Id = targetField1 && targetField1.id
                    let targetField2Id = targetField2 && targetField2.id

                    let targetOption1Id = selectedOption && allOptions.allIds.find(oId => {
                      let optionObj = allOptions.byId[oId]
                      return (optionObj && optionObj.fieldId === targetField1Id && optionObj.value === selectedOption.value)
                    })

                    let targetOption2Id = selectedOption && allOptions.allIds.find(oId => {
                      let optionObj = allOptions.byId[oId]
                      if (optionObj && optionObj.fieldId === targetField2Id) {
                        let optionsTextsAreEqual = optionObj.value === selectedOption.value
                        let silverColorMatch = optionObj && optionObj.text === 'Srebrna (RAL 9006)' && selectedOption.text === 'Nikljana (siva)'
                        return optionsTextsAreEqual || silverColorMatch
                      }
                    })

                    if (targetOption1Id && targetField1Id) {
                      if (!rowActions.byFieldId[targetField1Id]){
                        rowActions.allIds.push(targetField1Id)
  
                        let fieldValuesIds = row.valuesIdsByFieldsIds[targetField1Id]
  
                        if (fieldValuesIds && fieldValuesIds.length > 0){
                          let optionObj = allOptions.byId[targetOption1Id]
                          rowActions.byFieldId[targetField1Id] = {type: 'update', newValue: targetOption1Id, selectedOption: optionObj}
                        }
                        else
                          rowActions.byFieldId[targetField1Id] = {type: 'create', newValue: targetOption1Id}
                      }
                    }

                    if (targetOption2Id && targetField2Id) {
                      if (!rowActions.byFieldId[targetField2Id]){
                        rowActions.allIds.push(targetField2Id)
  
                        let fieldValuesIds = row.valuesIdsByFieldsIds[targetField2Id]
  
                        if (fieldValuesIds && fieldValuesIds.length > 0){
                          let optionObj = allOptions.byId[targetOption2Id]
                          rowActions.byFieldId[targetField2Id] = {type: 'update', newValue: targetOption2Id, selectedOption: optionObj}
                        }
                        else
                          rowActions.byFieldId[targetField2Id] = {type: 'create', newValue: targetOption2Id}
                      }
                    }
                  }
                  
                  // if (['spajanje_zaluzije'].includes(field.name)){
                  //   let actionFieldId1 = fields.allIds.find(id => {
                  //     let field = fields.byId[id]
                  //     return (field.name === 'stransko_vodilo_levo')
                  //   })
                  //   let actionFieldId2 = fields.allIds.find(id => {
                  //     let field = fields.byId[id]
                  //     return (field.name === 'stransko_vodilo_desno')
                  //   })
                  //   // console.log({actionFieldId1})

                  //   // ignore resetting of field "stransko_vodilo_levo" & "stransko_vodilo_desno"
                  //   if (rowActions.allIds.includes(actionFieldId1)) {
                  //     if (rowActions.byFieldId[actionFieldId1].type === 'reset') {
                  //       rowActions.allIds = rowActions.allIds.filter(id => id !== actionFieldId1)
                  //       delete rowActions.byFieldId[actionFieldId1]
                  //     }
                  //   }
                  //   if (rowActions.allIds.includes(actionFieldId2)) {
                  //     if (rowActions.byFieldId[actionFieldId2].type === 'reset') {
                  //       rowActions.allIds = rowActions.allIds.filter(id => id !== actionFieldId2)
                  //       delete rowActions.byFieldId[actionFieldId2]
                  //     }
                  //   }
                  // }

                  // if (['spajanje_zaluzije'].includes(field.name) && selectedOption && selectedOption.text === 'Samostojna'){

                  //   let actionFieldId1 = fields.allIds.find(id => {
                  //     let field = fields.byId[id]
                  //     return (field.name === 'stransko_vodilo_levo')
                  //   })
                  //   let actionFieldId2 = fields.allIds.find(id => {
                  //     let field = fields.byId[id]
                  //     return (field.name === 'stransko_vodilo_desno')
                  //   })
                  //   // console.log({actionFieldId1})

                  //   // ignore resetting of field "stransko_vodilo_levo" & "stransko_vodilo_desno"
                  //   if (rowActions.allIds.includes(actionFieldId1)) {
                  //     if (rowActions.byFieldId[actionFieldId1].type === 'reset') {
                  //       rowActions.allIds = rowActions.allIds.filter(id => id !== actionFieldId1)
                  //       delete rowActions.byFieldId[actionFieldId1]
                  //     }
                  //   }
                  //   if (rowActions.allIds.includes(actionFieldId2)) {
                  //     if (rowActions.byFieldId[actionFieldId2].type === 'reset') {
                  //       rowActions.allIds = rowActions.allIds.filter(id => id !== actionFieldId2)
                  //       delete rowActions.byFieldId[actionFieldId2]
                  //     }
                  //   }
                    
                  //   // id of 'enojno_vodilo'
                  //   let actionNewValue1 = allOptions.allIds.find(oId => {
                  //     let optionObj = allOptions.byId[oId]
                  //     // console.log({optionObj})
                  //     return optionObj.value === 'enojno_vodilo' && optionObj.fieldId === actionFieldId1
                  //   })

                  //   if (actionFieldId1 && actionNewValue1) {
                  //     if (!rowActions.byFieldId[actionFieldId1]){
                  //       // rowActions.allIds.push(field.id)
                  //       rowActions.allIds.push(actionFieldId1)
  
                  //       let fieldValuesIds = row.valuesIdsByFieldsIds[actionFieldId1]
  
                  //       if (fieldValuesIds && fieldValuesIds.length > 0){
                  //         rowActions.byFieldId[actionFieldId1] = {type: 'update', newValue: actionNewValue1}
                  //       }
                  //       else
                  //         rowActions.byFieldId[actionFieldId1] = {type: 'create', newValue: actionNewValue1}
                  //     }
                  //   }

                  //   let actionNewValue2 = allOptions.allIds.find(oId => {
                  //     let optionObj = allOptions.byId[oId]
                  //     // console.log({optionObj})
                  //     return optionObj.value === 'enojno_vodilo' && optionObj.fieldId === actionFieldId2
                  //   })

                  //   if (actionFieldId1 && actionNewValue2) {
                  //     if (!rowActions.byFieldId[actionFieldId2]){
                  //       // rowActions.allIds.push(field.id)
                  //       rowActions.allIds.push(actionFieldId2)
  
                  //       let fieldValuesIds = row.valuesIdsByFieldsIds[actionFieldId2]
  
                  //       if (fieldValuesIds && fieldValuesIds.length > 0){
                  //         rowActions.byFieldId[actionFieldId2] = {type: 'update', newValue: actionNewValue2}
                  //       }
                  //       else
                  //         rowActions.byFieldId[actionFieldId2] = {type: 'create', newValue: actionNewValue2}
                  //     }
                  //   }
                  // }

                  //  copying 'Stransko vodilo LEVO' to 'Stransko vodilo DESNO'
                  // if (field.name === 'stransko_vodilo_levo') {
                  //   let conditionFieldId = fields.allIds.find(id => {
                  //     let field = fields.byId[id]
                  //     return (field.name === 'spajanje_zaluzije')
                  //   })

                  //   let conditionFieldSelectedOptionId = rowValues[conditionFieldId]
                  //   let conditionFieldSelectedOption = conditionFieldSelectedOptionId && allOptions.byId[conditionFieldSelectedOptionId]

                  //   if (conditionFieldSelectedOption && conditionFieldSelectedOption.text === 'Samostojna') { // ?
                  //     let targetFieldId = fields.allIds.find(id => {
                  //       let field = fields.byId[id]
                  //       return field.name === 'stransko_vodilo_desno'
                  //     })

                  //     let targetOptionId = selectedOption && allOptions.allIds.find(oId => {
                  //       let optionObj = allOptions.byId[oId]
                  //       return (optionObj && optionObj.fieldId === targetFieldId && optionObj.value === selectedOption.value)
                  //     })

                  //     if (targetOptionId && targetFieldId) {
                  //       if (!rowActions.byFieldId[targetFieldId]){
                  //         rowActions.allIds.push(targetFieldId)
    
                  //         let fieldValuesIds = row.valuesIdsByFieldsIds[targetFieldId]
    
                  //         if (fieldValuesIds && fieldValuesIds.length > 0){
                  //           rowActions.byFieldId[targetFieldId] = {type: 'update', newValue: targetOptionId}
                  //         }
                  //         else
                  //           rowActions.byFieldId[targetFieldId] = {type: 'create', newValue: targetOptionId}
                  //       }
                  //     }
                  //   }
                  // }

                                      //  copying 'stransko_vodilo_levo-barva' to 'stransko_vodilo_desno-barva'
                                      //  and 'stransko_vodilo_levo-distancnik' to 'stransko_vodilo_desno-distancnik'
                                      // if (['stransko_vodilo_levo-barva', 'stransko_vodilo_levo-distancnik'].includes(field.name)){

                                      //   let copyFieldTo = (targetFieldName) => {
                                      //     let conditionFieldId = fields.allIds.find(id => {
                                      //       let field = fields.byId[id]
                                      //       return (field.name === 'stransko_vodilo_levo')
                                      //     })

                                      //     let conditionFieldSelectedOptionId = rowValues[conditionFieldId]
                                      //     let conditionFieldSelectedOption = conditionFieldSelectedOptionId && allOptions.byId[conditionFieldSelectedOptionId]

                                      //     if (conditionFieldSelectedOption && ['enojno_vodilo', 'MultiBox_1-1', 'MultiBox_1-1-1', 'Multibox_1-1-2', 'MultiBox_1-1-3'].includes(conditionFieldSelectedOption.value)) {
                      
                                      //       let targetFieldId = fields.allIds.find(id => {
                                      //         let field = fields.byId[id]
                                      //         return targetFieldName === field.name
                                      //       })
                      
                                      //       let targetOptionId = selectedOption && allOptions.allIds.find(oId => {
                                      //         let optionObj = allOptions.byId[oId]
                                      //         return (optionObj && optionObj.fieldId === targetFieldId && optionObj.value === selectedOption.value)
                                      //       })
                      
                                      //       if (targetOptionId && targetFieldId) {
                                      //         if (!rowActions.byFieldId[targetFieldId]){
                                      //           rowActions.allIds.push(targetFieldId)
                          
                                      //           let fieldValuesIds = row.valuesIdsByFieldsIds[targetFieldId]
                          
                                      //           if (fieldValuesIds && fieldValuesIds.length > 0){
                                      //             rowActions.byFieldId[targetFieldId] = {type: 'update', newValue: targetOptionId}
                                      //           }
                                      //           else
                                      //             rowActions.byFieldId[targetFieldId] = {type: 'create', newValue: targetOptionId}
                                      //         }
                                      //       }
                                      //     }
                                      //   }

                                      //   let conditionFieldId = fields.allIds.find(id => {
                                      //     let field = fields.byId[id]
                                      //     return (field.name === 'spajanje_zaluzije')
                                      //   })

                                      //   let conditionFieldSelectedOptionId = rowValues[conditionFieldId]
                                      //   let conditionFieldSelectedOption = conditionFieldSelectedOptionId && allOptions.byId[conditionFieldSelectedOptionId]
                                      //   if (conditionFieldSelectedOption.text === "Samostojna") { // ?
                                      //     if ('stransko_vodilo_levo-barva' === field.name){
                                      //       copyFieldTo('stransko_vodilo_desno-barva')
                                      //     }
                                      //     if ('stransko_vodilo_levo-distancnik' === field.name){
                                      //       copyFieldTo('stransko_vodilo_desno-distancnik')
                                      //     }
                                      //   }
                                      // }

                  if (field.name === 'vrsta_pogona' && selectedOption.value === 'elektromotorni'){
                    
                    let targetFieldId = fields.allIds.find(id => {
                      let field = fields.byId[id]
                      return field.name === 'pozicija_vgradnje-stran_pogona'
                    })

                    // ignore resetting of field "pozicija_vgradnje-stran_pogona"
                    if (rowActions.allIds.includes(targetFieldId)) {
                      if (rowActions.byFieldId[targetFieldId].type === 'reset') {
                        rowActions.allIds = rowActions.allIds.filter(id => id !== targetFieldId)
                        delete rowActions.byFieldId[targetFieldId]
                      }
                    }

                    let targetOptionId = selectedOption && allOptions.allIds.find(oId => {
                      let optionObj = allOptions.byId[oId]
                      return (optionObj && optionObj.fieldId === targetFieldId && optionObj.value === 'sredina')
                    })

                    if (targetOptionId && targetFieldId) {
                      if (!rowActions.byFieldId[targetFieldId]){
                        rowActions.allIds.push(targetFieldId)
  
                        let fieldValuesIds = row.valuesIdsByFieldsIds[targetFieldId]
  
                        if (fieldValuesIds && fieldValuesIds.length > 0){
                          let optionObj = allOptions.byId[targetOptionId]
                          rowActions.byFieldId[targetFieldId] = {type: 'update', newValue: targetOptionId, selectedOption: optionObj}
                        }
                        else
                          rowActions.byFieldId[targetFieldId] = {type: 'create', newValue: targetOptionId}
                      }
                    }
                  }

                  if (field.name === 'barva_lamele'){

                    // console.log({allFieldsToRender})
                    
                    // let targetFieldId = fields.allIds.find(id => {
                    //   let field = fields.byId[id]
                    //   return field.name === 'barva_spodnjega_profila'   // barva_spodnjega_profila_2
                    // })
                    let targetField = allFieldsToRender.find(field => {
                      return ['barva_spodnjega_profila', 'barva_spodnjega_profila_2'].includes(field.name)
                    })

                    let targetFieldId = targetField && targetField.id

                    let targetOptionId = selectedOption && allOptions.allIds.find(oId => {
                      let optionObj = allOptions.byId[oId]
                      return (optionObj && optionObj.fieldId === targetFieldId && optionObj.value === selectedOption.value)
                    })

                    if (targetOptionId && targetFieldId) {
                      if (!rowActions.byFieldId[targetFieldId]){
                        rowActions.allIds.push(targetFieldId)
  
                        let fieldValuesIds = row.valuesIdsByFieldsIds[targetFieldId]
  
                        if (fieldValuesIds && fieldValuesIds.length > 0){
                          let optionObj = allOptions.byId[targetOptionId]
                          rowActions.byFieldId[targetFieldId] = {type: 'update', newValue: targetOptionId, selectedOption: optionObj}
                        }
                        else
                          rowActions.byFieldId[targetFieldId] = {type: 'create', newValue: targetOptionId}
                      }
                    }
                  }

                  // ignore reseting of some field
                  // if (['spajanje_zaluzije', 'stransko_vodilo_levo'].includes(field.name)){
                  if (['spajanje_zaluzije', 'stransko_vodilo_levo', 'stransko_vodilo_levo-barva', 'stransko_vodilo_levo-distancnik', 'stransko_vodilo_desno', 'stransko_vodilo_desno-barva', 'stransko_vodilo_desno-distancnik'].includes(field.name)){

                    let ignoreReset = (fieldName) => {

                      let fieldId = fields.allIds.find(id => {
                        let field = fields.byId[id]
                        return (field.name === fieldName)
                      })

                      if (fieldId && rowActions.allIds.includes(fieldId)) {
                        if (rowActions.byFieldId[fieldId].type === 'reset') {
                          rowActions.allIds = rowActions.allIds.filter(id => id !== fieldId)
                          delete rowActions.byFieldId[fieldId]
                        }
                      }
                    }

                    ignoreReset('stransko_vodilo_levo')
                    ignoreReset('stransko_vodilo_levo-barva')
                    ignoreReset('stransko_vodilo_levo-distancnik')
                    ignoreReset('stransko_vodilo_desno')
                    ignoreReset('stransko_vodilo_desno-barva')
                    ignoreReset('stransko_vodilo_desno-distancnik')

                  }

                  // await executeRowActions({rowActions, row, values, dispatch, fieldValuesIds, fields, beforeFirstCreatedValue, field, actualRowId, createdOrderId, history})

                  let bfcvResult = await executeRowActions({rowActions, row, values, dispatch, fieldValuesIds, fields, beforeFirstCreatedValue, field, actualRowId, createdOrderId, history})
                  // console.log('executeRowActions done', bfcvResult)

                  let {thisOrderId, thisRowId, createdOrder, rows} = bfcvResult || {}
                  actualRowId = actualRowId || thisRowId
                  createdOrderId = createdOrderId || thisOrderId
                  // console.log({createdOrderId})

                  // if (field.name === 'spajanje_zaluzije') {
                  // if (field.name === 'spajanje_zaluzije') {
                    // await handleSpecialChangeCases({field, newValueOptionId: newValue, allFieldsToRender, createdOrderId, actualRows: rows, rowsWithRenderDetailsRef})

                  if (orderLoadStatus === 'loaded') {
                    handleSpecialChangeCases({field, newValueOptionId: newValue, allFieldsToRender, createdOrderId, actualRows: rows, rowsWithRenderDetailsRef})
                  }
                  else {
                    orderLoadedCallbackRef.current = async () => {
                      handleSpecialChangeCases({field, newValueOptionId: newValue, allFieldsToRender, createdOrderId, actualRows: rows, rowsWithRenderDetailsRef})
                    }
                  }
                  
                    // orderLoadedCallbackRef.current = () => console.log('orderLoadedCallbackRef.cur callback')
                  // }

                  if (createdOrderId)
                    history.replace(`/orders/${createdOrderId}/edit`)

                }

                else if (field.type === 'SELECT_MULTIPLE'){
                  // let fieldValuesIds = row.valuesIdsByFieldsIds[field.id]
                  // newValue - array of optionsIds
                  let updatedOptionsIds = newValue
                  let currentOptionsIds = (fieldValuesIds && fieldValuesIds.map(valueId => values.byId[valueId].optionId)) || []

                  let currentValueObjects = (fieldValuesIds && fieldValuesIds.map(valueId => values.byId[valueId])) || []
                  // console.log({currentValueObjects})

                  // let addedOptionsIds = updatedOptionsIds.filter(optionId => !currentOptionsIds.includes(optionId))
                  let addedOptionsIds = arrayDiff(currentOptionsIds, updatedOptionsIds)
                  let removedOptionsIds = arrayDiff(updatedOptionsIds, currentOptionsIds)

                  // console.log({currentOptionsIds, updatedOptionsIds})
                  // console.log({addedOptionsIds, removedOptionsIds})

                  addedOptionsIds.forEach(async addedOptionId => {
                    // let option = allOptions.byId[addedOptionId]
                    let newValueObj = {
                      type: 'SELECT_MULTIPLE',
                      id: Math.random(),
                      fieldId: field.id,
                      optionId: addedOptionId,
                      dirtyStatus: 'is-saving'
                    }
                    dispatch(actions.selectMultipleCreateValue(field.id, row, newValueObj))

                    let {id, optionId, ...withoutOptionIdAndId} = newValueObj

                    let {thisOrderId, thisRowId} = (await beforeFirstCreatedValue()) || {}
                    actualRowId = actualRowId || thisRowId
                    createdOrderId = createdOrderId || thisOrderId

                    orderService.createFieldValue({...withoutOptionIdAndId, customerOrderRowId: (actualRowId || row.id), fieldOptionId: addedOptionId})
                      .then((createdFieldValue) => {
                        // console.log({createdFieldValue})
                        if (!actualRowId)
                          dispatch(actions.selectMultipleUpdateValueWithNewId({...createdFieldValue, type: field.type}, newValueObj.id, field.id, row))
                      })
                  })

                  removedOptionsIds.forEach(removedOptionId => {
                    let valueObjToRemove = currentValueObjects.find(valueObj => valueObj.optionId === removedOptionId)

                    if (valueObjToRemove){
                      if (valueObjToRemove.dirtyStatus !== 'is-saving'){
                        // console.log({valueObjToRemove})
                        dispatch(actions.selectMultipleRemoveFieldValue(field.id, row, valueObjToRemove))
                        orderService.removeFieldValue(valueObjToRemove.id)
                      }
                    }

                    

                          


                    // let newValueObj = {
                    //   type: 'SELECT_MULTIPLE',
                    //   id: Math.random(),
                    //   fieldId: field.id,
                    //   optionId: addedOptionId,
                    //   // dirtyStatus: 'is-saving'  
                    // }
                    // dispatch(actions.removeFieldValue(fieldId, row))
                  })

                }
              },
              classes,
              // options: options.filter(option => option.fieldId === field.id)
              // options: field.optionIds && field.optionIds.map(id => options.byId[id])
              // options: filteredOptions || defaultOptions,
              options,
              setPopperAllowed,
              handlePopperClose,
              handlePopperOpen
            })}
          </TableCell>
        )
      })}

    </TableRow>
  )
}

// const cleanChildFields = (fieldId, rowValues, allOptions, allRules, updatedRowValues = {}) => {
//   let previousOptionId = rowValues[fieldId]
//   let previousOption = allOptions.byId[previousOptionId]

  // if (previousOption && previousOption.childrenRulesIds) {
  //   previousOption.childrenRulesIds.forEach(ruleId => {
  //     let rule = allRules.byId[ruleId]
  //     if (rule.childFieldId){
  //       // updatedRowValues = cleanChildFields(rule.childFieldId, rowValues, allOptions, allRules, updatedRowValues)
  //       updatedRowValues[rule.childFieldId] = null
  //     }
  //     if (rule.childOptionFieldId){
  //       let childOption = allOptions.byId[rule.childOptionFieldId]
  //       if (childOption && childOption.fieldId){
  //         updatedRowValues = cleanChildFields(childOption.fieldId, rowValues, allOptions, allRules, updatedRowValues)
  //         updatedRowValues[childOption.fieldId] = null
  //       }
  //     }
  //   })
  // }
//   return updatedRowValues
// }

const renderField = ({id, name, text, type, readOnly, isEnabled, value = '', defaultInt, setLocalValue, setNewValue, classes, options = [], setPopperAllowed, handlePopperOpen, handlePopperClose, rowValuesObjects, field, htmlId, orderLoadStatus, saveDefaultNumberValue, min, max,
  valueObj
}) => {

  if (type === 'NUMBER') {
    return (
      <OutlinedNumberField
        id={htmlId}
        name={name}
        value={value}
        defaultValue={defaultInt}
        text={text}
        isEnabled={isEnabled}
        readOnly={readOnly}
        setLocalValue={setLocalValue}
        setNewValue={setNewValue}
        withPlaceholderClasses={classes.withPlaceholder}
        orderLoadStatus={orderLoadStatus}
        saveDefaultNumberValue={saveDefaultNumberValue}
        min={min}
        max={max}
      />
    )
  }

  else if (type === 'TEXT') {
    return (
      <OutlinedTextField 
        id={htmlId}
        name={name}
        value={value}
        text={text}
        isEnabled={isEnabled}
        readOnly={readOnly}
        setLocalValue={setLocalValue}
        setNewValue={setNewValue}
        withPlaceholderClasses={classes.withPlaceholder}
        max={max}
      />
    )
  }

  else if (type === 'SELECT_ONE') {
    // console.log('valueObj', valueObj, value)

    if (valueObj && valueObj.text) {
      let selectedOption = options.find(o => o.text === valueObj.text)
      // let selectedOption = options.find(o => o.text === 'RAL 7016')
      value = (selectedOption && selectedOption.id) || ''
    }

    

    return (
      <OutlinedSelect
        id={htmlId}
        value={value}
        name={name}
        text={text}
        readOnly={readOnly}
        disabled={!isEnabled}
        classes={classes}
        // onChange={setNewValue}
        onChange={(e) => {
          // console.log('onChange')
          setNewValue(e)
          handlePopperClose()
        }}

        onOpen={() => {
          handlePopperClose()
          setPopperAllowed(true)
        }}
        onClose={() => setPopperAllowed(false)}
        
      >
        {() => (
          options.map((option, ind) => {
            // console.log(rowValuesObjects[field.id])
            return (
              <MenuItem 
                disabled={
                  ['Spojena 2/2', 'Spojena 2/3', 'Spojena 3/3'].includes(option.text)
                }
                key={ind} 
                value={option.id}
                onMouseEnter={(e) => {
                  e.persist()
                  // let src = `https://via.placeholder.com/400?text=${text}`
                  let imageName = option.hasImages && option.images[0]
                  if (imageName) {
                    // let src = `/api/fe/field-options/${option.id}/img/${imageName}`
                    // handlePopperOpen(e, option.id, src)

                    // handlePopperOpen(e, `https://via.placeholder.com/400?text=${text}`)
                    
                    formConfigApi.downloadOptionImage(option.id, imageName)
                      .then(src => {
                        if (src)
                          handlePopperOpen(e.target, src)
                      })
                  }

                  // handlePopperOpen(e, option.id, src)
                }}
                onMouseLeave={handlePopperClose}
              >
                {/* {option.text  + ' - ' + value + ' - ' + (rowValuesObjects[field.id] && rowValuesObjects[field.id].id)} */}
                {/* {option.text} {valueObj && valueObj.id}  */}
                {option.text}
              </MenuItem>
            )
          })
        )}
      </OutlinedSelect>
    )
  }

  // else if (type === 'SELECT_MULTIPLE') {

  //   // console.log(field)

  //   return (
  //     <OutlinedSelectMultiple
  //       // value={value || ''}
  //       value={value || []}
  //       renderValue={values => {
  //         let texts = values.map(value => options.find(o => o.id === value).text)
  //         return <MultiselectValueRender texts={texts}/>
  //       }}
  //       // displayEmpty
  //       text={text}
  //       // key={name}
  //       name={name}
  //       // id={id}
  //       readOnly={readOnly}

  //       disabled={!isEnabled}
  //       classes={classes}
  //       onChange={e => {
  //         setValue(e.target.value)
  //       }}
  //     >
  //       {options.map((option, ind) => {
  //           return <MenuItem key={ind} value={option.id}>{option.text}</MenuItem>
  //       })}
  //     </OutlinedSelectMultiple>
  //   )
  // }

  else if (type === 'SELECT_MULTIPLE') {

    return (
      <CountingMultiSelect
        id={htmlId}
        // value={value || ''}
        value={value || []}
        renderValue={valuesIds => {
          let texts = []
          if (options && options.length > 0){
            texts = valuesIds.map(valueId => options.find(o => o.id === valueId).text)
          }
          return <MultiselectValueRender texts={texts}/>
        }}
        text={text}
        name={name}
        readOnly={readOnly}
        disabled={!isEnabled}
        classes={classes}
        onChange={setNewValue}
        options={options}
      />
    )
  }

  return null
}


const styles={
  disabled: {
    opacity: '.1'
  },
  smallLabel: {
    transform: 'translate(14px, 10px) scale(1)',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    // width: '84%',
    width: 'calc(100% - 42px)',
    color: placeholderLabelColor
  },
  smallPadding: {
    padding: '8px 30px 5px 5px',
    // backgroundColor: 'red'
  },
  invisible: {
    display: 'none'
  },
  withPlaceholder: {

    '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: placeholderLabelColor,
    //   textOverflow: 'ellipsis',
    //   whiteSpace: 'nowrap',
    //   overflow: 'hidden',
      opacity: 1
    },
    
    '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
      color: placeholderLabelColor,
    //   textOverflow: 'ellipsis',
    //   whiteSpace: 'nowrap',
    //   overflow: 'hidden',
      opacity: 1
    },
    
    '&::-ms-input-placeholder': { /* Microsoft Edge */
      color: placeholderLabelColor,
    //   textOverflow: 'ellipsis',
    //   whiteSpace: 'nowrap',
    //   overflow: 'hidden',
      opacity: 1
    }


  }
}

function areFieldsEqual (fields, prevRow, nextRow, prevValues, nextValues) {
  return fields.allIds.every(fieldId => {
    let prevFieldValuesIds = prevRow.valuesIdsByFieldsIds && prevRow.valuesIdsByFieldsIds[fieldId]
    let nextFieldValuesIds = nextRow.valuesIdsByFieldsIds && nextRow.valuesIdsByFieldsIds[fieldId]

    if (!prevFieldValuesIds && !nextFieldValuesIds){
      return true
    }
    else if (!!prevFieldValuesIds !== !!nextFieldValuesIds){
      return false
    }
    else if (prevFieldValuesIds.length !== nextFieldValuesIds.length){
      return false
    }
    else {
      let fieldsEqual = prevFieldValuesIds.every(valueId => {
        return prevValues.byId[valueId] === nextValues.byId[valueId]
      }) 
      return fieldsEqual
    }
  })
}

function areEqual(prevProps, nextProps) {
  // console.log(prevProps.isSelected, nextProps.isSelected)
  if (prevProps.rowCount !== nextProps.rowCount)
    return false

  if (prevProps.row.id !== nextProps.row.id)
    return false

  if (prevProps.editingDisabled !== nextProps.editingDisabled)
    return false

  if (prevProps.number !== nextProps.number)
    return false

  if (prevProps.isSelected !== nextProps.isSelected)
    return false

  let fieldsEqual = areFieldsEqual(prevProps.fields, prevProps.row, nextProps.row, prevProps.values, nextProps.values)
  if (!fieldsEqual)
    return false

  if (prevProps.rowSecond && nextProps.rowSecond){
    let fieldsEqual2 = areFieldsEqual(prevProps.fields, prevProps.rowSecond, nextProps.rowSecond, prevProps.values, nextProps.values)
    if (!fieldsEqual2)
      return false
  }
  
  if (prevProps.rowThird && nextProps.rowThird){
    let fieldsEqual3 = areFieldsEqual(prevProps.fields, prevProps.rowThird, nextProps.rowThird, prevProps.values, nextProps.values)
    if (!fieldsEqual3)
      return false
  }
  
  if (prevProps.rowFourth && nextProps.rowFourth){
    let fieldsEqual4 = areFieldsEqual(prevProps.fields, prevProps.rowFourth, nextProps.rowFourth, prevProps.values, nextProps.values)
    if (!fieldsEqual4)
      return false
  }

  if (prevProps.rowFifth && nextProps.rowFifth){
    let fieldsEqual5 = areFieldsEqual(prevProps.fields, prevProps.rowFifth, nextProps.rowFifth, prevProps.values, nextProps.values)
    if (!fieldsEqual5)
      return false
  }

  if (prevProps.orderLoadStatus !== nextProps.orderLoadStatus)
    return false

  // if (JSON.stringify(prevProps.hscdData) !== JSON.stringify(nextProps.hscdData))
  //   return false

  return true
}

const RowMemo = React.memo(Row, areEqual)
export default withStyles(styles)(RowMemo)
// export default withStyles(styles)(Row)
