import React from 'react'
// import MainLayout from '../layout/Main'
import SimpleLayout from '../layout/SingleAppBarLayout'
import Orders from './Orders.js'

// import Fade from '@material-ui/core/Fade';

// import { withStyles } from '@material-ui/core/styles';
// import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import Badge from '@material-ui/core/Badge';
// import MenuIcon from '@material-ui/icons/Menu';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import NotificationsIcon from '@material-ui/icons/Notifications';

// import {withRouter} from 'react-router-dom'

// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';

// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';

// const styles = theme => ({
//   appBarSpacer: theme.mixins.toolbar,
//   tableRow: {
//     cursor: 'pointer'
//   },

//   orders: {

//   }
// });

import { useTranslation } from 'react-i18next';

const OrdersWithLayout = ({auth}) => {
  // console.log({props})
  // return (
  //   <MainLayout 
  //     headerText="Vsa naročila"
  //     matchProps={match}
  //     component={OrdersList}
  //   />
  // )


  const { t } = useTranslation()

  return (
    
    <SimpleLayout 
      headerText={t('Naročila')}
      component={Orders}
      auth={auth}
      backButtonEnabled={false}
      contentStyles={{
        height: '100%',
        overflow: 'hidden',
        position: 'absolute'
      }}
      mainContentStyles={{
        // backgroundColor: 'red',
        height: 'calc(100% - 64px)',
        overflow: 'hidden',
      }}
    />
  )
}

export default (OrdersWithLayout)