import React, {useState} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import UpdateDialog, {useEditPopupState} from '../UpdateDialog'

const renderStickyTableHead = (entry, columnNumber) => {
  // console.log({entry, entryIndex})
  return (
    <TableCell
      style={{
        position: "sticky",
        top: 0,
        padding: 0,
        backgroundColor: "white",
        whiteSpace: 'nowrap',
        borderLeft: entry ? '1px solid #dadada' : 'none',
        width: entry ? 'auto' : 340
      }}
      key={columnNumber}
      align="left"
    >
      <div style={{padding: '6px 24px 6px 16px'}}>{(entry !== null) ? (columnNumber + '.') : ''}</div>
      <BottomLine />
    </TableCell>
  )
}

const renderEmptyStickyTableHead = (withLeftBorder, key) => {
  return (
    <TableCell
      key={key}
      style={{
        position: "sticky",
        top: 0,
        padding: 0,
        backgroundColor: "white",
        whiteSpace: 'nowrap',
        borderLeft: withLeftBorder ? '1px solid #dadada' : 'none',
        width: 'auto'
      }}
    >
      <div style={{padding: '6px 24px 6px 16px'}}></div>
      <BottomLine />
    </TableCell>
  )
}

const renderEmptyCell = (withLeftBorder, key) => {
  return (
    <TableCell
      align="left" 
      key={key}
      style={{
        whiteSpace: 'nowrap', 
        borderLeft: withLeftBorder ? '1px solid #dadada' : 'none',
        width: 'auto'
      }}
    />
  )
}

const BottomLine = () => (
  <div style={{
    position: 'absolute',
    borderBottom: '1px solid lightgray',
    width: '100%',
    bottom: 0
  }}></div>
)

export default ({
  workOrder,
  onChange,
  columnOffset,
  editingDisabled
}) => {
  
  const editPopupState = useEditPopupState()

  if (!workOrder || !workOrder.worksheetRows)
    return null

  const {rows} = workOrder.worksheetRows

  const columns = rows
  const rowLabels = columns && columns[0] && columns[0].map(i => i.label)

  let uniqueLabels = new Set()
  let labelsObjectsArray = []

  const columnsAsObjects = columns.map(col => {
    return col.reduce((colObj, {label, value, columnNum}) => {
      if (!uniqueLabels.has(label)){
        labelsObjectsArray.push({label, columnNum})
        uniqueLabels.add(label)
      }
      return {...colObj, [label]: value}
    }, {})
  })

  let labelsArray = labelsObjectsArray.sort((a, b) => a.columnNum - b.columnNum)
    .map(labelObj => labelObj.label)

  // console.log({uniqueLabels})
  // console.log({labelsArray})

  // return null

  if (!columns)
    return null

  const columnsToDisplay = Array(5).fill().map((_, n) => {
    // return columns[columnOffset + n] || null
    return columnsAsObjects[columnOffset + n] || null
  })

  return (
    <>
      <Table size="small" style={{tableLayout: 'fixed'}}>
        <TableHead>
          <TableRow key="header">
            {renderStickyTableHead(null)}
            {/* {columns */}
            {columnsToDisplay
              // .slice(columnOffset, columnOffset + 5)
              .map((col, ind) => {
                if (col)
                  return renderStickyTableHead({}, ind + columnOffset + 1) 
                else {
                  let withLeftBorder = (columnOffset + ind) < (columns.length + 1)
                  return renderEmptyStickyTableHead(withLeftBorder, ind + columnOffset + 1)
                }
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          
          {labelsArray && labelsArray.map((label, labelIndex) => {  
            // console.log({rowName})
            // return null

            // let label = labels[labelIndex]
            return (
              <TableRow key={labelIndex} >
                <TableCell component="td" scope="row" style={{whiteSpace: 'nowrap', width: 340}}>{label}</TableCell>
                
                {columnsToDisplay
                  // .slice(columnOffset, columnOffset + 5)
                  .map((column, columnIndex)  => {

                    if (column){

                      let value = column[label]
                      return (
                        <TableCell
                          key={`${workOrder.id}-${labelIndex}-${columnIndex}`}
                          onDoubleClick={() => {
                            if (!editingDisabled){

                              editPopupState.open({
                                text: value,
                                onConfirm: (dialogValue) => {
                                  if (dialogValue !== value)
                                    onChange(labelsObjectsArray[labelIndex], columnIndex + columnOffset, dialogValue)
                                }
                              })
                            }
                          }}
                          align="left" style={{
                            whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis', borderLeft: '1px solid #dadada', width: 'auto',
                            color: editingDisabled ? 'gray' : 'unset'
                          }}
                        >
                            {value}
                        </TableCell>
                      )
                    }

                    else {
                      let withLeftBorder = (columnOffset + columnIndex) < (columns.length + 1)
                      return renderEmptyCell(withLeftBorder, columnIndex)
                      // return null
                    }
                  })
                }
              </TableRow >
            )
          })}
        </TableBody>
      </Table>
      
      <UpdateDialog {...editPopupState} />
      
    </>
  )
}