import React from 'react'
import {Route} from "react-router-dom"
import SimpleLayout from './layout/SingleAppBarLayout'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import {useFormConfigState} from '../store/formConfig/FormConfigContext'

// import {useReload as useOrdersReload} from '../store/orders/OrdersContext'
// import {useUpdateLastChange as useUpdateLastOrdersChange} from '../store/orders/OrdersContext'

// import ordersService from '../services/order'

const styles = theme => {
  // console.log({theme})
  return {
    cardGrid: {
      paddingTop: theme.spacing(1)* 8,
      paddingBottom: theme.unit * 8,
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    }
  }
}

const ProductButton = ({path, primaryText, secondaryText, disabled, onClick}) => {
  return (
    <Card disabled={disabled} raised={!disabled}>
      <CardActionArea onClick={onClick} disabled={disabled} >
        <CardContent>
          <Typography color="textSecondary" gutterBottom >
            {secondaryText}
          </Typography>
          <Typography variant="h5" component="h2" color={disabled ? 'textSecondary' : 'textPrimary'} style={{opacity: disabled ? .6 : 1}}>
            {primaryText}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const ProductFamilyList = ({history, match}) => {

  let formConfig = useFormConfigState()
  if (!formConfig.productTypes || formConfig.isFetching)
    return "loading..."

  // let productTypes = formConfig.productTypes
  let productTypes = formConfig.productTypes.allIds.map(id => formConfig.productTypes.byId[id])

  return <div >

    <Breadcrumbs aria-label="Breadcrumb" gutterBottom>
      <Typography key={"product1"} color="textPrimary">{'Družine produktov'}</Typography>
    </Breadcrumbs>

    {/* {primaryText && <Typography variant="h6" gutterBottom component="h6">
      {primaryText}
    </Typography>} */}

    <Grid container spacing={3}>
      {productTypes.map(productType => (
        <Grid item xs={3} key={productType.id}>
          <ProductButton
            key={productType.id}
            primaryText={productType.name}
            onClick={() => {
              history.push(`${match.path}/${productType.id}`)
            }}
          />
        </Grid>
      ))}
    </Grid>
  </div>
}

const ProductList = ({history, match, auth}) => {

  // const reloadOrders = useOrdersReload()
  // const updateLastOrdersChange = useUpdateLastOrdersChange()

  let formConfig = useFormConfigState()
  if (!formConfig.productTypes || !formConfig.products || formConfig.isFetching)
    return "loading..."

  let productTypes = formConfig.productTypes

  let productTypeId = match.params.productTypeId
  // let productType = productTypes.find(pt => pt.id == productTypeId)
  let productType = productTypes.byId[productTypeId]
  // console.log({productType})

  // let products = formConfig.products && formConfig.products.filter(p => p.productTypeId == productTypeId)

  let products = formConfig.products && formConfig.products.allIds
    .map(productId => {
      return formConfig.products.byId[productId]
    })
    .filter(product => {
      // console.log({product})
      return product.productTypeId === Number(productTypeId)
    })

  return <div >

    <Breadcrumbs aria-label="Breadcrumb" gutterBottom>
      <Link key={'/products'} color="inherit" to={'/products'} component={RouterLink}>
        {'Družine produktov'}
      </Link>
      <Typography key={"product1"} color="textPrimary">{productType.name}</Typography>
    </Breadcrumbs>

    {/* {primaryText && <Typography variant="h6" gutterBottom component="h6">
      {primaryText}
    </Typography>} */}

    <Grid container spacing={3}>
      {products.map(product => (
        <Grid item xs={3} key={product.id}>
          <ProductButton
            key={product.id}
            primaryText={product.name}
            onClick={() => {
              
              history.push(`/orders/new/${product.id}`)

              // if (product.id && auth && auth.user && auth.user.id){
              //   ordersService.createOrder(product.id, auth.user.id)
              //     .then(createdOrder => {
              //       return ordersService.createRows(createdOrder.id, [1, 2, 3, 4, 5])
              //         .then(() => createdOrder)
              //     })
              //     .then(createdOrder => {
              //       updateLastOrdersChange()
              //       history.push(`/orders/${createdOrder.id}/edit`)
              //     })
              // }


            }}
          />
        </Grid>
      ))}
    </Grid>
  </div>
}

const ProductsMenu = ({auth, history, match}) => {
  return (
    <>
      <Route exact path="/products" render={({match}) => (
        <SimpleLayout 
          headerText={"Družine produktov"}
          // component={Menu}
          auth={auth}
          history={history}
          match={match}
          mainRender={({history}) => {
            // console.log(history)
            return <ProductFamilyList match={match} history={history} />
          }}
        />
      )}/>

      <Route exact path="/products/:productTypeId" render={({match}) => (
        <SimpleLayout 
          headerText={"Izbira produkta"}
          // component={Menu}
          auth={auth}
          history={history}
          match={match}
          mainRender={({history}) => {
            // console.log(history, match)
            return <ProductList match={match} history={history} auth={auth} />
          }}
        />
      )}/>
    </>
    
  )
}

export default withStyles(styles)(ProductsMenu)
