import React, {useState, useEffect, useContext} from 'react'
import formConfigService from '../../services/formConfig'

const FormConfigContext = React.createContext({})

const FormConfigProvider = function ({children}) {

  const [isFetching, setIsFetching] = useState(true)
  const [productTypes, setProductTypes] = useState([])
  const [products, setProducts] = useState(null)
  const [fields, setFields] = useState([])
  const [options, setOptions] = useState([])
  const [rules, setRules] = useState([])

  useEffect(() => {
    // let productTypesPromise = formConfigService.getProductTypes()
    // let productsPromise = formConfigService.getProducts()
    // let fieldsPromise = formConfigService.getFields()
    // let optionsPromise = formConfigService.getOptions()
    // let enabledFieldsPromise = formConfigService.getEnabledFields()
    
    formConfigService.getProductsConfiguration()
      .then(function([productTypes, products, rules, fields, options]) {

        setProductTypes(productTypes)
        setProducts(products)
        setFields(fields)
        setOptions(options)
        setRules(rules)

        setIsFetching(false)
        // console.log("All done: ", {productTypes, products, fields, options, enabledFields});
    });


    // Promise.all([
    //   productTypesPromise, 
    //   productsPromise, 
    //   fieldsPromise, 
    //   optionsPromise,
    //   enabledFieldsPromise
    // ])
    //   .then(function([productTypes, products, fields, options, enabledFields]) {
    //     setProductTypes(productTypes)
    //     setProducts(products)
    //     setFields(fields)
    //     setOptions(options)
    //     setEnabledFields(enabledFields)

    //     setIsFetching(false)
    //     console.log("All done: ", {productTypes, products, fields, options, enabledFields});
    // });

  }, [])

  return (
    <FormConfigContext.Provider
      value={{
        isFetching,
        productTypes,
        products,
        fields,
        options,
        rules
      }}
    >
      {children}
    </FormConfigContext.Provider >
  )
}

function useFormConfigState() {
  const context = useContext(FormConfigContext)
  if (context === undefined) {
    throw new Error('useFormConfigState must be used within a FormConfigContext')
  }
  return context
}

function useProductConfiguration(productId) {
  const context = useContext(FormConfigContext)
  if (context === undefined) {
    throw new Error('useProductConfiguration must be used within a FormConfigContext')
  }

  if (!productId)
    return null

  let {isFetching, products, fields, options, rules} = context
  if (isFetching)
    return null

  let product = products.byId[productId]
  if (!product)
    return null
  
  return {product, fields, options, rules}
}

function useProducts() {
  const context = useContext(FormConfigContext)
  if (context === undefined) {
    throw new Error('useProducts must be used within a FormConfigContext')
  }

  return context.products
}

// function useProductConfigurationOld2 (productId) {
//   const context = useContext(FormConfigContext)
//   if (context === undefined) {
//     throw new Error('useFormConfigState must be used within a FormConfigContext')
//   }

//   let {isFetching, products, fields, options, enabledFields} = context
//   if (isFetching)
//     return null

//   let product = products.byId[productId]
//   if (!product)
//     return null

//   let formConfigId = product.formConfigId

//   let productFieldsIds = fields.allIds.filter(fieldId => {
//     let field = fields.byId[fieldId]
//     // console.log(field, formConfigId)
//     return field.formConfigs.some(config => config.id === formConfigId)
//   })

//   return {productFieldsIds, fields, options, enabledFields}
// }

// function useProductConfigurationOld(productId) {
//   const context = useContext(FormConfigContext)
//   if (context === undefined) {
//     throw new Error('useFormConfigState must be used within a FormConfigContext')
//   }

//   let {isFetching, products, fields, options} = context

//   if (isFetching)
//     return null

//   let product = products.find(p => p.id === productId)

//   if (!product)
//     return null

//   let formConfigId = product.formConfigId

//   // console.log({fields})

//   let productFieldsIds = fields.allIds.filter(fieldId => {
//     let field = fields.byId[fieldId]
//     return field.formConfigs.some(config => config.id === formConfigId)
//   })

//   let productOptionsIds = options.allIds.filter(optionId => {
//     let option = options.byId[optionId]
//     // return productFieldsIds.some(fieldId => fieldId === option.fieldId)
//     return productFieldsIds.includes(option.fieldId)
//   })

//   return {
//     fields: {
//       allIds: productFieldsIds,
//       byId: productFieldsIds.reduce((normalizedObject, id) => ({...normalizedObject, [id]: fields.byId[id]}), {byId: {}})
//     }, 
//     options: {
//       allIds: productOptionsIds,
//       byId: productOptionsIds.reduce((normalizedObject, id) => ({...normalizedObject, [id]: options.byId[id]}), {byId: {}})
//     }, 
//   }

//   // return {
//   //   productFieldsIds,
//   //   productOptionsIds,
//   //   products,
//   //   fields
//   // }
// }

export { FormConfigProvider, useFormConfigState, useProductConfiguration, useProducts}