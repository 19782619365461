const getFieldRenderDetails = ({field, allOptions, allRules, rowValues, valueObj}) => {

  let isEnabled = field.alwaysEnabled
  let isReadonly = false

  let defaultOptions = field.optionsIds && field.optionsIds
    .map(optionsId => allOptions.byId[optionsId])

  let filteredOptions = null

  if (!isEnabled){
    if (field.parentRulesIds) {
      // console.log('Mera "c" rules:')
      field.parentRulesIds.forEach(ruleId => {
        let rule = allRules.byId[ruleId]
        if (rule.parentOptionFieldId){
          let parentOption = allOptions.byId[rule.parentOptionFieldId]
          if (parentOption && parentOption.id === rowValues[parentOption.fieldId]){
            isEnabled = true

            if (rule.readonly && (rule.intValue || rule.strValue))
              isReadonly = true

            // ToDo: ? 
            // if (field.text === 'Mera "c"' && rule.readonly){
            //   // console.log('OK', {rule}, {parentOption})
            //   readonlyValue = rule.intValue || rule.strValue
            // }
          }
        }
      })
    }
    
    if (!isEnabled){
      filteredOptions = defaultOptions && 
        defaultOptions.filter(option => {
          return option.parentRulesIds && option.parentRulesIds.some(ruleId => {
            let rule = allRules.byId[ruleId]
            if (rule && rule.parentOptionFieldId) {
              let parentOption = allOptions.byId[rule.parentOptionFieldId]

              if (parentOption && parentOption.id === rowValues[parentOption.fieldId])
                return true
            }
            return false
          })
        })

      if (filteredOptions && filteredOptions.length > 0)
        isEnabled = true
    }
  }
  
  let selectedOptionText = valueObj && valueObj.optionId && allOptions.byId[valueObj.optionId] && allOptions.byId[valueObj.optionId].text
  if (['Spojena 2/2', 'Spojena 2/3', 'Spojena 3/3'].includes(selectedOptionText))
    isEnabled = false

  isReadonly = Boolean(isReadonly) || (valueObj && valueObj.dirtyStatus === 'is-saving')

  let options = filteredOptions || defaultOptions

  return {
    isEnabled,
    isReadonly,
    options
  }

}

function getRowValues(row, values, fields){
  let rowValues = {}
  let rowValuesObjects = {}

  fields.allIds && fields.allIds.forEach(fieldId => {
    let fieldValuesIds = row.valuesIdsByFieldsIds && row.valuesIdsByFieldsIds[fieldId]
    if (fieldValuesIds){
      fieldValuesIds.forEach(valueId => {
        if (values.byId[valueId] !== null){
          let value = values.byId[valueId]

          if (!value)
            return null

          // if (value.type === 'SELECT_ONE'){
          //   console.log({value})
          // }
          
          // console.log({value, fieldId})
          rowValuesObjects[fieldId] = value


          if (value.localValue || value.localValue === '' || value.localValue === 0)
            rowValues[fieldId] = value.localValue
          else if (value && value.type === 'SELECT_ONE' && value.optionId)
            rowValues[fieldId] = value.optionId
          else if (value.type === 'NUMBER')
            rowValues[fieldId] = value.number
          else if (value.type === 'TEXT')
            rowValues[fieldId] = value.text
          else if (value.type === 'SELECT_MULTIPLE' && value.optionId){
            // console.log(('MS value type:', value))
            // special multiselect with counters
            // TODO: add fieldType: SELECT_MULTIPLE_WITH_COUNTERS
            // and check value.type === 'SELECT_MULTIPLE_WITH_COUNTERS'
            if (!rowValues[fieldId])
              rowValues[fieldId] = [value.optionId]
            else
              rowValues[fieldId].push(value.optionId)
          }
        }
        
      })
    }
  })
  // console.log({rowValues, rowValuesObjects})
  // 
  return {rowValues, rowValuesObjects}
}

export {
  getFieldRenderDetails,
  getRowValues
}