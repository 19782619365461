import React, {useState, useEffect} from 'react'
// import {useLocation, useHistory, useRouteMatch} from "react-router-dom";
import {useLocation, useHistory} from "react-router-dom";
import queryString from 'query-string'

import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
// import IconButton from '@material-ui/core/IconButton';
// import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

import {useAuth} from '../../AuthContext'

// import LoadingSpinner from './layout/LoadingSpinner'
import userService from '../../services/user'
import InfoDialog from './InfoDialog'

const successDialogConfig = {
  open: true,
  title: 'Geslo uspešno nastavljeno!',
  text: 'Lahko se prijavite.',
  buttonText: 'Prijava',
  showSuccessIcon: true
}

const errorDialogConfig ={
  open: true,
  title: 'Link ni veljaven!',
  text: 'Preusmerjeni boste na vstopno stran.',
  buttonText: 'OK',
  showErrorIcon: true
}

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1)* 3,
    marginRight: theme.spacing(1)* 3,
    [theme.breakpoints.up(400 + theme.spacing(1)* 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  h1: {
    marginTop: theme.spacing(1)* 8
  },
  paper: {
    marginTop: theme.spacing(1)* 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1)* 2}px ${theme.spacing(1)* 3}px ${theme.spacing(1)* 3}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1)* 3,
  },
});

function SetPassword(props) {
  const { classes } = props;

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  // const match = useRouteMatch("/set-password");

  useEffect(() => {
    const values = queryString.parse(location.search)
    console.log({values})
    if (values.token){
      setToken(values.token)
    } else {
      history.replace('/')
    }
  }, [history, location.search]);

  let auth = useAuth()
  // if (auth.user)
  //   auth.logout()
  // console.log(auth)


  const [token, setToken] = useState(null)
  const [password1, setPassword1] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [formErrorsEnabled, setFormErrorsEnabled] = React.useState(false)

  const submitForm = React.useCallback((e) => {

    if (auth.user)
      auth.logout()

    e.preventDefault();
    // console.log(password1, password2, password1.length > 4, password1 === password2, token)
    if (password1 && password2 && password1.length > 4 && password1 === password2 && token)
      userService.resetPassword(password1, token)
        .then(() => {
          setDialogData(successDialogConfig)
        })
        .catch(e => {
          setDialogData(errorDialogConfig)
        })
  }, [password1, password2, token])

  const onPassword1Change = React.useCallback((e) => {
    setPassword1(e.target.value)
  }, [setPassword1])

  const onPassword2Change = React.useCallback((e) => {
    setPassword2(e.target.value)
  }, [setPassword2])

  const onPasswordd2Blur = React.useCallback((e) => {
    if (password1 || password2) 
      setFormErrorsEnabled(true)
  }, [password1, password2])


  // if (isLoggingIn) {
  //   return (
  //     <Fade in={true}>
  //       <LoadingSpinner style={{position: 'absolute'}}/>
  //     </Fade>
  //   )
  // }

  // const [dialogData, setDialogData] = useState(null)
  const [dialogData, setDialogData] = useState(null)

  let showNotEqualError = formErrorsEnabled && (password1 !== password2)
  let showPasswordLenghtError = formErrorsEnabled && (password1.length < 5) && (password2.length < 5)

  return (
    
    <React.Fragment>

      <Fade in={true}>
        <Typography className={classes.h1} component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
          {t('Spletna Trgovina Sonal')}
        </Typography>
      </Fade>
      <Fade in={true}>
        <main className={classes.main}>
          <Paper className={classes.paper} style={{position: 'relative'}}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('Novo geslo')}
            </Typography>
            <form className={classes.form} onSubmit={submitForm}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password1">{t('Vpišite željeno geslo')}</InputLabel>
                <Input id="password1" type="password" name="password1" autoComplete="new-password" autoFocus value={password1} onChange={onPassword1Change}/>
                {/* { credentialsError &&
                  <FormHelperText id="component-helper-text">{t('Napačno uporabniško ime ali geslo')}</FormHelperText>
                } */}
              </FormControl>
              <FormControl margin="normal" required fullWidth error={showNotEqualError || showPasswordLenghtError}>
                <InputLabel htmlFor="password2">{t('Potrdite geslo')}</InputLabel>
                <Input name="password2" type="password" id="password2" autoComplete="new-password" value={password2} onChange={onPassword2Change} onBlur={onPasswordd2Blur}/>
                { showNotEqualError &&
                  <FormHelperText id="component-helper-text">{t('Gesla se ne ujemata')}</FormHelperText>
                }
                { showPasswordLenghtError &&
                  <FormHelperText id="component-helper-text">{t('Geslo mora vsebovati vsaj 5 znakov')}</FormHelperText>
                }
              </FormControl>
              {/* <FormControlLabel
                control={<Checkbox checked={rememberMe} value="remember" color="primary" onChange={() => toggleRememberMe(!rememberMe)}/>}
                label={t('Zapomni si uporabniško ime')}
              /> */}

              {/* <IconButton
                style={{position: 'absolute', right: 0, top: 0}}
                color="inherit"
                aria-label="Open drawer"
                onClick={(e) => setLanguageMenuEl(e.currentTarget)}
              >
                <LanguageIcon />
              </IconButton>

              <Menu
                anchorEl={languageMenuEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(languageMenuEl)}
                onClose={() => setLanguageMenuEl(null)}
              >
                <MenuItem onClick={() => changeLanguage('si')}>Slovensko</MenuItem>
                <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
              </Menu> */}


              <Button
                // type="button"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                // onClick={onLoginClick}
                disabled={!password1 || !password2 || (password1 !== password2) || password1.length < 5}
              >
                {t('Potrdi')}
              </Button>
              
            </form>
          </Paper>

          <InfoDialog {...dialogData}
            onContinue={() => {
              history.replace('/')
            }}
          />  

        </main>
      </Fade>
    </React.Fragment>
  );
}

SetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SetPassword);
