import React, {useState, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import userService from '../../services/user'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const sendResetPasswordLink = (email) => {
  return userService.requestPasswordReset(email)
    .catch(e => console.log(e))
}

const RecoverPasswordForm = ({onBackClick}) => {

  let [email, setEmail] = useState('')
  let [recoveryLinkSent, setRecoveryLinkSent] = useState(false)

  // const history = useHistory();

  return (
    <>
      {/* <Paper className={"classes.paper"} style={{position: 'relative'}}> */}
        {/* <Avatar className={"classes.avatar"}>
          <LockOutlinedIcon />
        </Avatar> */}

        <IconButton onClick={onBackClick} aria-label="back" style={{position: 'absolute', left: 12, top: 8}}>
          <ArrowBackIcon />
        </IconButton>

        <Typography component="h1" variant="h5">
          Obnovitev gesla
        </Typography>

        {recoveryLinkSent ? (<>
          {/* <Avatar style={{}}> */}
          {/* </Avatar> */}
          <Typography variant="body2" style={{marginTop: '1em'}}>
            Povezava do obnovitve gesla je bila poslana na e-mail {email}.
          </Typography>
          <MailOutlineIcon color="primary" fontSize="large" style={{marginTop: 16}}/>

        </>) : (
          <Typography variant="body2" style={{marginTop: '1em'}}>
            Na vaš e-mail vam bomo poslali povezavo do obnovitve gesla.
          </Typography>
        )}

        <form style={{
          width: '100%'
        }} onSubmit={(e) => {
          e.preventDefault()
          sendResetPasswordLink(email)
            .then(() => {
              setRecoveryLinkSent(true)
            })
        }}>
          {!recoveryLinkSent && (
            <FormControl margin="normal" fullWidth >
              <InputLabel htmlFor="email">{'e-mail'}</InputLabel>
              <Input name="email" type="email" id="email" value={email} onChange={e => setEmail(e.target.value)}/>
            </FormControl>
          )}

          {!recoveryLinkSent && (
            <Button
              style={{
                marginTop: 24
              }}
              id="reset-password-button"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              // onClick={() => sendResetPasswordLink(email)}
              disabled={email === ''}
            >
              {'Pošlji povezavo'}
            </Button>
          )}
          
        </form>
      {/* </Paper> */}
    </>
  )
}

export default RecoverPasswordForm