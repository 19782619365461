import { RemoveFromQueueTwoTone } from '@material-ui/icons';
import handleFieldChangeFromName from '../handleFieldChangeFromName'
import handleFieldResetFromName from '../handleFieldResetFromName'

import handleStranskoVodiloLevo from './stranskoVodiloLevo'

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default async ({
  field, newValueOptionId, allFieldsToRender,
  rowsWithRenderDetails, ind, dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService,
  // productField,
  allOptions, createdOrderId, actualRows, addRows, insertRows, deleteRow,
  rowsWithRenderDetailsRef
}) => {

  let currentRow = rowsWithRenderDetails[ind]
  let secondRow = rowsWithRenderDetails[ind + 1]
  let thirdRow = rowsWithRenderDetails[ind + 2]
  // let fourthRow = rowsWithRenderDetails[ind + 3]
  // let fifthRow = rowsWithRenderDetails[ind + 4]

  let selectedOption = allOptions.byId[newValueOptionId]

  let oldOptionId = currentRow.rowValues[field.id]
  let oldOption = allOptions.byId[oldOptionId]

  // console.log({currentRow, secondRow, thirdRow, fourthRow, fifthRow})
  
  // let fieldDetails = currentRow && currentRow.fieldDetailsByFieldId && currentRow.fieldDetailsByFieldId[field.id]

  let handleChange = (targetFieldName, targetOptionText, rowWithDetails, actualRowId) => {
    handleFieldChangeFromName({
      targetFieldName,
      targetOptionText,
      rowWithDetails,
      actualRowId,
      
      createdOrderId,
      dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService, allFieldsToRender, allOptions
    })
  }

  let handleReset = (targetFieldName, rowWithDetails, actualRowId) => {
    handleFieldResetFromName({
      targetFieldName,
      rowWithDetails,
      actualRowId,
      
      createdOrderId,
      dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService, allFieldsToRender, allOptions
    })
  }

  // if (oldOption && oldOption.text === 'Spojena 1/3'){
  if (selectedOption && selectedOption.text === 'Spojena 1/3'){

    if (!oldOption || (oldOption && oldOption.text === 'Samostojna')){
      await insertRows({quantity: 2, orderId: createdOrderId})
    }
    else if (oldOption && oldOption.text === 'Spojena 1/2'){
      await insertRows({quantity: 1, orderId: createdOrderId})
    }
    
    currentRow = rowsWithRenderDetailsRef.current[ind]
    secondRow = rowsWithRenderDetailsRef.current[ind + 1]
    thirdRow = rowsWithRenderDetailsRef.current[ind + 2]

    // handling first (Spojena 1/3) row
    if (currentRow) {
      let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id

      handleChange('stransko_vodilo_levo', 'enojno vodilo', currentRow, actualRowId)



      // handleChange('stransko_vodilo_levo-barva', 'RAL 9006', currentRow, actualRowId)
      // handleChange('stransko_vodilo_levo-distancnik', 'TD-st. 55-75mm', currentRow, actualRowId)

      // handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
      // handleChange('stransko_vodilo_desno-barva', 'RAL 9006', currentRow, actualRowId)
      // handleChange('stransko_vodilo_desno-distancnik', 'TD-st. 55-75mm', currentRow, actualRowId)
    }

    
    // handling second (Spojena 2/3) row
    if (secondRow){
      
      // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
      let actualRowId = secondRow && secondRow.row.id

      handleChange('spajanje_zaluzije', 'Spojena 2/3', secondRow, actualRowId)

      // handleChange('stransko_vodilo_desno', 'dvojno vodilo', secondRow, actualRowId)
      // handleChange('stransko_vodilo_desno-barva', 'RAL 9006', secondRow, actualRowId)
      // handleChange('stransko_vodilo_desno-distancnik', 'TD-st. 55-75mm', secondRow, actualRowId)

      handleReset('stransko_vodilo_levo', secondRow, actualRowId)
      handleReset('stransko_vodilo_levo-barva', secondRow, actualRowId)
      handleReset('stransko_vodilo_levo-distancnik', secondRow, actualRowId)
    }

    // handling third (Spojena 3/3) row
    if (thirdRow){
      // let thirdOption = fieldDetails && fieldDetails.options && fieldDetails.options.find(o => o.text === 'Spojena 3/3')
      // let actualRowId = actualRows && actualRows[ind+2] && actualRows[ind+2].id
      let actualRowId = thirdRow && thirdRow.row.id

      handleChange('spajanje_zaluzije', 'Spojena 3/3', thirdRow, actualRowId)

      // handleChange('stransko_vodilo_desno', 'enojno vodilo', thirdRow, actualRowId)
      // handleChange('stransko_vodilo_desno-barva', 'RAL 9006', thirdRow, actualRowId)
      // handleChange('stransko_vodilo_desno-distancnik', 'TD-st. 55-75mm', thirdRow, actualRowId)

      handleReset('stransko_vodilo_levo', thirdRow, actualRowId)
      handleReset('stransko_vodilo_levo-barva', thirdRow, actualRowId)
      handleReset('stransko_vodilo_levo-distancnik', thirdRow, actualRowId)
    }

    // if (fourthRow) {
    //   let actualRowId = actualRows && actualRows[ind+3] && actualRows[ind+3].id
    //   // optionally reset fourth row
    //   let oldSecondOptionId = secondRow.rowValues[field.id]
    //   let oldSecondOption = allOptions.byId[oldSecondOptionId]
    //   let oldThirdOptionId = thirdRow.rowValues[field.id]
    //   let oldThirdOption = allOptions.byId[oldThirdOptionId]
      
    //   if ((oldSecondOption && oldSecondOption.text === 'Spojena 1/3')
    //     || (oldThirdOption && ['Spojena 1/2', 'Spojena 1/3'].includes(oldThirdOption.text))
    //   ){
    //     handleReset('spajanje_zaluzije', fourthRow, actualRowId)
    //     handleReset('stransko_vodilo_levo', fourthRow, actualRowId)
    //     handleReset('stransko_vodilo_levo-barva', fourthRow, actualRowId)
    //     handleReset('stransko_vodilo_levo-distancnik', fourthRow, actualRowId)
    //     handleReset('stransko_vodilo_desno', fourthRow, actualRowId)
    //     handleReset('stransko_vodilo_desno-barva', fourthRow, actualRowId)
    //     handleReset('stransko_vodilo_desno-distancnik', fourthRow, actualRowId)
    //   }
    // }

    // if (fifthRow) {
    //   let actualRowId = actualRows && actualRows[ind+4] && actualRows[ind+4].id
    //   // optionally reset fifth row
    //   let oldThirdOptionId = thirdRow.rowValues[field.id]
    //   let oldThirdOption = allOptions.byId[oldThirdOptionId]
      
    //   if (oldThirdOption && oldThirdOption.text === 'Spojena 1/3'){
    //     handleReset('spajanje_zaluzije', fifthRow, actualRowId)
    //     handleReset('stransko_vodilo_levo', fifthRow, actualRowId)
    //     handleReset('stransko_vodilo_levo-barva', fifthRow, actualRowId)
    //     handleReset('stransko_vodilo_levo-distancnik', fifthRow, actualRowId)
    //     handleReset('stransko_vodilo_desno', fifthRow, actualRowId)
    //     handleReset('stransko_vodilo_desno-barva', fifthRow, actualRowId)
    //     handleReset('stransko_vodilo_desno-distancnik', fifthRow, actualRowId)
    //   }
    // }

    // selecting 'enojno vodilo' in field 'stransko_vodilo_levo'
    // and passing handling to it ('stransko_vodilo_levo')
    // let svlField = allFieldsToRender.find(f => f.name === 'stransko_vodilo_levo')
    // if (svlField) {

    //   let optionId = svlField.optionsIds && svlField.optionsIds.find(oId => {
    //     let option = allOptions.byId[oId]
    //     return option.text === 'enojno vodilo'
    //   })
    //   if (optionId) {
    //     await handleStranskoVodiloLevo({
    //       field: svlField,
    //       newValueOptionId: optionId,
    //       rowsWithRenderDetails: rowsWithRenderDetailsRef.current,
    //       allFieldsToRender,
    //       ind, dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService,
    //       // productField,
    //       allOptions, createdOrderId, actualRows, addRows,
    //       rowsWithRenderDetailsRef
    //     })
    //   }
    // }
  }

  else if (selectedOption && selectedOption.text === 'Spojena 1/2'){

    if (!oldOption || (oldOption && oldOption.text === 'Samostojna')){
      await insertRows({quantity: 1, orderId: createdOrderId})
    }
    else if (oldOption && oldOption.text === 'Spojena 1/3'){
      thirdRow = rowsWithRenderDetailsRef.current[ind + 2]
      if (thirdRow){
        if (thirdRow.row && thirdRow.row.id)
          deleteRow(thirdRow.row.id)
      }
    }

    // console.log({oldOption})

    currentRow = rowsWithRenderDetailsRef.current[ind]
    secondRow = rowsWithRenderDetailsRef.current[ind + 1]
    thirdRow = rowsWithRenderDetailsRef.current[ind + 2]

    if (currentRow) {
      // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
      let actualRowId = currentRow && currentRow.row.id

      handleChange('stransko_vodilo_levo', 'enojno vodilo', currentRow, actualRowId)
      // handleChange('stransko_vodilo_levo-barva', 'RAL 9006', currentRow, actualRowId)
      // handleChange('stransko_vodilo_levo-distancnik', 'TD-st. 55-75mm', currentRow, actualRowId)

      // handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
      // handleChange('stransko_vodilo_desno-barva', 'RAL 9006', currentRow, actualRowId)
      // handleChange('stransko_vodilo_desno-distancnik', 'TD-st. 55-75mm', currentRow, actualRowId)
    }

    if (secondRow){
      // let secondOption = fieldDetails && fieldDetails.options && fieldDetails.options.find(o => o.text === 'Spojena 2/2')
      // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
      let actualRowId = secondRow && secondRow.row.id

      handleChange('spajanje_zaluzije', 'Spojena 2/2', secondRow, actualRowId)

      // handleChange('stransko_vodilo_desno', 'enojno vodilo', secondRow, actualRowId)
      // handleChange('stransko_vodilo_desno-barva', 'RAL 9006', secondRow, actualRowId)
      // handleChange('stransko_vodilo_desno-distancnik', 'TD-st. 55-75mm', secondRow, actualRowId)

      handleReset ('stransko_vodilo_levo', secondRow, actualRowId)
      handleReset ('stransko_vodilo_levo-barva', secondRow, actualRowId)
      handleReset ('stransko_vodilo_levo-distancnik', secondRow, actualRowId)
    }
  }

  else if (selectedOption && selectedOption.text === 'Samostojna'){

    currentRow = rowsWithRenderDetailsRef.current[ind]
    secondRow = rowsWithRenderDetailsRef.current[ind + 1]
    thirdRow = rowsWithRenderDetailsRef.current[ind + 2]

    let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
    // console.log({actualRowId, currentRow})

    if (currentRow){
    //   handleChange('stransko_vodilo_levo', 'enojno vodilo', currentRow, actualRowId)
    //   handleChange('stransko_vodilo_desno', 'enojno vodilo', currentRow, actualRowId)

      handleChange('stransko_vodilo_levo', 'enojno vodilo', currentRow, actualRowId)
    }
    
    // reseting old second and third row
    if (oldOption && oldOption.text === 'Spojena 1/3'){
      if (secondRow){
        if (secondRow.row && secondRow.row.id)
          deleteRow(secondRow.row.id)
        // handleReset ('spajanje_zaluzije', secondRow, actualRowId)
        // handleReset ('stransko_vodilo_desno', secondRow, actualRowId)
        // handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        // handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }
      
      if (thirdRow) {
        if (thirdRow.row && thirdRow.row.id){
          delay(100).then(() => deleteRow(thirdRow.row.id))
          // deleteRow(thirdRow.row.id)
        }
        // handleReset ('stransko_vodilo_desno', thirdRow, actualRowId)
        // handleReset('stransko_vodilo_desno-barva', thirdRow, actualRowId)
        // handleReset('stransko_vodilo_desno-distancnik', thirdRow, actualRowId)
        // handleReset ('spajanje_zaluzije', thirdRow, actualRowId)
      }
    }
    
    else if (oldOption && oldOption.text === 'Spojena 1/2'){
      if (secondRow){
        if (secondRow.row && secondRow.row.id)
          deleteRow(secondRow.row.id)

        // handleReset ('spajanje_zaluzije', secondRow, actualRowId)
        // handleReset ('stransko_vodilo_desno', secondRow, actualRowId)
        // handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        // handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }
    }
  }

  // selecting 'enojno vodilo' in field 'stransko_vodilo_levo'
  // and passing handling to it ('stransko_vodilo_levo')
  let svlField = allFieldsToRender.find(f => f.name === 'stransko_vodilo_levo')
  if (svlField) {

    let optionId = svlField.optionsIds && svlField.optionsIds.find(oId => {
      let option = allOptions.byId[oId]
      return option.text === 'enojno vodilo'
    })
    if (optionId) {
      await handleStranskoVodiloLevo({
        field: svlField,
        newValueOptionId: optionId,
        rowsWithRenderDetails: rowsWithRenderDetailsRef.current,
        allFieldsToRender,
        ind, dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService,
        // productField,
        allOptions, createdOrderId, actualRows, addRows,
        rowsWithRenderDetailsRef
      })
    }
  }
  
}