import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

const LoadingSpinner = ({style, visible = false}) => (
  <Fade in={visible} unmountOnExit={true}>
    <div 
      style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%',
        width: '100%',
        ...style
      }}
    >
      <CircularProgress />
    </div>
  </Fade>
)

export default LoadingSpinner