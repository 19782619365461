const arrayDiff = (a, b) => {
  let arr1 = [...a]
  let arr2 = [...b]

	let diffItems = arr2.filter(newItem => {
		let index = arr1.indexOf(newItem)
		if (index === -1){
			return true
		}
		
		arr1.splice(index, 1);
		return false
  })
  
	return diffItems
}

export {
  arrayDiff
}
