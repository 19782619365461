import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ChangePasswordDialog = ({
  isOpen = false,
  email,
  onConfirm
}) => (
  <Dialog
    open={isOpen}
    onClose={onConfirm}
    aria-labelledby="change-password-dialog-title"
    aria-describedby="change-password-dialog-description"
  >
    <DialogTitle id="change-password-dialog-title">{"Sprememba gesla"}</DialogTitle>
    
    <DialogContent>
      <DialogContentText id="change-password-dialog-description">
        Na vaš email ({email}) smo poslali povezavo na kateri lahko spremenite geslo.
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <Button onClick={onConfirm} color="primary">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
)

export default ChangePasswordDialog