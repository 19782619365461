import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
// import Icon from '@material-ui/core/Icon';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutline';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  menuItemActions: {
    // visibility: "hidden",
    color: 'lightgray',
    position: 'absolute',
    top: 0,
    right: 0,

    "-webkit-transition": "color 200ms linear",
    "-ms-transition": "color 200ms linear",
    "transition": "color 200ms linear"
  },
  menuItem: {
    cursor: 'unset',
    // "&:hover": {
      // backgroundColor: 'unset'
    // },
    // selected: {
    //   backgroundColor: 'red !important'
    // },
    "&:hover $menuItemActions": {
      // visibility: "inherit",
      color: 'black',
    }
  },
  selectedMenuItemActions: {
    color: 'black'
  },

  whiteBg: {
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white'
    },
  },
}));

const useDisabledStyles = makeStyles({
  root: {
    '&$disabled': {
      color: '#efefef',
    },
  },
  disabled: {}
})

const useOutlinedInputClasses = makeStyles({
  root: {
    '&$disabled $notchedOutline': {
      borderColor: '#efefef',
    },
  },
  notchedOutline: {},
  disabled: {}
})

const CountingMultiSelect = ({id, disabled, classes = {}, value, text, readOnly, renderValue, onChange, options}) => {

  classes = {...useStyles(), ...classes}
  let disabledClasses = useDisabledStyles()
  let outlinedInputClasses = useOutlinedInputClasses()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    if (options && options.length > 0)
      setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const addOption = (option) => {
    return () => {
      let newValue = [...value, option.id]
      onChange(newValue)
    }
  }
  const removeOption = (option) => {
    return () => {
      let index = value.findIndex(o => o === option.id)
      // console.log({index, value})
      if (index >= 0){
        let newValue = [...value.slice(0, index), ...value.slice(index+1)]
        onChange(newValue)
      }
    }
  }

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}

      <FormControl
        style={{
          minWidth: '240px',
          maxWidth: '320px',
          width: '100%',
          // opacity: disabled ? .25 : 1
        }}
        variant="outlined" 
        // margin="dense"
        // classes={{
        //   focused: classes.invisible
        // }}
      >
        <InputLabel
          disableAnimation={true}
          disabled={disabled}
          classes={{
            root: classes.smallLabel + " " + disabledClasses.root,
            focused: classes.invisible,

            disabled: disabledClasses.disabled
          }}
        >{(value && value.length > 0) ? '' : text}</InputLabel>

        <Select
          id={id}
          inputProps={{style:{padding: '5px'}, readOnly}}
          open={false}
        // margin="dense"
          value={value}
          // name={name}
          renderValue={renderValue}
          disabled={disabled}
          classes={{
            icon: disabled ? classes.disabled : classes.icon,
            // input: classes.smallPadding
            // root,marginNormal,marginDense,fullWidth
          }}
          // placeholder="asdf"
          input={<OutlinedInput
            // placeholder="asdf"
            labelWidth={0}
            // style={{padding: '5px'}}
            classes={{
              input: classes.smallPadding + " " + classes.whiteBg,

              root: outlinedInputClasses.root,
              notchedOutline: outlinedInputClasses.notchedOutline,
              disabled: outlinedInputClasses.disabled
            }}
          />}
          // onChange={() => {}}
          onOpen={handleClick}
          // MenuProps={{
          //   transitionDuration: 100,
          //   anchorOrigin: {
          //     vertical: 'bottom',
          //     horizontal: 'right',
          //   },
          //   transformOrigin: {
          //     vertical: 'top',
          //     horizontal: 'right',
          //   },
          //   getContentAnchorEl: null
          // }}
        >
          {/* {children} */}
        </Select>
      </FormControl>


      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={50}
      >
         {options.map((option, ind) => {
           let optionCount = value && value.filter(v => v === option.id).length
           let isSelected = optionCount > 0
            return (
              <MenuItem 
                disableRipple={true}
                style={{paddingRight: '96px'}}
                key={ind} 
                value={option.id}
                selected={isSelected}
                // onClick={() => (optionCount == 0) && addOption(option)}
                className={classes.menuItem}
                classes={{selected: classes.selectedMenuItem}}
              >
                <CounterNumber number={optionCount} />
                {option.text}
                <div className={classes.menuItemActions + ' ' + (isSelected ? classes.selectedMenuItemActions : '')}
                    // style={{color: (optionCount > 0) ? 'black' : null}}
                >
                  <AddButton onClick={addOption(option)} optionCount={optionCount}/>
                  <RemoveButton onClick={removeOption(option)} optionCount={optionCount}/>
                </div>
              </MenuItem>
            )
        })}
      </Popover>
    </div>
  );
}

const CounterNumber = ({number}) => {
  if (number <= 0)
    return null

  return (
    <span style={{fontWeight: 'bold', paddingRight: '12px' }}>
      {number}x
    </span>
  )
}

const AddButton = ({onClick, optionCount}) => {
  // if (optionCount <= 0)
  //   return null

  return (
    <Button
      // variant="outlined"
      // color="primary"
      size="small"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
      }}
      style={{
        minWidth: 42,
        color: 'inherit'
      }}
      // className={classes.button}
    >
      <AddCircleIcon />
    </Button>
  )
}

const RemoveButton = ({onClick, optionCount}) => {
  // if (optionCount <= 0)
  //   return null

  return (
    <Button
      // variant="outlined"
      // color="primary"
      disabled={optionCount === 0}
      size="small"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
      }}
      style={{
        minWidth: 48,
        color: (optionCount === 0) ? 'lightgray' : 'inherit'
      }}
      // className={classes.button}
    >
      <RemoveCircleIcon />
    </Button>
  )
}

export default CountingMultiSelect