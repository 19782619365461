import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNames from 'classnames'
import Popover from '@material-ui/core/Popover';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => {
  // console.log({theme})
  return {
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    accordionDetails: {
      flexWrap: 'wrap',
      // padding: 230
    },
    card: {
      cursor: 'pointer',
      // width: 230,
      marginRight: 8,
      marginBottom: 8,
      // minWidth: 'fit-content'
      minWidth: 114,
      minHeight: 56,
    },
    cardContent: {
      padding: 14,
      '&:last-child': {
        padding: 14
      },
      position: 'relative',
      height: '100%'
    },
    cardTitle: {
      margin: 0,
      top: '50%',
      left: '50%',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      whiteSpace: 'nowrap'
    },
    cardItem: {
      fontSize: 13,
      margin: 0
    },
    selected: {
      border: `2px solid ${theme.palette.primary.main}`
    },
    popover: {
      pointerEvents: 'none',
    }
  }
});

export default ({
  stickersData,
  selectedStickers,
  setSelectedStickers
}) => {

  const classes = useStyles();

  // console.log({selectedStickers})

  const [hoveredItem, setHoveredItem] = React.useState(null);

  if (!stickersData)
    return null

  const handlePopoverOpen = (target, position, itemList) => {
    console.log('open', event.currentTarget)
    setHoveredItem({
      anchorEl: target, position, itemList
    });
  };

  const handlePopoverClose = () => {
    console.log('close')
    setHoveredItem(null);
  };

  const open = Boolean(hoveredItem);

  return (
    <>
      {stickersData && stickersData.sequence && stickersData.sequence.map((title, ind) => {
        let woStickers = stickersData.stickers && stickersData.stickers[title]
        if (!woStickers)
          return null

        let allWOStickersSelected = woStickers && woStickers.every(s => selectedStickers[s.id])
          
        return (
          <Accordion key={ind}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                <Checkbox
                  color="primary"
                  checked={allWOStickersSelected}
                  onChange={e => {
                    let stickersIds = woStickers.reduce((acc, s) => {
                      acc[s.id] = !allWOStickersSelected
                      return acc
                    }, {...selectedStickers})
                    setSelectedStickers(stickersIds)
                  }}
                />}
                label={title}
              />
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {woStickers.map((s, ind) => {

                let itemList = s.items && Object.values(s.items).reduce((acc, valueObj) => {
                  for (const [name, value] of Object.entries(valueObj)) {
                    if (value)
                      acc = [...acc, {name, value}]
                  }
                  return acc
                }, [])

                // console.log({itemList})

                let isSelected = s.id && selectedStickers && selectedStickers[s.id]
                // return (
                //   <Card key={ind}
                //     onClick={() => setSelectedStickers(selected => ({...selected, [s.id]: !isSelected}))}
                //     className={classNames(classes.card, {
                //       [classes.selected]: isSelected
                //     })}
                //   >
                //     <CardContent className={classes.cardContent}>
                //       <Typography key="pos" className={classes.cardTitle} color="textSecondary" gutterBottom>
                //         {`Pozicija: `}<span style={{fontWeight: 'bold'}}>{ind+1}</span>
                //       </Typography>

                      // {itemList && itemList.map(({name, value}, ind) => (
                      //   <Typography key={ind} className={classes.cardItem} color="textSecondary" gutterBottom>
                      //     {`${name}: `}<span style={{fontWeight: 'bold'}}>{value}</span>
                      //   </Typography>
                      // ))}
                //     </CardContent>
                //   </Card>   
                // )

                return (
                  <Card key={ind}
                    onClick={() => setSelectedStickers(selected => ({...selected, [s.id]: !isSelected}))}
                    className={classNames(classes.card, {
                      [classes.selected]: isSelected
                    })}
                  >
                    <CardContent
                      className={classes.cardContent}
                      onMouseEnter={e => handlePopoverOpen(e.currentTarget, ind+1, itemList)}
                      onMouseLeave={handlePopoverClose}
                    >
                      <Typography key="pos" className={classes.cardTitle} color="textSecondary" gutterBottom>
                        {`Pozicija: `}<span style={{fontWeight: 'bold'}}>{ind+1}</span>
                      </Typography>
                    </CardContent>
                  </Card>   
                )
              })}
               
            </AccordionDetails>
          </Accordion>
        )
      })}

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        // classes={{
        //   paper: classes.paper,
        // }}
        open={open}
        anchorEl={hoveredItem && hoveredItem.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box p={2}>
          {hoveredItem && hoveredItem.itemList && hoveredItem.itemList.map(({name, value}, ind) => (
            <Typography key={ind} className={classes.cardItem} color="textSecondary" gutterBottom>
              {`${name}: `}<span style={{fontWeight: 'bold'}}>{value}</span>
            </Typography>
          ))}
        </Box>

        
      </Popover>
    </>
  )
}