const handleFieldChangeFromName = async ({
  targetFieldName,
  targetOptionText,
  rowWithDetails,
  createdOrderId, actualRowId,
  dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService, allFieldsToRender, allOptions
}) => {

  let field = allFieldsToRender.find(({name}) => name === targetFieldName)
  if (!field) return

  let newValueOptionId = field && field.optionsIds && field.optionsIds.find(oId => {
    let option = allOptions && allOptions.byId[oId]
    return option && (option.text === targetOptionText) && (option.fieldId === field.id)
  })

  let row = rowWithDetails.row
  // console.log({row})

  let existingValueObj = rowWithDetails.rowValuesObjects && rowWithDetails.rowValuesObjects[field.id]

  if (existingValueObj){

    // console.log({existingValueObj})
    // console.log(newValueOptionId, field.id, row, 'is-saving')
    // update
    // dispatchOrderActions(actions.selectOneUpdateValue(newValueOptionId, field.id, row, 'is-saving'))

    let selectedOption = allOptions.byId[newValueOptionId]
    dispatchOrderActions(actions.updateFieldValue({
      optionId: newValueOptionId,
      dirtyStatus: 'is-saving',
      ...((selectedOption && selectedOption.text) ? {text: selectedOption.text} : {})
    }, field.id, row))


    // console.log(existingValueObj)

    let newValueObj = {
      ...existingValueObj,
      customerOrderRowId: row.id,
      fieldOptionId: newValueOptionId 
    }
    ordersService.updateFieldValue(newValueObj)
      .then(() => dispatchOrderActions(actions.updateFieldValue({dirtyStatus: null}, field.id, row)))
    
  }
  else {
    // create
    let newValueObj = {
      type: 'SELECT_ONE',
      id: Math.random(),
      fieldId: field.id,
      optionId: newValueOptionId,
      dirtyStatus: 'is-saving'  
    }
    dispatchOrderActions(actions.selectOneCreateValue(
      field,
      // row, 
      row, 
      newValueObj
    ))

    let {id, optionId, ...withoutOptionIdAndId} = newValueObj

    // console.time("test");
    let {thisOrderId, thisRowId} = (await beforeFirstCreatedValue({redirectTimeout: null})) || {}
    actualRowId = actualRowId || thisRowId
    createdOrderId = createdOrderId || thisOrderId
    // console.timeEnd("test");

    // await delay(100)

    // await ordersService.createFieldValue({...withoutOptionIdAndId, customerOrderRowId: (actualRowId || row.id), fieldOptionId: newValueOptionId})
    ordersService.createFieldValue({...withoutOptionIdAndId, customerOrderRowId: (actualRowId || row.id), fieldOptionId: newValueOptionId})
      .then((createdFieldValue) => {
        // console.log('created', createdFieldValue, actualRowId)
        // if (!actualRowId)
          dispatchOrderActions(actions.selectOneUpdateValueWithNewId({...createdFieldValue, type: field.type}, newValueObj.id, field.id, row))
      })

  }
}

export default handleFieldChangeFromName