import orderApi from '../api/order'
import FileSaver from 'file-saver';
import {delay} from '../../utils/timing'

export function getList(page) {
  return orderApi.getList(page)
}
export function getListWithoutDrafts(page) {
  return orderApi.getListWithoutDrafts(page)
}

export function getById(id) {
  return orderApi.getById(id)
    .then(normalizeOrder)
}

function normalizeOrder (order) {

  let {rows: removedRows, ...orderWithoutRows} = order
  let updatedOrder = {...orderWithoutRows}
  // let updatedOrder = {...orderWithoutRows, rowsIds: []}

  let initialObject = {
    rows: {byId: {}, allIds: []},
    // values: {byId: {}, allIds: []}
    values: {byId: {}}
  }

  let {rows, values} = order && order.rows && order.rows.reduce((acc, row) => {

    // updatedOrder.rowsIds.push(row.id)

    let {fields, ...rowWithoutFields} = row

    acc.rows.byId[row.id] = {...rowWithoutFields, valuesIdsByFieldsIds: {}}
    acc.rows.allIds.push(row.id)

    if (fields){
      fields.forEach(value => {
        if (!acc.values.byId[value.id]){
          acc.values.byId[value.id] = value
          // acc.values.allIds.push(value.id)
          // let fieldId = null

          // // fake faieldIds
          // if (value.id === 1) fieldId = 1
          // if (value.id === 2) fieldId = 2
          // if (value.id === 3) fieldId = 3
          // if (value.id === 4) fieldId = 5

          if (value.fieldId){
            if (!acc.rows.byId[row.id].valuesIdsByFieldsIds[value.fieldId])
              acc.rows.byId[row.id].valuesIdsByFieldsIds[value.fieldId] = [value.id]
            else acc.rows.byId[row.id].valuesIdsByFieldsIds[value.fieldId].push(value.id)
          }
        }
      })
    }
    return acc
  }, initialObject)

  return {
    order: updatedOrder,
    rows,
    values
  }
}

const cleanWorkOrder = (workOrder) => {
  let updatedWorkOrder = null
  if (workOrder && workOrder.worksheetRows && workOrder.worksheetRows.rows){

    const allItems = workOrder.worksheetRows.rows.flat()
    // console.log({allItems})
    const normalizedItems = allItems.reduce((acc, item, index) => {
      if (item.label === 'Pozicija naročnika')
        return acc

      if (item.value){
        if (!acc[item.value]){
          acc[item.value] = {item, quantity: 1}
        }
        else {
          acc[item.value] = {...acc[item.value], quantity: acc[item.value].quantity +1}
        }
      }
      return acc
    }, {})

    // console.log({normalizedItems})

    updatedWorkOrder = {
      ...workOrder,
      worksheetRows: {
        items: normalizedItems
      }
    }
  }
  // console.log({updatedWorkOrder})
  return updatedWorkOrder || workOrder
}

// const groupedItemsObj = items.reduce((acc, item, index) => {
//   if (item.value){
//     if (!acc[item.value]){
//       acc[item.value] = {item, quantity: 1}
//     }
//     else {
//       acc[item.value] = {...acc[item.value], quantity: acc[item.value].quantity +1}
//     }
//   }
//   return acc
// }, {})

// const groupedItemsKeys = Object.keys(groupedItemsObj)

export function updateFieldValue(updatedFieldValue) {
  return orderApi.updateFieldValue(updatedFieldValue)
}

export function removeFieldValue(valueId) {
  return orderApi.removeFieldValue(valueId)
}

export function createFieldValue(createdFieldValue) {
  return orderApi.createFieldValue(createdFieldValue)
}

export function createRows(orderId, positionNumbers) {
  return orderApi.createRows(orderId, positionNumbers)
}

export function createOrder (productId, userId, status, deliveryDate) {
  return orderApi.createOrder(productId, userId, status, deliveryDate)
}

export function deleteOrder (orderId) {
  return orderApi.deleteOrder(orderId)
}

export function deleteRow (rowId) {
  return orderApi.deleteRow(rowId)
}
export function duplicateRow (rowId, times) {
  return orderApi.duplicateRow(rowId, times)
}
export function updateOrder (updatedOrder) {
  return orderApi.updateOrder(updatedOrder)
}
export function submitOrder (id) {
  return orderApi.submitOrder(id)
}
export function confirmOrder (id) {
  return orderApi.confirmOrder(id)
}

export function getAttachmentsList (id) {
  return orderApi.getAttachmentsList(id)
}
export function updateStatus (id, status) {
  return orderApi.updateStatus(id, status)
}
export function statusToIncomplete (id, message) {
  return orderApi.statusToIncomplete(id, message)
}

export function uploadFile(orderId, data){
  const uploadPromise = orderApi.uploadFile(orderId, data)
  const minimumUploadTimePromise = delay(1500)

  return Promise.all([uploadPromise, minimumUploadTimePromise])
}
export function downloadFile(orderId, fileName){
  return orderApi.downloadFile(orderId, fileName)
    .then(fileData => {
      FileSaver.saveAs(new Blob([fileData]), fileName);
    })
}
export function deleteFile(orderId, fileName){
  return orderApi.deleteFile(orderId, fileName)
}

export function createWorkOrders(orderId){
  return orderApi.createWorkOrders(orderId)
}
export function getWorkOrdersByOrderId(orderId){
  return orderApi.getWorkOrdersByOrderId(orderId)
    .then(workOrders => {
      // last 4 and unique
      let woObject = workOrders.slice(-4)
        .reverse()
        .reduce((acc, wo) => {
          if (!acc[wo.workOrderTypeId]) 
            acc[wo.workOrderTypeId] = wo
          return acc
        }, {})
        
      let wlList = Object.keys(woObject)
        .map(k => woObject[k])
        .sort((wo1, wo2) => wo1.workOrderTypeId - wo2.workOrderTypeId)
        .map((wo => {
          if (wo.workOrderTypeDescription === 'krmiljenje_in_elektronika')
            return cleanWorkOrder(wo)
          return wo
        }))

      return wlList
    })
}
export function getOriginalWorkOrdersByOrderId(orderId){
  return orderApi.getOriginalWorkOrdersByOrderId(orderId)
    .then(workOrders => {
      // first 4 and unique
      let woObject = workOrders.slice(0, 4)
        .reduce((acc, wo) => {
          if (!acc[wo.workOrderTypeId]) 
            acc[wo.workOrderTypeId] = wo
          return acc
        }, {})
        
      let wlList = Object.keys(woObject)
        .map(k => woObject[k])
        .sort((wo1, wo2) => wo1.workOrderTypeId - wo2.workOrderTypeId)
        .map((wo => {
          if (wo.workOrderTypeDescription === 'krmiljenje_in_elektronika')
            return cleanWorkOrder(wo)
          return wo
        }))

      return wlList
    })
}
export function updateWorkOrder(workOrder){
  return orderApi.updateWorkOrder(workOrder)
}

export function getErpCustomers(subject){
  return orderApi.getErpCustomers(subject)
}
export function getErpDataByOrderId(orderId){
  return orderApi.getErpDataByOrderId(orderId)
}
export function getErpDataByOrderIdTogether(orderId){
  return orderApi.getErpDataByOrderId(orderId)
    .then(erpData => {
      // console.log({erpData})
      return erpData && erpData.reduce((acc, woErpData) => {
          let erpOrderItems = woErpData.erpOrderItems
          erpOrderItems.forEach((item, ind) => {
            if (acc[ind]) {
              acc[ind] = {...acc[ind], erpOrderPartsList: [...acc[ind].erpOrderPartsList, ...item.erpOrderPartsList]}
            }
            else {
              acc[ind] = item
            }
          })

          return acc
        }, [])
    })
}

export function getStickersData(orderId){
  // return orderApi.getStickersData(orderId)
  return orderApi.getStickersData(orderId)
    .then(stickers => {
      return stickers.reduce((acc, sticker) => {
        let stickerTitle = sticker && sticker.header && sticker.header['sticker-title']
        if (stickerTitle) {
          if (!acc.stickers[stickerTitle]) {
            acc.stickers[stickerTitle] = []
            acc.sequence.push(stickerTitle)
          }
          acc.stickers[stickerTitle].push(sticker)
          return acc
        }
      }, {stickers: {}, sequence: [], originalData: stickers})
    })
}

export function printStickers(orderId, stickersIds){
  return orderApi.printStickers(orderId, stickersIds)
}

export default {
  getById,
  getList,
  getListWithoutDrafts,
  createOrder,
  deleteOrder,

  updateFieldValue,
  removeFieldValue,
  createFieldValue,
  
  createRows,
  deleteRow,
  duplicateRow,

  updateOrder,
  submitOrder,
  confirmOrder,
  getAttachmentsList,

  updateStatus,
  statusToIncomplete,
  uploadFile,
  downloadFile,
  deleteFile,
  createWorkOrders,
  getWorkOrdersByOrderId,
  getOriginalWorkOrdersByOrderId,
  updateWorkOrder,
  getErpCustomers,
  getErpDataByOrderId,
  getErpDataByOrderIdTogether,

  getStickersData,
  printStickers
}