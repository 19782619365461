import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {withRouter} from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import LanguageIcon from '@material-ui/icons/Language';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import Divider from '@material-ui/core/Divider';
// import Paper from '@material-ui/core/Paper';
// import { useTranslation } from 'react-i18next';

// import {useOrdersDispatch} from '../../../store/orders/OrdersContext'
// import {useOrdersActions} from '../../../store/orders/OrdersContext'

import ChangePasswordDialog from './ChangePasswordDialog';

// import userApi from '../../../services/api/user'
import userService from '../../../services/user'

//'ju.re.cekon@gmail.com'
const styles = theme => {
  // console.log(theme)
  return {
    root: {
      display: 'flex'    },
    appBar: {
      zIndex: theme.zIndex.drawer + 2,
      color: theme.palette.common.white,
    },
    clickableTitle: {
      cursor: 'pointer'
    },
    titleSeparator: {
      width: "1px",
      height: "48px",
      // backgroundColor: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.primary.customLight,
      marginLeft: theme.spacing(1)* 3,
      marginRight: theme.spacing(1)* 3
    },
    titleBackButtonSeparator1: {
      marginRight: 5
    },
    titleBackButtonSeparator2: {
      marginLeft: 5
    },
    content: {
      // flexGrow: 1,
      width: '100%'
    },
    toolbar: theme.mixins.toolbar,
    mainContent: {
      padding: theme.spacing(1)* 3,
    },
    grow: {
      flexGrow: 1,
    },
  }
};

const TitleSeparator = ({classes}) => <div className={classes} />

function SimpleLayout({
  title, 
  headerText = "",
  onTitleClick, 
  classes, 
  component: Component,
  history,
  backButtonEnabled = true,
  auth,
  mainRender,
  contentStyles = {},
  mainContentStyles = {},
  ...rest
}) {

  const [accountMenuEl, setAccountMenuEl] = useState(null)
  // const [languageMenuEl, setLanguageMenuEl] = useState(null)

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false)

  onTitleClick = onTitleClick || (() => history.push('/'))

  // const { t, i18n } = useTranslation();

  // title = title || t('Spletna Trgovina Sonal')
  title = 'Sonal'

  // const changeLanguage = React.useCallback((languageCode) => {
  //   i18n.changeLanguage(languageCode)
  //   setLanguageMenuEl(null)
  // })

  // let dispatchOrders = useOrdersDispatch()
  // console.log('Rendering SABlayout')

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <Toolbar
          // onClick={() => dispatchOrders({type: 'add', order: {status: 'osnutek'}})}
        >
          <Typography variant="h5" color="inherit" noWrap onClick={onTitleClick} className={classNames({
            [classes.clickableTitle]: !!onTitleClick
          })}>
            {title}
          </Typography>

          { backButtonEnabled ?
            <>
              <TitleSeparator classes={classNames(classes.titleSeparator, classes.titleBackButtonSeparator1)} />

              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={() => history.goBack()}
                className={classNames(classes.backButton)}
              >
                <ArrowBackIcon />
              </IconButton>

              <TitleSeparator classes={classNames(classes.titleSeparator, classes.titleBackButtonSeparator2)} />
            </>
            : 
            <TitleSeparator classes={classNames(classes.titleSeparator)} />
          }

        

          
          
          <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
            {headerText}
          </Typography>


          {/* <div> */}
          
            {/* <IconButton
              aria-haspopup="true"
              onClick={(e) => setLanguageMenuEl(e.currentTarget)}
              color="inherit"
            >
              <LanguageIcon />
            </IconButton> */}
            {/* <Menu
              anchorEl={languageMenuEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(languageMenuEl)}
              onClose={() => setLanguageMenuEl(null)}
            >
              <MenuItem onClick={() => changeLanguage('si')}>Slovensko</MenuItem>
              <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            </Menu> */}

            
          


          <Button
            id="user-dropdown-button"
            color="inherit"
            endIcon={<AccountCircle>send</AccountCircle>}
            aria-haspopup="true"
            onClick={(e) => setAccountMenuEl(e.currentTarget)}
          >
            {auth.user.login}
          </Button>


          {/* <Typography variant="subtitle1" color="inherit" noWrap >
            {auth.user.login}
          </Typography> */}
          {/* <IconButton
            aria-haspopup="true"
            onClick={(e) => setAccountMenuEl(e.currentTarget)}
            color="inherit"
          >
            <AccountCircle />
          </IconButton> */}
          <Menu
            anchorEl={accountMenuEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}

            open={Boolean(accountMenuEl)}
            onClose={() => setAccountMenuEl(null)}
          >

            <MenuItem id="ud-settings-item"
              onClick={() => {
                setAccountMenuEl(null)
                userService.requestPasswordReset(auth.user.email)
                  .then(() => setChangePasswordDialogOpen(true))
              }}
            >Spremeni geslo</MenuItem>

            <MenuItem id="ud-logout-item" onClick={auth.logout}>Odjava</MenuItem>
          </Menu>

          
        </Toolbar>
      </AppBar>
      
      <main className={classes.content} style={contentStyles}>
        <div className={classes.toolbar} />

        <div className={classes.mainContent} style={mainContentStyles}>
        
          {Component && <Component history={history} {...rest} auth={auth}/>}

          {mainRender && mainRender({history})}

        </div>
      </main>

      <ChangePasswordDialog
        email={auth.user.email}
        isOpen={changePasswordDialogOpen} onConfirm={() => {
          setChangePasswordDialogOpen(false)
        }}
      />

    </div>
  );
}

SimpleLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SimpleLayout))
