import moment from "moment";

const getRowDefinition = function (labelName, columnsObjects) {
  let rowItems = columnsObjects.map((column) => column[labelName]);

  return [
    {
      noWrap: false,
      text: labelName,
    },
    // ...rowItems.map(item => item.value)
    // ...rowItems.map(item => (item && item.value) ? item.value : '')
    ...rowItems.map((item) => item || ""),
  ];
};
//
const generatePdfDefinition = function ({
  orderData,
  workOrderTypeText,
  numberOfColumnsPerPage,
  workOrder,
}) {
  const { rows } = workOrder.worksheetRows;
  const { allIds } = orderData.rows;

  const allColumns = rows;
  const Ids = allIds.slice(0, allColumns.length);

  let uniqueLabels = new Set();
  let labelsObjectsArray = [];
  const columnsAsObjects = allColumns.map((col) => {
    return col.reduce((colObj, { label, value, columnNum }) => {
      if (!uniqueLabels.has(label)) {
        labelsObjectsArray.push({ label, columnNum });
        uniqueLabels.add(label);
      }
      return { ...colObj, [label]: value };
    }, {});
  });
  let labelsArray = labelsObjectsArray
    .sort((a, b) => a.columnNum - b.columnNum)
    .map((labelObj) => labelObj.label);

  // console.log({columnsAsObjects, labelsArray, labelsObjectsArray})

  let getRows = function (offset = 0) {
    let columnsObjectsToDisplay = columnsAsObjects.slice(
      offset,
      offset + numberOfColumnsPerPage
    );
    //console.log({ columnsObjectsToDisplay });

    return (
      labelsArray &&
      labelsArray.map((labelName) => {
        return getRowDefinition(labelName, columnsObjectsToDisplay);
      })
    );
  };

  var getSectionDefinition = function (offset, pageBreak) {
    let columnsToDisplay = allColumns.slice(
      offset,
      offset + numberOfColumnsPerPage
    );

    let idsToDisplay = Ids.slice(offset, offset + numberOfColumnsPerPage);

    let rowNamePercentageWidth = 30;
    let columnPercentageWidth = (100 - rowNamePercentageWidth) / 5;
    // console.log({columnPercentageWidth})
    return [
      {
        table: {
          // widths: [140, ...columnsToDisplay.map(() => "*")],
          // widths: [140, ...columnsToDisplay.map(() => 64)],
          widths: [
            `${rowNamePercentageWidth}%`,
            ...columnsToDisplay.map(() => `${columnPercentageWidth}%`),
          ],
          body: [
            [
              { text: "", style: "tableHeader" },
              ...columnsToDisplay.map((c, ind) => ({
                text: `${offset + ind + 1}.`,
                style: "tableHeader",
              })),
            ],
            ...getRows(offset),
            [
              { text: "Qr Kode" },
              ...idsToDisplay.map((id, i) => {
                return {
                  qr: `${id}`,
                  fit: 50,
                };
              }),
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0.6;
          },
          vLineWidth: function (i, node) {
            return 0.6;
          },
          hLineColor: function (i, node) {
            return "#d3d3d3";
          },
          vLineColor: function (i, node) {
            return "#d3d3d3";
          },
        },
        pageBreak: pageBreak ? "after" : null,
      },
    ];
  };

  let numberOfSections = Math.ceil(allColumns.length / numberOfColumnsPerPage);

  let allSections = Array(numberOfSections)
    .fill()
    .map((n, ind) => {
      let pageBreak = ind + 1 < numberOfSections;
      return getSectionDefinition(ind * numberOfColumnsPerPage, pageBreak);
    });

  let docDefinition = {
    footer: function (currentPage, pageCount) {
      let footerPageText = currentPage.toString() + " / " + pageCount;
      return {
        columns: [{ text: footerPageText, alignment: "center" }],
      };
    },
    content: [
      {
        text: `Delovni nalog - ${workOrderTypeText} (${orderData.order.productName})`,
        bold: true,
      },
      "\n",
      {
        alignment: "justify",
        columns: [
          {
            text: [
              { text: "Referenčna številka: ", style: { color: "gray" } },
              `${orderData.order.referenceId || ""}`,
            ],
          },
          {
            text: [
              { text: "Objekt/Lokacija: ", style: { color: "gray" } },
              `${orderData.order.location || ""}`,
            ],
          },
          {
            text: [
              { text: "Stranka: ", style: { color: "gray" } },
              `${orderData.order.finalCustomer || ""}`,
            ],
          },
        ],
      },
      "\n",
      {
        alignment: "justify",
        columns: [
          {
            text: [
              { text: "Željen datum dobave: ", style: { color: "gray" } },
              `${
                orderData.order.deliveryDate
                  ? moment(orderData.order.deliveryDate).format("DD.MM.YYYY")
                  : ""
              }`,
            ],
          },
          {
            text: [
              { text: "Opomba: ", style: { color: "gray" } },
              `${orderData.order.notice || ""}`,
            ],
          },
          {
            text:
              orderData.order.documents && orderData.order.documents.length
                ? [
                    { text: "Št. datotek: ", style: { color: "gray" } },
                    `${orderData.order.documents.length || ""}`,
                  ]
                : { text: "Ni priloženih datotek ", style: { color: "gray" } },
          },
        ],
      },
      "\n",
      ...allSections,
    ],
    styles: {
      tableHeader: {
        bold: true,
      },
    },
    defaultStyle: {
      fontSize: 9,
      columnGap: 20,
    },
  };

  return docDefinition;
};

export default generatePdfDefinition;
