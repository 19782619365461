import handleFieldChangeFromName from '../handleFieldChangeFromName'
import handleFieldResetFromName from '../handleFieldResetFromName'

export default async ({
  field, newValueOptionId, allFieldsToRender,
  rowsWithRenderDetails, ind, dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService,
  // productField,
  allOptions, createdOrderId, actualRows, addRows,
  rowsWithRenderDetailsRef
}) => {

  // let currentRow = rowsWithRenderDetails[ind]
  // let secondRow = rowsWithRenderDetails[ind + 1]
  // let thirdRow = rowsWithRenderDetails[ind + 2]
  // // let fourthRow = rowsWithRenderDetails[ind + 3]
  // // let fifthRow = rowsWithRenderDetails[ind + 4]

  let currentRow = rowsWithRenderDetailsRef.current[ind]
  let secondRow = rowsWithRenderDetailsRef.current[ind + 1]
  let thirdRow = rowsWithRenderDetailsRef.current[ind + 2]

  let selectedOption = allOptions.byId[newValueOptionId]
  if (!selectedOption)
    return

  let oldOptionId = currentRow.rowValues[field.id]
  let oldOption = allOptions.byId[oldOptionId]
  // console.log('stranskoVodiloLevo')

  let conditionField = allFieldsToRender.find(f => {
    return (f.name === 'spajanje_zaluzije')
  })
  let valueObj = currentRow && currentRow.rowValuesObjects && currentRow.rowValuesObjects[conditionField.id]
  // console.log({valueObj, conditionField})

  if (!valueObj)
    return

  let conditionOption = allOptions.byId[valueObj.optionId]
  let conditionOptionText = conditionOption && conditionOption.text

  if (!conditionOptionText)
    return
  
  // console.log({currentRow, secondRow, thirdRow, fourthRow, fifthRow})
  
  // let fieldDetails = currentRow && currentRow.fieldDetailsByFieldId && currentRow.fieldDetailsByFieldId[field.id]

  let handleChange = (targetFieldName, targetOptionText, rowWithDetails, actualRowId) => {
    handleFieldChangeFromName({
      targetFieldName,
      targetOptionText,
      rowWithDetails,
      actualRowId,
      
      createdOrderId,
      dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService, allFieldsToRender, allOptions
    })
  }

  let handleReset = (targetFieldName, rowWithDetails, actualRowId) => {
    handleFieldResetFromName({
      targetFieldName,
      rowWithDetails,
      actualRowId,
      
      createdOrderId,
      dispatchOrderActions, actions, beforeFirstCreatedValue, ordersService, allFieldsToRender, allOptions
    })
  }

  if (conditionOptionText === 'Spojena 1/3') {
    // console.log("1/3", conditionOptionText, selectedOption.text, selectedOption)

    if (selectedOption.text === 'enojno vodilo') {
        // handling first (Spojena 1/3) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/3) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }

      // // handling third (Spojena 3/3) row
      if (thirdRow){
        // let thirdOption = fieldDetails && fieldDetails.options && fieldDetails.options.find(o => o.text === 'Spojena 3/3')
        // let actualRowId = actualRows && actualRows[ind+2] && actualRows[ind+2].id
        let actualRowId = thirdRow && thirdRow.row.id

        handleChange('stransko_vodilo_desno', 'enojno vodilo', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', thirdRow, actualRowId)
      }
    }
    
    else if (selectedOption.text === 'MultiBox 1/1') {
      // handling first (Spojena 1/3) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/3) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }

      // // handling third (Spojena 3/3) row
      if (thirdRow){
        // let thirdOption = fieldDetails && fieldDetails.options && fieldDetails.options.find(o => o.text === 'Spojena 3/3')
        // let actualRowId = actualRows && actualRows[ind+2] && actualRows[ind+2].id
        let actualRowId = thirdRow && thirdRow.row.id

        handleChange('stransko_vodilo_desno', 'MultiBox 1/1', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', thirdRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'MultiBox 1/1/1') {
      // handling first (Spojena 1/3) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/3) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }

      // // handling third (Spojena 3/3) row
      if (thirdRow){
        // let thirdOption = fieldDetails && fieldDetails.options && fieldDetails.options.find(o => o.text === 'Spojena 3/3')
        // let actualRowId = actualRows && actualRows[ind+2] && actualRows[ind+2].id
        let actualRowId = thirdRow && thirdRow.row.id

        handleChange('stransko_vodilo_desno', 'MultiBox 1/1/1', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', thirdRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'Multibox 1/1/2') {
      // handling first (Spojena 1/3) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/3) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }

      // // handling third (Spojena 3/3) row
      if (thirdRow){
        // let thirdOption = fieldDetails && fieldDetails.options && fieldDetails.options.find(o => o.text === 'Spojena 3/3')
        // let actualRowId = actualRows && actualRows[ind+2] && actualRows[ind+2].id
        let actualRowId = thirdRow && thirdRow.row.id

        handleChange('stransko_vodilo_desno', 'Multibox 1/1/2', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', thirdRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'MultiBox 1/1/3') {
      // handling first (Spojena 1/3) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/3) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }

      // // handling third (Spojena 3/3) row
      if (thirdRow){
        // let thirdOption = fieldDetails && fieldDetails.options && fieldDetails.options.find(o => o.text === 'Spojena 3/3')
        // let actualRowId = actualRows && actualRows[ind+2] && actualRows[ind+2].id
        let actualRowId = thirdRow && thirdRow.row.id

        handleChange('stransko_vodilo_desno', 'MultiBox 1/1/3', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', thirdRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'Brez') {
      // handling first (Spojena 1/3) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/3) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }

      // // // handling third (Spojena 3/3) row
      if (thirdRow){
        // let thirdOption = fieldDetails && fieldDetails.options && fieldDetails.options.find(o => o.text === 'Spojena 3/3')
        // let actualRowId = actualRows && actualRows[ind+2] && actualRows[ind+2].id
        let actualRowId = thirdRow && thirdRow.row.id

        handleReset('stransko_vodilo_desno-barva', thirdRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', thirdRow, actualRowId)
      }
    }
  }
  
  if (conditionOptionText === 'Spojena 1/2') {
    // console.log("1/2", conditionOptionText, selectedOption.text, selectedOption)

    if (selectedOption.text === 'enojno vodilo') {
        // handling first (Spojena 1/2) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/2) row
      if (secondRow){
        // let thirdOption = fieldDetails && fieldDetails.options && fieldDetails.options.find(o => o.text === 'Spojena 3/3')
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleChange('stransko_vodilo_desno', 'enojno vodilo', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }
    }
    
    else if (selectedOption.text === 'MultiBox 1/1') {
      // handling first (Spojena 1/2) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/2) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleChange('stransko_vodilo_desno', 'MultiBox 1/1', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'MultiBox 1/1/1') {
      // handling first (Spojena 1/2) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/2) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleChange('stransko_vodilo_desno', 'MultiBox 1/1/1', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'Multibox 1/1/2') {
      // handling first (Spojena 1/2) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/2) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleChange('stransko_vodilo_desno', 'Multibox 1/1/2', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'MultiBox 1/1/3') {
      // handling first (Spojena 1/2) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'dvojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/2) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleChange('stransko_vodilo_desno', 'MultiBox 1/1/3', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'Brez') {
      // handling first (Spojena 1/2) row
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }

      // handling second (Spojena 2/2) row
      if (secondRow){
        
        // let actualRowId = actualRows && actualRows[ind+1] && actualRows[ind+1].id
        let actualRowId = secondRow && secondRow.row.id

        handleReset('stransko_vodilo_desno-barva', secondRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', secondRow, actualRowId)
      }
    }
  }

  else if (conditionOptionText === 'Samostojna'){

    if (selectedOption.text === 'enojno vodilo') {
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'enojno vodilo', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }
    }
    
    else if (selectedOption.text === 'MultiBox 1/1') {
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'MultiBox 1/1', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'MultiBox 1/1/1') {
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'MultiBox 1/1/1', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'Multibox 1/1/2') {
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'Multibox 1/1/2', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'MultiBox 1/1/3') {
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleChange('stransko_vodilo_desno', 'MultiBox 1/1/3', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }
    }

    else if (selectedOption.text === 'Brez') {
      if (currentRow) {
        // let actualRowId = actualRows && actualRows[ind] && actualRows[ind].id
        let actualRowId = currentRow && currentRow.row.id

        handleReset('stransko_vodilo_levo-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_levo-distancnik', currentRow, actualRowId)

        handleReset('stransko_vodilo_desno-barva', currentRow, actualRowId)
        handleReset('stransko_vodilo_desno-distancnik', currentRow, actualRowId)
      }
    }
  }
  
}