function updateFieldValue(updateData, fieldId, row) {
  return {
    type: 'UPDATE_FIELD_VALUE',
    updateData,
    fieldId,
    row
  }
}

function updateFieldValueWithNewId(createdFieldValue, oldId, fieldId, row) {
  return {
    type: 'UPDATE_FIELD_VALUE_NEW_ID',
    createdFieldValue,
    oldId,
    fieldId,
    row
  }
}

function addFieldValue(valueObj, fieldId, row) {
  return {
    type: 'ADD_FIELD_VALUE',
    valueObj,
    fieldId,
    row
  }
}

function removeFieldValue(fieldId, row) {
  return {
    type: 'REMOVE_FIELD_VALUE',
    fieldId,
    row
  }
}

function selectOneUpdateValue(value, fieldId, row, dirtyStatus) {
  return {
    type: 'SELECT_ONE_UPDATE_VALUE',
    value,
    fieldId,
    row,
    dirtyStatus
  }
}

function selectOneCreateValue(field, row, valueObj) {
  return {
    type: 'SELECT_ONE_CREATE_VALUE',
    field,
    row,
    valueObj
  }
}

function selectOneUpdateValueWithNewId(createdFieldValue, oldId, fieldId, row) {
  return {
    type: 'SELECT_ONE_UPDATE_VALUE_NEW_ID',
    createdFieldValue, 
    oldId, 
    fieldId, 
    row
  }
}

function selectMultipleCreateValue(fieldId, row, valueObj) {
  return {
    type: 'SELECT_MULTIPLE_CREATE_VALUE',
    fieldId,
    row,
    valueObj
  }
}
function selectMultipleRemoveFieldValue(fieldId, row, valueObjToRemove) {
  return {
    type: 'SELECT_MULTIPLE_REMOVE_VALUE',
    fieldId,
    row,
    valueObjToRemove
  }
}
function selectMultipleUpdateValueWithNewId(createdFieldValue, oldId, fieldId, row) {
  return {
    type: 'SELECT_MULTIPLE_UPDATE_VALUE_NEW_ID',
    createdFieldValue, 
    oldId, 
    fieldId, 
    row
  }
}
function updateOrderAttribute(attributeName, value) {
  return {
    type: 'UPDATE_ORDER_ATTRIBUTE',
    attributeName,
    value
  }
}


export default {
  updateFieldValue,
  updateFieldValueWithNewId,
  addFieldValue,
  removeFieldValue,
  selectOneUpdateValue,
  selectOneUpdateValueWithNewId,
  selectOneCreateValue,
  selectMultipleCreateValue,
  selectMultipleRemoveFieldValue,
  selectMultipleUpdateValueWithNewId,
  updateOrderAttribute
}