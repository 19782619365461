import React, {useRef} from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress';
import orderService from '../../../../../services/order'

export default ({
  finalCustomer,
  textChangeHandler,
  onTextFieldChange,
  onTextFieldBlur,
  readOnlyFields,
  renderErpSelect
}) => {

  // const finalCustomerRef = useRef(finalCustomer);

  // console.log(finalCustomer === finalCustomerRef.current, finalCustomer, finalCustomerRef.current )
  
  return renderErpSelect ? (
    <ErpSelect
      finalCustomer={finalCustomer}
      onTextFieldChange={onTextFieldChange}
      onTextFieldBlur={onTextFieldBlur}
    />
  ) : (
    <TextField fullWidth
      id="finalCustomer"
      label="Stranka"
      margin="none"
      onChange={textChangeHandler('finalCustomer')}
      onBlur={() => onTextFieldBlur('finalCustomer')}
      value={finalCustomer || ''}
      InputProps={{readOnly: readOnlyFields}}
    />
  )
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const getErpOptions = async (subject) => {
  let options = await orderService.getErpCustomers(subject)
  // console.log({options})
  // await sleep(700)
  return options || []
}

const ErpSelect = ({
  finalCustomer,
  onTextFieldChange,
  onTextFieldBlur,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false)

  // const [selectValue, setSelectValue] = React.useState(finalCustomer && {subject: finalCustomer});
  const [selectValue, setSelectValue] = React.useState(finalCustomer && {subject: finalCustomer});
  const [inputValue, setInputValue] = React.useState(finalCustomer || '');

  return (
    <Autocomplete
      id="asynchronous-demo"
      inputValue={inputValue}
      value={selectValue}
      open={open}
      // onOpen={() => {
        // setOpen(true);
      // }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={async (e, value, reason) => {
        // console.log(reason, value)
        setInputValue(value)
        if (reason === 'input') {
          // setOptions([])

          setLoading(true)
          let fetchedOptions = await getErpOptions(value)
          // if (active) {
          // console.log('setting...')
          setOptions(fetchedOptions);
          setLoading(false)
            
          
          if (value !== '') {
            setOpen(true)
            
          }
          else setOpen(false);
        }
      }}
      onChange={async (e, value, reason) => {
        setSelectValue(value)
        if (value && value.subject) {
          onTextFieldChange('finalCustomer', value.subject)
          // console.log(value.subject, value)
          await sleep(200)
          // console.log('blur')
          onTextFieldBlur('finalCustomer')
        }
      }}
      getOptionSelected={(option, value) => option.subject === value.subject}
      getOptionLabel={(option) => option.subject}
      options={options}
      loading={loading}
      loadingText="Nalaganje..."
      noOptionsText="Ni ujemanj"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Stranka"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}