import React from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/EditOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';

import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
// import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import ErrorIcon from '@material-ui/icons/Error';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import PrintIcon from '@material-ui/icons/Print';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import MoreVertIcon from '@material-ui/icons/MoreVert';
// import TextField from '@material-ui/core/TextField';
// import { KeyboardDatePicker } from "@material-ui/pickers";
// import { DatePicker } from "@material-ui/pickers";
import Menu from '@material-ui/core/Menu';
import moment from 'moment'

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {useAuth} from '../../../AuthContext'

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
// import Popper from '@material-ui/core/Popper';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import {
  usePopupState,
  bindTrigger,
  bindPopover,
  anchorRef
} from 'material-ui-popup-state/hooks'

import FileBrowserDialog from '../FileBrowserDialog'

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const renderStatusText = (status, isOnlyUser) => {
  let icon = null
  let color = 'black'
  let statusText = ''

  if (status === 'DRAFT'){
    color = 'gray'
    // icon = <EditIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
    statusText = 'Osnutek'
  }

  if (status === 'PENDING'){
    color = 'royalblue'
    icon = <DoneIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
    statusText = 'Oddano'
  }
  if (status === 'REJECTED'){
    color = 'red'
    icon = <ErrorIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
    statusText = 'Nepopolno'
  }
  if (status === 'CONFIRMED'){
    color = 'green'
    icon = <DoneAllIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
    statusText = 'Potrjeno'
  }


  if (status === 'TECH_CHECKED'){
    color = 'green'
    statusText = 'Tehnično potrjeno'
    if (isOnlyUser){
      icon = <DoneAllIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
      statusText = 'Potrjeno'
    }
  }
  if (status === 'IN_PROGRESS'){
    color = 'green'
    statusText = 'V izdelavi'
    if (isOnlyUser){
      icon = <DoneAllIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
      statusText = 'Potrjeno'
    }
  }
  if (status === 'FINISHED'){
    color = 'green'
    statusText = 'Končano'
    if (isOnlyUser){
      icon = <DoneAllIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
      statusText = 'Potrjeno'
    }
  }
  if (status === 'DELIVERED'){
    color = 'green'
    statusText = 'Dobavljeno'
    if (isOnlyUser){
      icon = <DoneAllIcon style={{verticalAlign: 'top', marginLeft: '5px'}}/>
      statusText = 'Potrjeno'
    }
  }

  return (
    <span align="right" style={{color}}>{statusText}{icon}</span>
  )
}

const renderCreatedByText = (userName) => (<>
  <span style={{ color: "#757575" }}>Uporabnik: </span>
  {userName}
</>)
const renderUserCompanyText = (company, userName) => (<>
  <span style={{ color: "#757575" }}>Naročnik: </span>
  {company}
  {userName && <span style={{ color: "#757575" }}>{` (${userName})`}</span>}
</>)

// const aditionalActionOptions = [
//   'DRAFT',
//   'PENDING',
//   'REJECTED',
//   'CONFIRMED',
//   'IN_PROGRESS',
//   'FINISHED',
//   'DELIVERED'
// ]

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  moreButton: {
    marginLeft: theme.spacing(2),
  },
  statusText: {
    // flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  companyText: {
    marginLeft: '2em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexShrink: 4
  },
  title: {
    marginRight: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  fieldsContainer: {
    // padding: theme.spacing(2),
    padding: theme.spacing(2, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3)
    }
  }
}));

const Pagination = ({page, pageCount, goNext, goPrevious}) => {

  return (<>
      <Typography variant="subtitle1" style={{whiteSpace: 'nowrap'}}>
        {'Stran ' + page + ' / ' + pageCount}
      </Typography>
    
      <div style={{marginLeft: 12, whiteSpace: 'nowrap'}}>
        <IconButton disabled={page <= 1} onClick={goPrevious}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton disabled={page >= pageCount} onClick={goNext}>
          <ChevronRightIcon />
        </IconButton>
      </div>
    
  </>)
}

const GeneralInfo = ({
  order, product, onDeleteOrderClick, onConfirmOrderClick, history,
  onStatusChange, onPrintClick,
  saveEnabled, onSave, showOriginal, onShowOriginalClick, hasChanged
}) => {

  const classes = useStyles();

  const [moreMenuAnchorEl, setMMAnchorEl] = React.useState(null);
  // const [datepickerOpen, setDatepickerOpen] = React.useState(false);

  const handleMoreClick = event => {
    setMMAnchorEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setMMAnchorEl(null);
  };

  const handleDeleteOrder = () => {
    onDeleteOrderClick(order.id);
    setMMAnchorEl(null)
  };

  const [noticePopoverAnchor, setNPA] = React.useState(null);
  const [fileModalOpen, setFileModalOpen] = React.useState(false);

  const actionsMenuState = usePopupState({ variant: 'popper', popupId: 'actionsMenu' })

  const theme = useTheme();
  const mediaMD = useMediaQuery(theme.breakpoints.up('md'));

  const {isUser, isAdmin, isManager, isOperator, user} = useAuth()

  // const canConfirmOrder = isAdmin || isManager || isOperator
  // const canEditAndDelete = user.login === order.userLogin
  const isOnlyUser = isUser && !isOperator && !isManager && !isAdmin
  // console.log({authUser, userLogin: order.userLogin})


  // let noticeOneRowValue = order.notice ? order.notice.split('\n').join(' ') : ''
  let orderHasDocuments = order.documents && order.documents.length > 0

  return (
    <div >
      <AppBar position="relative" color="default" elevation={0}>
        <Toolbar variant="dense">
          <Typography variant="h6" component="h6" className={classes.title}>
            {product.productTypeName} - {product.name}
          </Typography>

          <Typography
            variant="subtitle1"
            component="h6"
            className={classes.statusText}
          >
            <span style={{ color: "#757575" }}>Status: </span>
            {renderStatusText(order.status, isOnlyUser)}
          </Typography>

          <Typography
            variant="subtitle1"
            component="h6"
            className={classes.companyText}
          >
             {isOnlyUser ?
              renderCreatedByText(order.userName)
              : renderUserCompanyText(order.userCompany, order.userName)
            }
          </Typography>

          <div style={{flexGrow: 1, width: '1em'}}></div>

          {/* <Pagination 
            page={currentPageIndex+1}
            pageCount={pagesCount}
            goNext={() => {
              if (currentPageIndex < (currentPageIndex+1))
                setCurrentPageIndex(currentPageIndex+1)
            }}
            goPrevious={() => {
              if (currentPageIndex > 0)
                setCurrentPageIndex(currentPageIndex-1)
            }}
          /> */}

          {/* <Button variant="outlined" onClick={onShowOriginalClick} >
            {}
          </Button> */}

          {showOriginal && (
            <Typography variant="subtitle1" style={{whiteSpace: 'nowrap', color: 'black', fontWeight: 500, fontSize: 16, marginRight: 12}}>ORIGINAL</Typography>
          )}

          {(hasChanged || showOriginal) && (
            <FormControlLabel
              value="show-original"
              control={<Checkbox color="primary" color="primary" checked={showOriginal} onChange={onShowOriginalClick} />}
              label="Prikaži original"
              labelPlacement="end"
            />
          )}
          
          
          <Button variant="outlined" onClick={onSave} disabled={!saveEnabled} startIcon={<SaveIcon color={saveEnabled ? "primary" : "disabled"}/>} >
            {'Shrani'}
          </Button>
        </Toolbar>
      </AppBar>

      <div className={classes.fieldsContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" style={{overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              <span style={{ color: "#757575" }}>Referenčna številka: </span>
              {order.referenceId || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" style={{overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              <span style={{ color: "#757575" }}>Objekt/Lokacija: </span>
              {order.location || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" style={{overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              <span style={{ color: "#757575" }}>Stranka: </span>
              {order.finalCustomer || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" style={{overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              <span style={{ color: "#757575" }}>Željen datum dobave: </span>
              {order.deliveryDate &&
                moment(order.deliveryDate).format("DD.MM.YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography
              onMouseEnter={(e) => {
                setNPA(e.currentTarget)
              }}
              onMouseLeave={() => {
                setNPA(null)
              }}
              variant="subtitle1"
              style={{overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
            >
              <span style={{ color: "#757575" }}>Opomba: </span>
              {order.notice || ""}
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={12} md={4} >
            {orderHasDocuments ?
              <>
                <Grid item xs={6} style={{display: orderHasDocuments ? 'flex' : 'none'}}>
                  <Typography variant="subtitle1" component="h6" style={{cursor: 'pointer'}} onClick={() => setFileModalOpen(true)}>
                    <span style={{ color: "#757575" }}>Št. datotek: </span>{(order.documents && order.documents.length) || 0}
                  </Typography>
                </Grid>
                <Grid item xs={orderHasDocuments ? 6 : 12}>
                  <Button style={{marginTop: -8}} disabled={!order.id} variant="outlined" fullWidth component="span" startIcon={<FileIcon color={order.id ? "primary" : "disabled"}/>} onClick={() => setFileModalOpen(true)}>
                    {'Datoteke'}
                  </Button>
                </Grid>
              </>
            :
              <Typography variant="subtitle1" component="h6" >
                <span style={{ color: "#757575" }}>Ni priloženih datotek </span>
              </Typography>
            }
          </Grid>
        </Grid>
      </div>
      
      
      <Menu
        id="order-more-menu"
        anchorEl={moreMenuAnchorEl}
        keepMounted
        open={Boolean(moreMenuAnchorEl)}
        onClose={handleMoreClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuItem onClick={handleDeleteOrder}>Izbriši naročilo</MenuItem>
      </Menu>

      <FileBrowserDialog
        readOnly
        open={fileModalOpen}
        order={order}
        orderHasDocuments={orderHasDocuments}
        onClose={() => setFileModalOpen(false)}
      />

      <Popper
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(noticePopoverAnchor && order.notice && order.notice !== "")}
        anchorEl={noticePopoverAnchor}
        onClose={() => {
          console.log('on close')
          setNPA(null)
        }}
      >
        <Paper style={{padding: 16}}>
          <Typography component="pre">{order.notice}</Typography>
        </Paper>
      </Popper>  

      
    </div>
  );
}

export default GeneralInfo