import React from 'react'
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  // root: {
  //   backgroundColor: 'white',root
  //   'fieldset': {
  //     backgroundColor: 'red',
  //   },
  // },

  whiteBg: {
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white'
    }
  }
});

const useDisabledStyles = makeStyles({
  root: {
    '&$disabled': {
      color: '#efefef',
    },
  },
  disabled: {}
})

const useOutlinedInputClasses = makeStyles({
  root: {
    '&$disabled $notchedOutline': {
      borderColor: '#efefef',
    },
  },
  notchedOutline: {},
  disabled: {}
})


const OutlinedSelect = ({id, value, text, readOnly, name, disabled, classes, onChange, onOpen, onClose, children}) => {
  // const labelEl = useRef(null);
  // const [labelWidth, setLabelWidth] = useState(0);

  // useEffect(() => {
  //   let node = ReactDOM.findDOMNode(labelEl.current)
  //   // console.log(node.offsetWidth)
  //   setLabelWidth(node.offsetWidth)
  // }, [text])  
  // let selectRef = React.createRef();

  let outlineSelectClasses = useStyles()
  let disabledClasses = useDisabledStyles()
  let outlinedInputClasses = useOutlinedInputClasses()

  return (
    <FormControl
      style={{
        minWidth: '240px',
        width: '100%',
        // borderColor: 'red'
        // opacity: disabled ? .25 : 1
      }}
      variant="outlined" 
      // margin="dense"
      // classes={{
      //   focused: classes.invisible
      // }}
    >
      <InputLabel
        disableAnimation={true}
        // style={{color: 'initial'}}
        disabled={disabled}
        classes={{
          root: classes.smallLabel + " " + disabledClasses.root,
          focused: classes.invisible,

          disabled: disabledClasses.disabled
        }}
      >{value ? '' : text}</InputLabel>

      <Select
        id={id}
        // ref={selectRef}
        inputProps={{style:{padding: '5px'}, readOnly}}
      // margin="dense"
        value={value}
        name={name}
        disabled={disabled}
        classes={{
          icon: disabled ? classes.disabled : classes.icon,
        }}
        // placeholder="asdf"
        input={<OutlinedInput
          // placeholder="asdf"
          labelWidth={0}
          // style={{padding: '5px'}}
          disabled={disabled}
          classes={{
            input: classes.smallPadding + " " + outlineSelectClasses.whiteBg,

            root: outlinedInputClasses.root,
            notchedOutline: outlinedInputClasses.notchedOutline,
            disabled: outlinedInputClasses.disabled
          }}
        />}
        onChange={(e) => onChange(e.target.value)}
        onOpen={onOpen}
        onClose={onClose}
        MenuProps={{
          transitionDuration: 100,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          getContentAnchorEl: null
        }}
      >
        {/* {children({selectRef})} */}
        {children()}
      </Select>
    </FormControl>
  )
}

export default OutlinedSelect