// import HTTP from './http'
import axios from 'axios'
// 401, 403
const API_ROOT = process.env.REACT_APP_API_ROOT || '/sonal-shop/api/fe'
const REACT_APP_BASENAME = process.env.REACT_APP_BASENAME || '/'

const defaultOptions = {
  baseURL: API_ROOT,
  // timeout: 10000,
  timeout: 20000,
}

const sonalHttpApi = axios.create(defaultOptions)
const sonalHttpApiWithAuth = axios.create(defaultOptions)
const sonalHttpApiWithAuthNoRedirect = axios.create(defaultOptions)

sonalHttpApiWithAuth.interceptors.request.use(function (config) {
  let authToken = localStorage.getItem('auth_token')
  if (authToken)
    config.headers = {...config.headers, 'Authorization': "Bearer " + authToken}
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

sonalHttpApiWithAuth.interceptors.response.use(res => res, (error) => {
  if (error && error.response && error.response.status){
    if ([401, 404].includes(error.response.status)){
      // window.location.reload();
      document.location.href = REACT_APP_BASENAME;
    }
    else
      return Promise.reject(error);
  }
  
  // 
  // history.push('/logout')
  return Promise.reject(error);
});


sonalHttpApiWithAuthNoRedirect.interceptors.request.use(function (config) {
  // console.log('asdf')
  let authToken = localStorage.getItem('auth_token')
  if (authToken)
    config.headers = {...config.headers, 'Authorization': "Bearer " + authToken}
  return config;
}, function (error) {
  return Promise.reject(error);
});

sonalHttpApiWithAuthNoRedirect.interceptors.response.use(res => res, (error) => {
  // console.log('no reddddd')
  return Promise.reject(error);
});

window.sonalHttpApiWithAuth = sonalHttpApiWithAuth

const sonalApi = {
  request: (method, ...rest) => {
    let options = {}

    switch(method) {
      case 'get':
        // console.log({rest})
        if (rest[1]) options = rest[1]
        break;
      case 'post':
        if (rest[2]) options = rest[2]
        break;
      case 'put':
        if (rest[2]) options = rest[2]
        break;
      case 'delete':
        if (rest[1]) options = rest[1]
        break;
      default:
        {}
    }

    // console.log({method, ...rest})


    let {useAuthentication = true, toLoginIfFailed = true} = options

    if (useAuthentication){
      if (toLoginIfFailed)
        return sonalHttpApiWithAuth[method](...rest)
      else {
          return sonalHttpApiWithAuthNoRedirect[method](...rest)
        }
    }
      
    return sonalHttpApi[method](...rest)
  }
}

// function get(url, options) {
//   return HTTP.get(url, withSonalOptions(options))
// }

// function post(url, data, options) {
//   return HTTP.post(url, data, withSonalOptions(options))
// }

// function put(url, data, options) {
//   return HTTP.put(url, data, withSonalOptions(options))
// }

// function deleteRequest(url, options) {
//   return HTTP.delete(url, withSonalOptions(options))
// }

// function withSonalOptions ({
//   useAuthentication = true,
//   ...otherOptions
// } = {}){

//   let options = {
//     // ...sonalApiOptions, 
//     ...otherOptions
//   }
//   // console.log(options)

//   if (useAuthentication){
//     let authToken = localStorage.getItem('auth_token')
//     if (authToken)
//       options.headers = {...options.headers, 'Authorization': "Bearer " + authToken}
//   }

//   return options
// }

function get(url, options) {
  // return sonalHttpApi.get(url, withSonalOptions(options))
  return sonalApi.request('get', url, options)
}

function post(url, data, options) {
  // console.log({url, data, options})
  return sonalApi.request('post', url, data, options)
}

function put(url, data, options) {
  return sonalApi.request('put', url, data, options)
}

function deleteRequest(url, options) {
  return sonalApi.request('delete', url, options)
}

export default {
  get,
  post,
  put,
  delete: deleteRequest
}

