import React, {useState, useRef} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import { DatePicker } from "@material-ui/pickers";

const ConfirmOrderDialog = ({
  onConfirm, 
  onCancel,
  deliveryDate,
  ...rest
}) => {

  let [date, setDate] = useState(deliveryDate || null)

  const onDateChange = (newDate) => {
    let value = newDate.format('YYYY-MM-DD')
    setDate(value)
  }

  // console.log({date})

  const datePickerRef = React.useRef(null);

  return (
    <Dialog
      // open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onExited={() => setDate(null)}
      {...rest}
    >
      <DialogTitle id="form-dialog-title">Potrditev naročila <DoneAllIcon style={{verticalAlign: 'sub', color: 'green'}}/></DialogTitle>
      
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {'Za potrditev naročila vnesite datum dobave:'}
        </DialogContentText>

        <DatePicker fullWidth
          label="Datum dobave"
          format="DD.MM.YYYY"
          // value={date || deliveryDate}
          value={date}
          minDate={new Date()}
          // minDateMessage={"Datum ne sme biti pred minimalnim"}
          onChange={onDateChange}
          animateYearScrolling
          margin="none"
          // ref={(ref) => {
          //   console.log({ref})
          // }}
          // onOpen={() => {
          //   datePickerRef.current.state.showDate = null
          //   datePickerRef.current.state.value = null
          // }}
        />

        
      </DialogContent>
      
      <DialogActions>
        {/* <Button onClick={onCancel} color="primary"> */}
        <Button onClick={onCancel} color="default">
          {'Prekliči'}
        </Button>
        <Button onClick={() => onConfirm(date)} color="primary" autoFocus disabled={!date} >
        {/* <Button onClick={onConfirm} color="default" autoFocus> */}
          {'Potrdi'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default ConfirmOrderDialog