import React, {useState} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import UpdateDialog, {useEditPopupState} from '../UpdateDialog'

export default ({
  workOrder,
  onChange,
  onNoteChange,
  editingDisabled
}) => {

  const editPopupState = useEditPopupState()

  if (!workOrder || !workOrder.worksheetRows)
    return null

  const {items} = workOrder.worksheetRows
  // console.log({items})

  if (!items)
    return null

  const itemsKeys = Object.keys(items)

  return (
    <>
      <Table size="small" style={{tableLayout: 'fixed'}}>
        <TableHead>
          <TableRow >
            <TableCell align="left" style={{width: 360}}>Tip</TableCell>
            <TableCell align="left" style={{width: 200}}>Količina</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
          {itemsKeys && itemsKeys.map((itemKey, rowIndex) => {  
            let item = items[itemKey]
            return (
              <TableRow key={rowIndex} >
                <TableCell component="td" scope="row">{itemKey}</TableCell>
                {/* <EditableNumberCell
                  disabled={editingDisabled}
                  component="td"
                  align="center"
                  onChange={newQuantityValue => {
                    console.log({newQuantityValue})
                    let q = Number(newQuantityValue)
                    q = (q === 0) ? 0
                      : (Number(q) || 1)
                    onChange(itemKey, q)
                  }}>
                    {item && item.quantity.toString()}
                </EditableNumberCell> */}

                  <TableCell
                    key={rowIndex} 
                    onDoubleClick={() => {
                      if (!editingDisabled){

                        editPopupState.open({
                          numberField: true,
                          text: item.quantity,
                          onConfirm: (newQuantityValue) => {
                            if (newQuantityValue !== item.quantity){
                              // console.log({newQuantityValue})
                              let q = Number(newQuantityValue)
                              q = (q === 0) ? 0
                                : (Number(q) || 1)
                              onChange(itemKey, q)
                            }
                          }
                        })
                      }
                    }}
                    align="left" style={{
                      whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis', borderLeft: '1px solid #dadada', width: 'auto',
                      color: editingDisabled ? 'gray' : 'unset'
                    }}
                  >
                    {item && item.quantity.toString()}
                  </TableCell>

                <TableCell component="td" align="left"></TableCell>
              </TableRow >
            )
          })}

        <TableRow key={'note-row'}>
          <TableCell component="td" scope="row">{'Opomba'}</TableCell>
          
          <TableCell 
            onDoubleClick={() => {
              if (!editingDisabled){

                editPopupState.open({
                  // text: workOrder.note ? workOrder.note.toString() : '',
                  // text: workOrder.note ? workOrder.note.toString() : null,
                  text: workOrder.note,
                  onConfirm: (newNote) => {
                    if (newNote !== workOrder.note){
                      // console.log({newNote})
                      onNoteChange(newNote)
                    }
                  }
                })
              }
            }}
            align="left" style={{
              whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis', borderLeft: '1px solid #dadada', width: 'auto',
              color: editingDisabled ? 'gray' : 'unset'
            }}
          >
            {workOrder && workOrder.note && workOrder.note.toString()}
          </TableCell>
            
        </TableRow>
  
        </TableBody>
      </Table>

      <UpdateDialog {...editPopupState} />

    </>
  )
}