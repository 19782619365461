import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom'
// import { Normalize } from 'styled-normalize'
// import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { AuthProvider } from './AuthContext';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import "moment/locale/sl";

import './i18n';  // localization

import App from './App'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#f5821e",
      customLight: "rgb(255, 189, 131)"
    },
    // secondary: {
    //   main: "#f5821e"
    // },
  }
});

// console.log('BASENAME', process.env.BASENAME)
// console.log('REACT_APP_BASENAME', process.env.REACT_APP_BASENAME)

const Root = () => (
  // <Router basename="/sonal" >
  <Router basename={process.env.REACT_APP_BASENAME}>
    <CssBaseline />
    <AuthProvider>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'sl'} >
          <App />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </AuthProvider>
  </Router>
)

export default Root
