
// import { updateFieldValue } from '../../../services/order/index';

const addRows = (rowsState, {addedRows}) => {

  let addedRowsIds = addedRows.map(row => row.id)
  let addedRowsById = addedRows.reduce((acc, row) => {
    acc[row.id] = {...row, valuesIdsByFieldsIds: {}}
    return acc
  }, {})

  // console.log({addedRowsIds, addedRowById})

  return {
    ...rowsState,
    byId: {
      ...rowsState.byId,
      ...addedRowsById
    },
    allIds: [
      ...rowsState.allIds,
      ...addedRowsIds
    ]
  }
}

const insertRows = (rowsState, {orderNo, quantity, insertedRows}) => {

  let insertedRowsIds = insertedRows.map(row => row.id)
  let insertedRowsById = insertedRows.reduce((acc, row) => {
    acc[row.id] = {...row, valuesIdsByFieldsIds: {}}
    return acc
  }, {})

  // console.log({addedRowsIds, addedRowById})

  // console.log({rowsState})

  let updatedRowsById = {}
  rowsState.allIds.forEach(rId => {
    let row = rowsState.byId[rId]
    // console.log(row.orderNo > orderNo, row )
    if (row.orderNo > orderNo) {
      updatedRowsById[rId] = {...row, orderNo: row.orderNo + quantity}
    }
  })

  // console.log({updatedRowsById})

  return {
    ...rowsState,
    byId: {
      ...rowsState.byId,
      ...insertedRowsById,
      ...updatedRowsById
    },
    allIds: [
      ...rowsState.allIds,
      ...insertedRowsIds
    ]
  }
}

const addFieldValuesToRow = (rowsState, {valueObj, fieldId, row}) => {
  row = rowsState.byId[row.id]
  let updatedRow = {
    ...row,
    valuesIdsByFieldsIds: {
      ...row.valuesIdsByFieldsIds,
      [fieldId]: [valueObj.id]
    }
  }

  let updatedRowsById = {
    ...rowsState.byId,
    [updatedRow.id]: updatedRow
  }

  // console.log({updatedRowsById})

  return {
    ...rowsState,
    byId: updatedRowsById
  }
}

const addFieldValues = (valuesState, {valueObj})=> {
  let updatedValuesById = {
    ...valuesState.byId,
    [valueObj.id]: valueObj
  }

  return {
    ...valuesState,
    byId: updatedValuesById
  }
}

const updateFieldValues = (valuesState, {updateData, fieldId, row})=> {
  let updatedValuesById = {}
  let fieldValuesIds = row.valuesIdsByFieldsIds[fieldId] || []
  fieldValuesIds.forEach(valueId => {
    let fieldValue = valuesState.byId[valueId]
    updatedValuesById[valueId] = {
      ...fieldValue,
      ...updateData
    }
  })
  return {
    ...valuesState,
    byId: {
      ...valuesState.byId,
      ...updatedValuesById
    }
  }
}

const removeFieldValuesFromRow = (rowsState, {fieldId, row}) => {

  row = rowsState.byId[row.id]
  
  let fieldValuesIds = row.valuesIdsByFieldsIds[fieldId]

  let updatedRows = fieldValuesIds.reduce((rowsToUpdate, valueId) => {

    let rowToUpdate = rowsToUpdate.byId[row.id]

    let updatedFieldValuesIds = fieldValuesIds && fieldValuesIds.filter(vId => vId !== valueId)

    let updatedRow = {
      ...rowToUpdate,
      valuesIdsByFieldsIds: {
        ...rowToUpdate.valuesIdsByFieldsIds,
        [fieldId]: updatedFieldValuesIds
      }
    }
    
    let updatedRowsById = {
      ...rowsToUpdate.byId,
      [updatedRow.id]: updatedRow
    }

    return {byId: updatedRowsById}

  }, rowsState)

  return {
    ...rowsState,
    ...updatedRows
  }
}
const removeFieldValues = (valuesState, {fieldId, row}) => {
  // console.log({row})
  let fieldValuesIds = row.valuesIdsByFieldsIds[fieldId]

  let updatedValues = fieldValuesIds.reduce((valuesToUpdate, valueId) => {

    let {[valueId]: removedValue, ...updatedValuesById} = valuesToUpdate.byId

    return {byId: updatedValuesById}

  }, valuesState)

  return {
    ...valuesState,
    ...updatedValues
  }
}

const removeAllFieldValuesFromRow = (rows, values, rowId, fieldId) => {

  let row = rows.byId[rowId]
  let fieldValuesIds = row.valuesIdsByFieldsIds[fieldId]

  // console.log({row})

  let updatedValues = fieldValuesIds.reduce((valuesToUpdate, valueId) => {
    // console.log({valueId})
    let {[valueId]: removedValue, ...updatedValuesById} = valuesToUpdate.byId
    // console.log({removedValue, updatedValuesById})
    return {byId: updatedValuesById}
  }, values)

  // console.log({valuesIdsByFieldsIds: row.valuesIdsByFieldsIds, fieldId})
  let {[fieldId]: removedValuesIds, ...withoutFieldId} = row.valuesIdsByFieldsIds

  // console.log({updatedValues, withoutFieldId, removedValuesIds})

  return {
    values: updatedValues,
    rows: {
      ...rows,
      byId: {
        ...rows.byId,
        [rowId]: {
          ...rows.byId[rowId],
          valuesIdsByFieldsIds: withoutFieldId
        }
      }
    }
  }
}
const removeOneFieldValueFromRow = (rows, values, rowId, fieldId, oldValueId) => {

  let row = rows.byId[rowId]
  let fieldValuesIds = row.valuesIdsByFieldsIds[fieldId]

  let oldValueIndex = fieldValuesIds.indexOf(oldValueId)
  if (oldValueIndex === -1)
    return {rows, values}

  let {[oldValueId]: removedValue, ...updatedValuesById} = values.byId

  let updatedValues = {
    byId: updatedValuesById
  }

  let withoutOldValueId = [...fieldValuesIds.slice(0, oldValueIndex), ...fieldValuesIds.slice(oldValueIndex + 1)]

  return {
    values: updatedValues,
    rows: {
      ...rows,
      byId: {
        ...rows.byId,
        [rowId]: {
          ...rows.byId[rowId],
          valuesIdsByFieldsIds: {
            ...rows.byId[rowId].valuesIdsByFieldsIds,
            [fieldId]: withoutOldValueId
          }
        }
      }
    }
  }
}

const selectOneUpdateValue = (valuesState, {value, fieldId, row, dirtyStatus}) => {
  let updatedValuesById = {}
  let fieldValuesIds = row.valuesIdsByFieldsIds[fieldId]
  fieldValuesIds && fieldValuesIds.forEach(valueId => {
    let fieldValue = valuesState.byId[valueId]
    updatedValuesById[valueId] = {
      ...fieldValue,
      optionId: value,
      dirtyStatus
    }
  })

  // console.log({value, updatedValuesById})

  return {
    ...valuesState,
    byId: {
      ...valuesState.byId,
      ...updatedValuesById
    }
  }
}

const selectOneCreateValue = (valuesState, {valueObj, field, row}) => {
  let updatedValuesById = {}
  updatedValuesById[valueObj.id] = valueObj

  return {
    ...valuesState,
    byId: {
      ...valuesState.byId,
      ...updatedValuesById
    }
  }
}

const selectOneAddValueToRow = (rowsState, {valueObj, field, row}) => {
  row = rowsState.byId[row.id]
  // console.log({row, rowsState })
  let updatedRow = {
    ...row,
    valuesIdsByFieldsIds: {
      ...row.valuesIdsByFieldsIds,      // TODO:  TypeError: Cannot read property 'valuesIdsByFieldsIds' of undefined
      // ...((row && row.valuesIdsByFieldsIds) || {}),
      [field.id]: [valueObj.id]
    }
  }

  let updatedRowsById = {
    ...rowsState.byId,
    [updatedRow.id]: updatedRow
  }

  return {
    ...rowsState,
    byId: updatedRowsById
  }
}

const selectOneUpdateValueNewId = ({rows, values}, action) => {

  let row = rows.byId[action.row.id]
  let updatedState = removeAllFieldValuesFromRow(rows, values, action.row.id, action.fieldId)

  let valueObj = {
    ...action.createdFieldValue,
    optionId: action.createdFieldValue.fieldOptionId
  }

  updatedState.rows = addFieldValuesToRow(updatedState.rows, {valueObj, fieldId:action.fieldId, row})
  updatedState.values = addFieldValues(updatedState.values, {valueObj})
  // console.log({updatedState})
  return updatedState
}

const selectMultipleUpdateValueNewId = ({rows, values}, action) => {
  let row = rows.byId[action.row.id]
  // console.log({row, action})
  let updatedState = removeOneFieldValueFromRow(rows, values, action.row.id, action.fieldId, action.oldId)

  let valueObj = {
    ...action.createdFieldValue,
    optionId: action.createdFieldValue.fieldOptionId
  }

  updatedState.rows = selectMultipleAddValueToRow(updatedState.rows, {valueObj, fieldId: action.fieldId, row})
  updatedState.values = selectMultipleCreateValue(updatedState.values, {valueObj})

  return updatedState
}

const fieldUpdateValueNewId = ({rows, values}, action) => {
  let valuesWithAddedValue = addFieldValues(values, {valueObj: action.createdFieldValue})
  let valuesWithRemovedOldValue = removeFieldValues(valuesWithAddedValue, {...action, row: rows.byId[action.row.id]})

  let rowsWithRemovedOld = removeFieldValuesFromRow(rows, {
    fieldId: action.fieldId,
    row: rows.byId[action.row.id]
  })

  let rowsWithAddedNew = addFieldValuesToRow(rowsWithRemovedOld, {
    valueObj: action.createdFieldValue,
    fieldId: action.fieldId,
    row: rows.byId[action.row.id]
  })

  return {values: valuesWithRemovedOldValue, rows: rowsWithAddedNew}
}

const selectMultipleCreateValue = (valuesState, {valueObj, field, row}) => {
  let updatedValuesById = {}
  updatedValuesById[valueObj.id] = valueObj

  return {
    ...valuesState,
    byId: {
      ...valuesState.byId,
      ...updatedValuesById
    }
  }
}
const selectMultipleAddValueToRow = (rowsState, {valueObj, fieldId, row}) => {
  row = rowsState.byId[row.id]

  // return rowsState
  let updatedRow = {
    ...row,
    valuesIdsByFieldsIds: {
      ...(row.valuesIdsByFieldsIds || {}),
      [fieldId]: [
        ...(row.valuesIdsByFieldsIds[fieldId] || []),
        valueObj.id
      ]
    }
  }

  let updatedRowsById = {
    ...rowsState.byId,
    [updatedRow.id]: updatedRow
  }

  return {
    ...rowsState,
    byId: updatedRowsById
  }
}

const selectMultipleRemoveValue = (valuesState, {fieldId, row, valueObjToRemove}) => {
  // let fieldValuesIds = row.valuesIdsByFieldsIds[fieldId]
  // console.log({valuesStateById: valuesState.byId})
  let {[valueObjToRemove.id]: removedValue, ...updatedValuesById} = valuesState.byId

  return {
    ...valuesState,
    byId: updatedValuesById
  }
}

const selectMultipleRemoveValueFromRow = (rowsState, {fieldId, row, valueObjToRemove}) => {
  // return rowsState
  row = rowsState.byId[row.id]

  let indexToRemoveAt = row.valuesIdsByFieldsIds[fieldId].indexOf(valueObjToRemove.id)

  let fieldValuesIds = row.valuesIdsByFieldsIds[fieldId]
  let updatedFieldValuesIds = [...fieldValuesIds.slice(0, indexToRemoveAt), ...fieldValuesIds.slice(indexToRemoveAt + 1)]

  let updatedRow = {
    ...row,
    valuesIdsByFieldsIds: {
      ...row.valuesIdsByFieldsIds,
      [fieldId]: updatedFieldValuesIds
    }
  }

  let updatedRowsById = {
    ...rowsState.byId,
    [updatedRow.id]: updatedRow
  }

  return {
    ...rowsState,
    byId: updatedRowsById
  }
}

const updateOrderAttribute = (order, {attributeName, value}) => {
  // console.log({attributeName, value})
  return {...order, [attributeName]: value}
}

const rowsReducer = (rowsState, action) => {
  switch (action.type) {
    case 'ADD_ROWS': 
      return addRows(rowsState, action)
    case 'INSERT_ROWS': 
      return insertRows(rowsState, action)
    case 'ADD_FIELD_VALUE': 
      return addFieldValuesToRow(rowsState, action)
    case 'REMOVE_FIELD_VALUE': 
      return removeFieldValuesFromRow(rowsState, action)
    case 'SELECT_ONE_CREATE_VALUE': 
      return selectOneAddValueToRow(rowsState, action)
    // case 'UPDATE_FIELD_VALUE_NEW_ID': {
      // let withRemovedOld = removeFieldValuesFromRow(rowsState, {
      //   fieldId: action.fieldId,
      //   row: action.row
      // })

      // let withAddedNew = addFieldValuesToRow(withRemovedOld, {
      //   valueObj: action.createdFieldValue,
      //   fieldId: action.fieldId,
      //   row: action.row
      // })
    //   return withAddedNew
    // }
    case 'SELECT_MULTIPLE_CREATE_VALUE':
      return selectMultipleAddValueToRow(rowsState, action)
    case 'SELECT_MULTIPLE_REMOVE_VALUE':
      return selectMultipleRemoveValueFromRow(rowsState, action)
    default:
      return rowsState
  }
}

const valuesReducer = (valuesState, action) => {
  switch (action.type) {
    case 'ADD_FIELD_VALUE': 
      return addFieldValues(valuesState, action)
    case 'UPDATE_FIELD_VALUE': 
      return updateFieldValues(valuesState, action)
    case 'REMOVE_FIELD_VALUE': 
      return removeFieldValues(valuesState, action)
    case 'SELECT_ONE_UPDATE_VALUE': 
      return selectOneUpdateValue(valuesState, action)
    case 'SELECT_ONE_CREATE_VALUE': 
      return selectOneCreateValue(valuesState, action)
    // case 'UPDATE_FIELD_VALUE_NEW_ID': {
    //   let withAddedValue = addFieldValues(valuesState, {valueObj: action.createdFieldValue})
    //   let withRemovedOldValue = removeFieldValues(withAddedValue, action)
    //   return withRemovedOldValue
    // }
    case 'SELECT_MULTIPLE_CREATE_VALUE':
      return selectMultipleCreateValue(valuesState, action)
    case 'SELECT_MULTIPLE_REMOVE_VALUE':
      return selectMultipleRemoveValue(valuesState, action)

    default:
      return valuesState
  }
}

const orderReducer = (state, action) => {
  // console.log({state})

  // if (action.row && action.row.id)
  //   console.log({rrr: state.rows.byId[action.row.id]})

  if (action.type === 'INIT')
    return action.orderData

  else if (action.type === 'SELECT_ONE_UPDATE_VALUE_NEW_ID') {
    return {...state, ...selectOneUpdateValueNewId(state, action)}
  }

  else if (action.type === 'UPDATE_FIELD_VALUE_NEW_ID') {
    return {...state, ...fieldUpdateValueNewId(state, action)}
  }

  else if (action.type === 'SELECT_MULTIPLE_UPDATE_VALUE_NEW_ID') {
    return {...state, ...selectMultipleUpdateValueNewId(state, action)}
  }

  else if (action.type === 'UPDATE_ORDER_ATTRIBUTE'){
    return {
      ...state,
      order: updateOrderAttribute(state.order, action)
    }
  }

  return {
    ...state,
    rows: rowsReducer(state.rows, action),
    values: valuesReducer(state.values, action)
  }
}
export default orderReducer